import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { setTotalResumen } from '../../../redux/modules/totalResumen';
import PropTypes from 'prop-types';

import DataParser from './DataParser';
import TCell, { tcellTipos } from './TCell';

import { tipos, anios } from '../../../data';

const uuidv4 = require('uuid/v4');

class TBodyRow extends Component {
  constructor(props) {
    super(props);

    const { rowData } = props;

    this.state = {
      rowData,
      childrenShown: false,
    };

    this.onClickRow = this.onClickRow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataRow !== prevState.dataRow) {
      return ({
        dataRow: nextProps.dataRow,
      });
    }
    return prevState;
  }

    onClickRow = () => {
      const { rowData, childrenShown } = this.state;

      this.setState({
        childrenShown: !childrenShown
      });
    }

    render() {
      const { rowData, childrenShown } = this.state;
      const { child } = this.props;

      const icons = {
        better: 'fa fa-arrow-down font-green-haze',
        worse: 'fa fa-arrow-up font-red-thunderbird',
        equal: 'fa fa-arrow-right font-yellow-gold'
      };

      let data = null;

      if (rowData.tipo === tipos.FINCA) {
        data = DataParser.getDataFromFinca(rowData.data, anios);
      } else if (rowData.tipo === tipos.PRODUCTO) {
        data = DataParser.getDataFromProducto(rowData.data, anios);
      } else if ([tipos.CICLO, tipos.PARCIAL].indexOf(rowData.tipo) > -1) {
        data = DataParser.getUniqueDataFromAdministracion(rowData.data, anios);
      }

      const { total } = data;
      const trClass = rowData.data ? '' : '';
      const textCenter = 'text-center';
      let className = child ? '' : textCenter;

      let children = (<React.Fragment />);
      if (([tipos.CICLO, tipos.PARCIAL].indexOf(rowData.tipo) <= -1) && rowData.data && childrenShown && !rowData.block) {
        children = rowData.data.map((item, index) => (
          <TBodyRow key={`${item.name}`} rowData={item} child />
        ));

        className = `${className} active`;
        children = [children, (<tr key={`${uuidv4()}`}>
          <td colSpan={data.total.length} className="divider active" />
        </tr>)];
      }

      let totalJSX = (<React.Fragment />);
      if (([tipos.CICLO, tipos.PARCIAL].indexOf(rowData.tipo) <= -1)) {
        totalJSX = total.map((item, index, arr) => {
            const valor = parseFloat(arr[index]).toFixed(2);
          if (index === 0) {
            return (
              <>
                <td className={className} style={{ position: "sticky", zIndex: 1, backgroundColor: "white", left: 0 }}>{ rowData.name }</td>
                <td className={className}>{ valor }</td>
              </>
            );
          }

          if (index >= 2 && index % 2 == 0) {
            return (
              <TCell
                valor={valor}
                tipo={tcellTipos.PORCENTAJE}
                iconClassName={valor > 0 ? icons.worse : valor == 0 ? icons.equal : icons.better}
              />
            );
          }
          return (
            <td className={className}>{ valor }</td>
          );
        });
      } else { // ADMINISTRACION
        totalJSX = data.map((valor, index, arr) => {
          if (index === 0) {
            return (
              <>
                <td className={className}>{ rowData.name }</td>
                <td className={className}>{ valor }</td>
              </>
            );
          }
          if (index >= 2 && index % 2 == 0) {
            return (
              <TCell
                valor={valor}
                tipo={tcellTipos.PORCENTAJE}
                iconClassName={valor > 0 ? icons.worse : valor === 0 ? null : icons.better}
              />
            );
          }
          return (
            <td className={className}>{ valor }</td>
          );
        });
      }

      if (!rowData) {
        return (<> </>);
      }
      return (
        <>
          <tr key={`${rowData.name}`} onClick={this.onClickRow} className={trClass}>
            { totalJSX }
          </tr>
          { children }
        </>
      );
    }
}


function mapStateToProps(state) {
  return {
    totalResumen: state.totalResumen,
  };
}

export default connect(
  mapStateToProps
)(TBodyRow);
