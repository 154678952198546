import React, { Component } from 'react';
import { connect } from 'react-redux';

import $ from 'jquery';

import {
  AplicacionesAPI,
  ReportesAPI,
} from '../../api/rest-api';

import { setMatrizCostos } from '../../redux/modules/matrizCostos';

import './TMatrixCellEditable.css';

export const TIPOS_INPUT = {
  DATE: 'date',
  NUMBER: 'number',
  SELECT: 'select',
  TEXT: 'text'
};


class TMatrixCellEditable extends Component {
  constructor(props) {
    super(props);

    const { nombre_atributo, nombre_submit } = props;
    const {
      aplicacion,
      programa,
      crearNuevoValorTemporal,
      tipo_input,
    } = props;

    this.state = {
      focused: false,
      aplicacion,
      newValue: null,
      backUpValue: aplicacion[nombre_atributo],
    };

    this.onBlur = this.onBlur.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

    componentDidMount = () => {
      const { tipo_input } = this.props;

      if (tipo_input !== TIPOS_INPUT.SELECT) {
        document.addEventListener('keydown', this.onKeyDown, false);
      }
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        aplicacion: nextProps.aplicacion
      });
    }

    componentWillUnMount = () => {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }

    onChangeValue = (e) => {
      const { aplicacion, nombre_atributo, nombre_submit } = this.props;
      this.setState({ newValue: e.target.value || 0 });
    }

    onSubmit = async () => {
      const {
        aplicacion, newValue, backUpValue, focused
      } = this.state;

      const {
        crearNuevoValorTemporal,
        programa,
        nombre_atributo,
        nombre_submit,
        isNuevaAplicacion,
        fetchMatrizDeCostos,
        setMatrizCostos,
        selectedFinca,
        selectedAnio,
      } = this.props;

      if (!isNuevaAplicacion) {
        try {
          const response = await AplicacionesAPI.patch(aplicacion.id, {
            [nombre_submit || nombre_atributo]: newValue
          });

          if (response.status === 200) {
            const { data } = response;
            const nuevoValor = data[nombre_submit || nombre_atributo];

            this.setState({
              focused: false,
              newValue: null,
              backUpValue: null
            });

            fetchMatrizDeCostos({ anio: selectedAnio, id_finca: selectedFinca }).then((response) => {
              setMatrizCostos(response.data);
            }).catch((err) => {
              console.error(err);
            });
            // crearNuevoValorTemporal(aplicacion, programa, nuevoValor, nombre_submit || nombre_atributo);
          } else {
            console.error(`No se pudo actualizar la aplicacion ${aplicacion}`);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }

    onTemporalSubmit = async () => {
      const {
        aplicacion, newValue, backUpValue, focused
      } = this.state;
      const {
        crearNuevoValorTemporal, programa, nombre_atributo, nombre_submit
      } = this.props;
      const nuevoValor = newValue;
      crearNuevoValorTemporal(aplicacion, programa, nuevoValor, nombre_atributo);
      this.setState({
        focused: false,
      });
    }

    onCancel = () => {
      const {
        aplicacion, newValue, backUpValue, focused
      } = this.state;
      const {
        crearNuevoValorTemporal, programa, nombre_atributo, nombre_submit
      } = this.props;

      if (backUpValue != null) {
        this.setState({
          focused: false,
          newValue: null,
          backUpValue: null
        }, () => {
          crearNuevoValorTemporal(aplicacion, programa, backUpValue || aplicacion[nombre_atributo] || aplicacion[nombre_submit], nombre_atributo);
        });
      } else {
        this.setState({
          focused: false,
          newValue: aplicacion[nombre_atributo] || aplicacion[nombre_submit],
        }, () => {
        });
      }
    }

    toggleSelect = (e) => {
      const { focused } = this.state;
      this.setState({ focused: !focused });
    }

    onBlur = (e) => {
      const { aplicacion, newValue } = this.state;
      const {
        crearNuevoValorTemporal, programa, nombre_atributo, nombre_submit, static_value
      } = this.props;
      if ((nombre_submit || nombre_atributo) == 'dias_atraso') {
        const { fecha_real, fecha_programacion } = this.props;
      }

      this.setState({ focused: true }, () => {
        crearNuevoValorTemporal(aplicacion, programa, newValue, nombre_atributo);
      });
    }

    onKeyDown = async (e) => {
      const {
        aplicacion, newValue, backUpValue, focused
      } = this.state;
      const {
        crearNuevoValorTemporal,
        programa,
        nombre_atributo,
        nombre_submit,
        isNuevaAplicacion,
        fetchMatrizDeCostos,
        setMatrizCostos,
        selectedFinca,
        selectedAnio,
      } = this.props;

      if (focused) {
        const ESCAPE = 27;
        const ENTER = 13;
        if (e.keyCode === ESCAPE && focused) {
          if (backUpValue != null) {
            this.setState({
              focused: false,
              newValue: null,
              backUpValue: null
            }, () => {

              crearNuevoValorTemporal(aplicacion, programa, backUpValue || aplicacion[nombre_atributo] || aplicacion[nombre_submit], nombre_submit);
            });
          } else {
            this.setState({
              focused: false,
              newValue: aplicacion[nombre_atributo] || aplicacion[nombre_submit],
            }, () => {
            });
          }
        } else if (e.keyCode === ENTER && focused) {
          if (!isNuevaAplicacion) {
            try {
              const response = await AplicacionesAPI.patch(aplicacion.id, {
                [nombre_submit || nombre_atributo]: newValue
              });

              if (response.status === 200) {
                const { data } = response;
                const nuevoValor = data[nombre_submit || nombre_atributo];

                this.setState({
                  focused: false,
                  newValue: null,
                  backUpValue: null
                });

                fetchMatrizDeCostos({ anio: selectedAnio, id_finca: selectedFinca }).then((response) => {
                  setMatrizCostos(response.data);
                }).catch((err) => {
                  console.error(err);
                });
                // crearNuevoValorTemporal(aplicacion, programa, nuevoValor, nombre_submit || nombre_atributo);
              } else {
                console.error(`No se pudo actualizar la aplicacion ${aplicacion}`);
              }
            } catch (e) {
              console.error(e);
            }
          } else {
            this.onTemporalSubmit();
          }
        }
      }
    };

    render = () => {
      const { aplicacion, focused, newValue } = this.state;
      const {
        nombre_atributo, tipo_input, static_value, choices, nombre_submit
      } = this.props;
      const tdClassName = newValue && (newValue !== aplicacion[nombre_atributo]) ? 'change' : '';

      let input = null;

      if (tipo_input === TIPOS_INPUT.SELECT) {
        const choicesJSX = choices.map(ch => (
          <option value={ch.id} key={ch.id}>{ ch.nombre }</option>));

        input = (
          <div ng-if="row.editing == 'programa'" className="ng-scope">
            <select
              className="form-control inline "
              id="editing"
              data-_extension-text-contrast=""
              id="editing"
              type={tipo_input}
              title="Enter para guardar; Escape para cancelar"
              className="form-control min-width"
              autoComplete="off"
              defaultValue=""
              onChange={this.onChangeValue}
            >
              <option selected value>Seleccione</option>
              {choicesJSX}
            </select>

            <button className="btn btn-xs btn-success" title="Guardar" onClick={this.onSubmit} data-_extension-text-contrast="">
              <i className="fa fa-check" />
            </button>
            <button className="btn btn-xs btn-danger" title="Cancelar" onClick={this.onCancel} data-_extension-text-contrast="">
              <i className="fa fa-times" />
            </button>
          </div>
        );
      } else {
        input = (
          <div>
            <input
              id="editing"
              type={tipo_input}
              title="Enter para guardar; Escape para cancelar"
              className="form-control min-width"
              autoComplete="off"
              onChange={this.onChangeValue}
              value={newValue === 0 ? 0 : (newValue || aplicacion[nombre_atributo] || aplicacion[nombre_submit])}
            />
          </div>
        );
      }

      let span = null;

      if (tipo_input === TIPOS_INPUT.SELECT) {
        const choice = choices.find(ch => ch.id.toString() === (newValue || aplicacion[nombre_atributo]) || ch.nombre.toString() === (newValue || aplicacion[nombre_atributo]));

        span = (
          <span>{choice ? choice.nombre : '' }</span>
        );
      } else {
          // console.info(newValue, aplicacion.hectareas, aplicacion.ha, aplicacion[nombre_atributo], aplicacion, aplicacion[nombre_submit], nombre_submit, nombre_atributo)
        span = (
          <span>{newValue || aplicacion[nombre_atributo] || aplicacion[nombre_submit]}</span>
        );
      }

      const content = focused ? input : span;

      return (
        <td
          onBlur={this.onBlur}
          onDoubleClick={this.toggleSelect}
          className={tdClassName}
        >
          { content }
        </td>
      );
    }
}

const mapStateToProps = state => ({
  fincas: state.fincas,
  motivosAtraso: state.motivosAtraso,
  programas: state.fincas,
  matrizCostos: state.matrizCostos,
  tipoProductos: state.tipoProductos,
  productos: state.productos,
  proveedores: state.proveedores,
  dosis: state.dosis,
  selectedFinca: state.selectedFinca,
  selectedAnio: state.selectedAnio,
}
);

function mapDispatchToProps(dispatch) {
  return {
    fetchMatrizDeCostos: data => ReportesAPI.getMatrizDeCostos(data),
    setMatrizCostos: arr => dispatch(setMatrizCostos(arr)),
  };
}

export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(TMatrixCellEditable));
