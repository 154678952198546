import React, { Component } from 'react';

import ReactEcharts from 'echarts-for-react';

import {getOption} from '../../../helpers/echarts/motivosAtrasoResumen'

const fracObject = {
    Amina: null,
    Anilinopirimidinas: null,
    Triazol: null,
    Guanidinas: null,
    Carboxamidas: null,
    Estrobilurinas: null,
}

export default class MotivosAtrasoResumen extends Component {

    getClassColor(value){    
        if(value == 0){
            return 'bg-green-haze bg-font-green-haze'
        }else if(value > 0){
            return 'bg-yellow-gold bg-font-yellow-gold'
        }else if(value <0){
            return 'bg-red-thunderbird bg-font-red-thunderbird'
        }
    }

    
    render(){
        const {data} = this.props;

        const option = getOption(data);
        
        return (
            <>
            <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">   MOTIVOS DE ATRASO   </span>
              </div>
            </div>
            <div className="portlet-body">

            <div id="contenedor_motivo" style={{height:500}}>

            
              <ReactEcharts
                option={option}
                notMerge
                lazyUpdate
                style={{height: '500px', width: '100%'}}
              />
              </div>
            </div>
          </div>
            </>
        );
    }
}

