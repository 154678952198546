import React, { useState } from 'react';

import { CrearProductoFormConsumer } from '../context/CrearProducto';

const ModalCrearProducto = (props) => {
  const {
    producto,
    programa,
    programaKey,
    aplicacion,
    agregarProducto,
    productos,
    proveedores,
    tarifas,
    dosis,
    tipoProductos,
  } = props;

  return (
    <CrearProductoFormConsumer>
      {
          (context) => {
            let proveedoresJSX = (<React.Fragment />);
            if (aplicacion
                  && (aplicacion.id_programa
                      || (aplicacion.programa && !isNaN(aplicacion.programa)))
                  && tarifas[aplicacion.id_programa || aplicacion.programa]) {
              const mappedProveedores = tarifas[aplicacion.id_programa || aplicacion.programa].map(ta => ta.proveedor_id.toString());
              const pvs = proveedores.filter(item => mappedProveedores.includes(item.id.toString()));
              pvs.sort((pv1, pv2) => {
                if (pv1.nombre < pv2.nombre) { return -1; }
                if (pv1.nomre > pv2.nombre) { return 1; }
                return 0;
              });
              proveedoresJSX = (pvs || []).map(pv => (
                <option key={pv.id} value={pv.id}>{pv.nombre}</option>
              ));
            } else {
              proveedoresJSX = (proveedores || []).map(pv => (
                <option key={pv.id} value={pv.id}>{pv.nombre}</option>
              ));
            }

            let productosJSX = null;
            const proveedor = context.state.proveedor || 0;

            if (aplicacion
                  && (aplicacion.id_programa
                      || (aplicacion.programa && !isNaN(aplicacion.programa)))
                  && tarifas[aplicacion.id_programa || aplicacion.programa]) {
              const mappedProveedores = tarifas[aplicacion.id_programa || aplicacion.programa].map(ta => ta.proveedor_id.toString());
              const tarifaRows = tarifas[aplicacion.id_programa || aplicacion.programa].filter(item => item.proveedor_id.toString() === proveedor.toString());
              const productIds = tarifaRows.map(pr => pr.producto_id.toString());

              productosJSX = (productos || []).filter(pr => productIds.includes(pr.id.toString())).map(pr => (
                <option key={pr.id} value={pr.id}>{pr.nombre}</option>
              ));
            } else {
              productosJSX = (productos || []).filter(pr => pr.proveedores.includes(parseInt(proveedor))).map(pr => (
                <option key={pr.id} value={pr.id}>{pr.nombre}</option>
              ));
            }

            let dosisJSX = null;

            dosisJSX = (dosis || []).filter(dosistmp => (dosistmp.producto.toString() === context.state.producto ? context.state.producto.toString() : '')).map(ds => (
              <option value={ds.id} key={ds.id}>{Number(ds.cantidad).toFixed(5)}</option>
            ));

            let cantidad = 0;
            let total = 0;
            let precio = 0;

            if (context.state.dosis) {
              const selectedDosis = dosis.find(el => el.id.toString() === context.state.dosis.toString());
              cantidad = selectedDosis ? Number(selectedDosis.cantidad).toFixed(5) : 0;
            }

            if (aplicacion && context.state.producto && (aplicacion.id_programa
                                                          || (aplicacion.programa && !isNaN(aplicacion.programa))
            ) && tarifas[aplicacion.id_programa || aplicacion.programa]) {
              const selectedTarifa = tarifas[aplicacion.id_programa || aplicacion.programa].find(tarifa => tarifa.proveedor_id.toString() === proveedor.toString()
                          && tarifa.producto_id.toString() === context.state.producto);

              precio = selectedTarifa ? Number(selectedTarifa.precio).toFixed(2) || 0 : 0;
              total = Number(precio * cantidad).toFixed(5);
            }

            return (
              <div className="modal fade modalCrear" id="modalCrearProducto" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                      <h4 className="modal-title" id="myModalLabel">Creacion de Producto</h4>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-4" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <span>Proveedor</span>
                        </div>
                        <div className="col-8" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <select
                            className="form-control"
                            style={{ width: '75%' }}
                            value={context.state.proveedor ? context.state.proveedor : null}
                            defaultValue={context.state.proveedor ? context.state.proveedor : null}
                            onChange={(ev) => { context.onChangeProveedor(ev.target.value); }}
                          >
                            <option selected value={null}>Seleccione</option>
                            {proveedoresJSX}
                          </select>

                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-4" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <span>Producto</span>
                        </div>
                        <div className="col-8" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <select
                            className="form-control"
                            style={{ width: '75%' }}
                            defaultValue={context.state.producto ? context.state.producto : null}
                            onChange={(ev) => { context.onChangeTipoProducto(ev.target.value); }}
                          >
                            <option selected value={null}>Seleccione</option>
                            {productosJSX}
                          </select>

                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-4" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <div>Dosis</div>
                        </div>
                        <div className="col-8" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                          <select
                            className="form-control"
                            onChange={(ev) => { context.onChangeDosis(ev.target.value); }}
                            style={{ width: '75%' }}
                            defaultValue={context.state.dosis ? context.state.dosis : null}
                          >
                            <option selected value={null}>Seleccione</option>
                            {dosisJSX}
                          </select>

                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="row" style={{ textAlign: 'center' }}>
                        <div className="col-4">
                          <div>
                            <strong>Cantidad</strong>
                          </div>
                          <div>
                            { cantidad }
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <strong>Precio</strong>
                          </div>
                          <div>
                            { precio }
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <strong>Total</strong>
                          </div>
                          <div>
                            { total }
                          </div>
                          <div />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => { context.handleSubmitCallback(e, agregarProducto, props, precio, cantidad); }
}
                      >
Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
      }
    </CrearProductoFormConsumer>
  );
};

export default ModalCrearProducto;
