export const SET_GERENTES = 'SET_GERENTES';

const initialState = [];

export function setGerentes(value) {
  return {
    type: SET_GERENTES,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GERENTES:
      return action.value;

    default:
      return state;
  }
}
