import React from 'react';
import { connect } from 'react-redux';

import { withAlert } from 'react-alert';
import { labels } from '../redux/modules/apiStatus';
import { TIPO_HECTAREAS } from '../data';
import { setProveedores } from '../redux/modules/proveedores';
import { crearAplicacion } from '../redux/modules/fetchAll';
import { setDosis } from '../redux/modules/dosis';
import { setFincas } from '../redux/modules/fincas';
import { setMatrizCostos } from '../redux/modules/matrizCostos';
import { setMotivosAtraso } from '../redux/modules/motivosAtraso';
import { setTiposProducto } from '../redux/modules/tipoProductos';
import { setProductos } from '../redux/modules/productos';
import { setNuevasAplicaciones } from '../redux/modules/nuevasAplicaciones';
import StringHelper from '../helpers/stringHelpers';
import { PARCIAL } from '../constants';

import { fetchTarifasFromProgramaId } from '../redux/modules/fetchAll';

import {
  AplicacionesAPI,
  AplicacionProductosAPI,
  ReportesAPI,
} from '../api/rest-api';

import TMatrizHead from './matriz/TMatrizHead';
import TAplicacionRow from './matriz/TAplicacionRow';
import TAplicacionNuevaRow from './matriz/TAplicacionNuevaRow';
import TMatrixFooter from './matriz/TMatrixFooter';
import DateHelper from '../helpers/dateHelper';
import ProductoHelper from '../helpers/ProductoHelper';
import CrearProductoFormProvider from './context/CrearProducto';

import './Matriz.css';

const $ = require('jquery');

window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

class Matriz extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nuevasAplicaciones: {
      },
      aplicaciones: {
        sigatoka: [],
        foliar: [],
        parcial: [],
        erwinia: []
      },
      filtros: {
        fincaSeleccionada: 1,
        anioSeleccionado: '2020',
      },
      tipoHectareas: TIPO_HECTAREAS,
    };

    this.removerAplicacion = this.removerAplicacion.bind(this);
    this.removerAplicacionNueva = this.removerAplicacionNueva.bind(this);
    this.crearAplicacion = this.crearAplicacion.bind(this);

    this.agregarFila = this.agregarFila.bind(this);

    this.agregarProductoNuevo = this.agregarProductoNuevo.bind(this);
    this.agregarProducto = this.agregarProducto.bind(this);

    this.removerProducto = this.removerProducto.bind(this);
    this.removerProductoNuevo = this.removerProductoNuevo.bind(this);
  }

  componentWillMount() {
    const { anioSeleccionado: anio, fincaSeleccionada: finca } = this.state;
  }


    removerAplicacion = (id, programa) => {
      const {
        crearAplicacion,
        crearAplicacionProducto,
        fetchMatrizDeCostos,
        setMatrizCostos,
        selectedFinca,
        selectedAnio,
        setNuevasAplicaciones,
      } = this.props;

      fetchMatrizDeCostos({ anio: selectedAnio, id_finca: selectedFinca }).then((response) => {
        setMatrizCostos(response.data);
        setNuevasAplicaciones({});
      }).catch((err) => {
        console.error(err);
      });
    }

    crearAplicacion = async (aplicacion, programa, indice) => {
      const { crearAplicacion: crearAppAsync, alert } = this.props;
      crearAppAsync({
        aplicacion, programa, indice, alert
      });
    }

    removerProducto = (id, programa, idApp) => {
      const { matrizCostos } = this.props; // programas
      const aplicacionesSeleccionadas = matrizCostos[programa];

      const aplicacion = aplicacionesSeleccionadas.find(app => app.id === idApp);

      const { productos } = aplicacion; // programas

      const indexProductoAEliminar = productos.findIndex(pr => pr.id === id);


      productos.splice(indexProductoAEliminar, 1);

      aplicacion.productos = productos;

      this.setState({
        aplicaciones: { ...matrizCostos, [programa]: aplicacionesSeleccionadas },
      });
    }

    removerProductoNuevo = (evt, index, programaKey, indexPr) => {
      const { nuevasAplicaciones, setNuevasAplicaciones } = this.props;
      const indexToDelete = indexPr;

      const nuevasApps = { ...nuevasAplicaciones };
      nuevasApps[programaKey][index].productos.splice(indexToDelete);

      setNuevasAplicaciones(nuevasApps);
    }

    agregarProductoNuevo = (evt, programaKey, index, producto) => {
      const { nuevasAplicaciones, setNuevasAplicaciones } = this.props;
      const { productos, proveedores, dosis } = this.props;
      const jsonNames = ProductoHelper.getNamesFromJSONIds(proveedores, productos, dosis, producto);

      const nuevasApps = { ...nuevasAplicaciones };
      if (nuevasApps && nuevasApps[programaKey][index]) {
        if (!nuevasApps[programaKey][index].productos) {
          nuevasApps[programaKey][index].productos = [];
        }
      }

      nuevasApps[programaKey][index].productos.push(jsonNames);
      setNuevasAplicaciones(nuevasApps);
      try {
        $(evt.currentTarget).siblings()[0].click();
      } catch (e) { console.error(e); }
    }

    agregarProducto = (evt, programaKey, index, producto) => {
      const {
        crearAplicacionProducto,
        fetchMatrizDeCostos,
        setMatrizCostos,
        selectedFinca,
        selectedAnio,
      } = this.props;

      try {
        $(evt.currentTarget).siblings()[0].click();
      } catch (e) { console.error(e); }
      crearAplicacionProducto(producto).then((response) => {
        fetchMatrizDeCostos({ anio: selectedAnio, id_finca: selectedFinca }).then((response) => {
          setMatrizCostos(response.data);
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        console.error(err);
      });
    }

    removerAplicacionNueva = (index, programaKey) => {
      const { nuevasAplicaciones, setNuevasAplicaciones } = this.props;
      const indexToDelete = index;

      const nuevasApps = { ...nuevasAplicaciones };
      nuevasApps[programaKey].splice(indexToDelete, 1);

      setNuevasAplicaciones(nuevasApps);
    }

    agregarFila = programaKey => (evt) => {
      const { nuevasAplicaciones, setNuevasAplicaciones } = this.props;

      const nuevasApps = { ...nuevasAplicaciones };
      if (nuevasApps && !nuevasApps[programaKey]) {
        nuevasApps[programaKey] = [];
      }
      // creamos un nuevo elemento vacio
      nuevasApps[programaKey].push({});

      setNuevasAplicaciones(
        {
          ...nuevasAplicaciones,
          [programaKey]: nuevasApps[programaKey]
        }
      );
    }


  crearNuevoValorTemporal = (aplicacion, programa, newValue, atributo) => {
    const { matrizCostos } = this.props; // programas
    if (!matrizCostos) return (<div />);
    const aplicacionesSeleccionadas = matrizCostos[programa];

    const indexToReplace = aplicacionesSeleccionadas.findIndex(app => app.id === aplicacion.id);

    const newAplicacion = { ...aplicacionesSeleccionadas[indexToReplace] };
    if (atributo === 'dias_atraso') {
      const { fecha_programacion, fecha_real } = aplicacion;
      const fecha = DateHelper.addDaysToDate(fecha_programacion, newValue);
      newAplicacion.fecha_real = DateHelper.getFormattedDate(fecha);
    }

    newAplicacion[atributo] = newValue;

    aplicacionesSeleccionadas.splice(indexToReplace, 1, newAplicacion);

    this.setState({
      aplicaciones: { ...matrizCostos, [programa]: aplicacionesSeleccionadas },
    });
  }


  render() {
    const {
      tipoHectareas,
    } = this.state;

    const {
      programas,
      matrizCostos,
      nuevasAplicaciones,
      proveedores,
      motivosAtraso,
      fetchTarifasFromProgramaId,
      apiStatus
    } = this.props;

    const {
      tipoHectarea: propTipohectarea
    } = this.props;


    let tipoHectarea = {};
    tipoHectareas.forEach((elemento, index) => {
      if (elemento.nombre === propTipohectarea) {
        tipoHectarea = elemento;
      }
    });

    if (!matrizCostos) return <div />;
    const programasJSX = Object.keys(matrizCostos).map(function (key, index) {
      const programa = matrizCostos[key];
      const titulo = StringHelper.capitalize(key);
      const isParcial = PARCIAL === key;

      const aplicacionesNuevasACrearse = nuevasAplicaciones && nuevasAplicaciones[key] ? nuevasAplicaciones[key] : [];
      const appsNuevasJSX = aplicacionesNuevasACrearse.map((appNueva, i) => (
        <TAplicacionNuevaRow
          apiStatus={apiStatus[labels.CREAR_APLICACION]}
          aplicacionesNuevas={aplicacionesNuevasACrearse}
          key={i}
          indice={i}
          programaKey={key}
          data={appNueva}
          programa={programa}
          removerAplicacionNueva={this.removerAplicacionNueva}
          crearAplicacion={this.crearAplicacion}
          isAplicacionNueva
          isParcial={isParcial}
          tipoHectarea={tipoHectarea}
          programas={programas || []}
          removerProducto={this.removerProductoNuevo}
          agregarProducto={this.agregarProductoNuevo}
          proveedores={proveedores}
          motivosAtraso={motivosAtraso}
          matrizCostos={matrizCostos}
          fetchTarifasFromProgramaId={fetchTarifasFromProgramaId}
          isParcial={isParcial}
        />
      ));
      
      return (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="portlet light portlet-fit portlet-datatable bordered">
              <div className="portlet-title" style={{ letterSpacing: '1px' }}>
                <div className="caption">
                  {titulo}
                </div>


              </div>
              <div className="portlet-body">
                <button
                  className="btn btn-circle btn-success"
                  onClick={this.agregarFila(key)}
                >
                        Agregar fila
                  {' '}
                  <i className="fa fa-plus" />
                </button>

                <div className=" table-scrollable">
                  <table className="table table-bordered">
                    <TMatrizHead
                      programa={programa}
                      aplicacionesNuevas={aplicacionesNuevasACrearse}
                      isParcial={isParcial}
                    />
                    <tbody>
                      {appsNuevasJSX}
                      {matrizCostos[key].map((aplicacion, index) => (
                        <TAplicacionRow
                          aplicacionesNuevas={aplicacionesNuevasACrearse}
                          indice={index}
                          programa={key}
                          programaData={programa}
                          aplicacion={aplicacion}
                          tipoHectarea={tipoHectarea}
                          crearNuevoValorTemporal={this.crearNuevoValorTemporal}
                          removerAplicacion={this.removerAplicacion}
                          removerProducto={this.removerProducto}
                          agregarProducto={this.agregarProducto}
                          isParcial={isParcial}
                        />
                      ))
                    }
                    </tbody>
                    <TMatrixFooter
                      aplicacionesNuevas={aplicacionesNuevasACrearse}
                      programa={programa}
                      tipo_hectarea={tipoHectarea}
                      isParcial={isParcial}
                    />


                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }, this);

    return (
      <CrearProductoFormProvider>
        <div>
          { programasJSX }
        </div>
      </CrearProductoFormProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    fincas: state.fincas,
    motivosAtraso: state.motivosAtraso,
    programas: state.programas,
    matrizCostos: state.matrizCostos,
    tipoProductos: state.tipoProductos,
    productos: state.productos,
    proveedores: state.proveedores,
    dosis: state.dosis,
    selectedFinca: state.selectedFinca,
    selectedAnio: state.selectedAnio,
    nuevasAplicaciones: state.nuevasAplicaciones,
    apiStatus: state.apiStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    crearAplicacionProducto: data => AplicacionProductosAPI.post(data),
    crearAplicacion: data => dispatch(crearAplicacion(data)),
    fetchMatrizDeCostos: data => ReportesAPI.getMatrizDeCostos(data),//aqui esta<---------------
    setMatrizCostos: arr => dispatch(setMatrizCostos(arr)),
    setNuevasAplicaciones: arr => dispatch(setNuevasAplicaciones(arr)),
    fetchTarifasFromProgramaId: id_programa => dispatch(fetchTarifasFromProgramaId(id_programa)),
  };
}
export default withAlert()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Matriz));
