export const tipos = {
  FINCA: 'finca',
  PRODUCTO: 'producto',
  CICLO: 'ciclo',
  PARCIAL: 'parcial',
};

export const TIPOS_APLICACION = [
  { nombre: 'CICLO', id: 0 },
  { nombre: 'PARCIAL', id: 1 },
  { nombre: 'TODOS', id: null },
];

export const TIPOS_HECTAREAJE = [
  { nombre: 'PRODUCCION', id: 0 },
  { nombre: 'NETA', id: 1 },
  { nombre: 'BANANO', id: 2 },
  { nombre: 'FUMIGACION', id: 3 },
];

function getYears(startYear) {
  var currentYear = new Date().getFullYear(), years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export const anios = getYears(2017);

export const aniosTabla = getYears(2017);

export const TIPO_HECTAREAS = [
  {
    nombre: 'produccion',
    divisor: 340
  },
  {
    nombre: 'fumigacion',
    divisor: 500
  },
  {
    nombre: 'bananeno',
    divisor: 700
  }

];
export const dataProgramaTecnico = [
  {
    id: 1,
    ciclo: '0',
    fecha: '2019-05-13',
    frecuencia: '2',
    hectareaje: '10',
    data: [
      {
        nombre: 'PANOMA',
        tipo: 'FUNGICIDA',
        dosis: '0.4',
        cantidad: '80',
      },
      {
        nombre: 'BANKIT',
        tipo: 'COADYUVANTE',
        dosis: '0.4',
        cantidad: '80',
      },
    ]
  },
  {
    id: 2,
    ciclo: '1',
    fecha: '2019-05-13',
    frecuencia: '2',
    hectareaje: '10',
    data: [
      {
        id: 1,
        nombre: 'PANOMA',
        tipo: 'FUNGICIDA',
        dosis: '0.4',
        cantidad: '80',
      },
      {
        id: 2,
        nombre: 'BANKIT',
        tipo: 'COADYUVANTE',
        dosis: '0.4',
        cantidad: '80',
      },
    ]
  },
  {
    id: 3,
    ciclo: '1',
    fecha: '2019-05-13',
    frecuencia: '2',
    hectareaje: '10',
    data: []
  },
];


export const dataProgramaTecnico2 = [
  {
    id: 1,
    ciclo: '0',
    fungicidas: [
      {
        id: 1,
        nombre: 'VOLLEY',
        idProducto: 2,
        dosis: 0.2
      },
      {
        id: 2,
        nombre: 'BRAVO',
        idProducto: 2,
        dosis: 0.2
      }
    ],
  },
  {
    id: 2,
    ciclo: '0',
    fungicidas: [
      {
        id: 1,
        nombre: 'PAMONA',
        idProducto: 1,
        dosis: 0.3
      },
      {
        id: 2,
        nombre: 'PAMONA',
        idProducto: 1,
        dosis: 0.4
      },
      {
        id: 3,
        nombre: 'PAMONA',
        idProducto: 1,
        dosis: 0.4
      }
    ],
  },
];

export const FUNGICIDA = 'Fungicida';
export const ACEITE = 'Aceite';
