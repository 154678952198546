
export default class Ciclos {
  constructor(data = [], dataCiclosHectareaje = [], selectedAnio) {
    this.data = data;
    this.dataHectareajeCiclo = dataCiclosHectareaje;
    this.selectedAnio = Number.parseInt((selectedAnio));
  }


  // promedio
  get promedio() {
    let year = null;
    let anioData = null;
    let promedio = 0;

    const { data } = this;
    const { legend, series } = data;

    year = this.selectedAnio;
    if (Array.isArray(series)) {
      anioData = series.find(serie => serie.name === year);
      if (anioData) {
        const anioDataSanatize = [...anioData.data.filter(obj => obj)];
        const suma = anioDataSanatize.reduce((acum, valor) => Number(acum) + (Number(valor) || 0), 0);

        promedio = anioDataSanatize.length > 0 ? parseFloat(Number(suma) / anioDataSanatize.length).toFixed(2) : suma.toFixed(2);
      }
    }

    return promedio;
  }

  // mayor
  get mayor() {
    let finca = '';
    let year = null;
    let anioData = null;
    const maxes = [];
    let max = 0;

    const { data, dataHectareajeCiclo } = this;
    const { legend, series } = data;
    year = this.selectedAnio;

    if (Array.isArray(series)) {
      anioData = series.find(serie => serie.name === year);

      if (anioData) {
        const m = Math.max(...anioData.data);
        const maxes = anioData.data.reduce((p, c, i, a) => (c == m ? p.concat(i) : p), []);
        if (maxes.length > 0) max = anioData.data[maxes[0]];

        if (dataHectareajeCiclo
          && dataHectareajeCiclo.series
          && dataHectareajeCiclo.xAxisData) {
          const { legend: legendHectareajeCiclo, series: seriesHectareajeCiclos, xAxisData } = dataHectareajeCiclo;
          const hcAnioData = seriesHectareajeCiclos.find(serie => serie.name === year);

          if (hcAnioData) {
            const valores = maxes.map((indice, index) => ({
              indice,
              valor: hcAnioData.data[indice]
            }));

            const fincaValorMaximo = valores.reduce((prev, current) => ((prev.valor > current.valor) ? prev : current), { valor: 0, indice: null });

            finca = xAxisData[fincaValorMaximo.indice];
          } else {
            try {
              finca = dataHectareajeCiclo.xAxisData[maxes[0]]; // fallback
            } catch (e) {
              console.error(e);
            }
          }
        }
      }
    }

    return {
      valor: max.toFixed(2),
      finca: finca || ''
    };
  }


  // menor
  get menor() {
    let finca = '';
    let year = null;
    let anioData = null;
    const mins = [];
    let min = 0;

    const { data, dataHectareajeCiclo } = this;
    const { legend, series } = data;
    year = this.selectedAnio;
    // if (Array.isArray(legend) && legend.length > 0) {
    //   year = legend[legend.length - 1];
    // }

    if (Array.isArray(series)) {
      anioData = series.find(serie => serie.name === year);

      if (anioData) {
        const m = Math.min(...anioData.data.filter(obj => obj));
        const mins = anioData.data.reduce((p, c, i, a) => (c == m ? p.concat(i) : p), []);

        if (mins.length > 0) min = m;

        if (dataHectareajeCiclo
          && dataHectareajeCiclo.series
          && dataHectareajeCiclo.xAxisData) {
          const { legend: legendHectareajeCiclo, series: seriesHectareajeCiclos, xAxisData } = dataHectareajeCiclo;
          // console.log(xAxisData);
          const hcAnioData = seriesHectareajeCiclos.find(serie => serie.name === year);
          // console.log(hcAnioData);

          if (hcAnioData) {
            const valores = mins.map((indice, index) => ({
              indice,
              valor: hcAnioData.data[indice]
            }));
            // console.log(valores);
            const fincaValorMin = valores.reduce((prev, current) => {
              if (prev.valor <= current.valor) {
                return current;
              }

              return prev;
            }, { valor: 0, indice: null });
            // console.log(fincaValorMin);
            finca = xAxisData[fincaValorMin.indice] || '';
          } else {
            try {
              finca = dataHectareajeCiclo.xAxisData[mins[0]]; // fallback
            } catch (e) {
              console.error(e);
            }
          }
        }
      }
    }

    return {
      valor: min.toFixed(2),
      finca: finca || ''
    };
  }
}
