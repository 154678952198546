import DateHelper from '../../helpers/dateHelper';
import { resetBoards } from './fetchAll';

import {
  ReportesAPI
} from '../../api/rest-api';

export const SELECT_SEMANA_INICIAL = 'SELECT_SEMANA_INICIAL';
const initialState = 1;

export const setSemanaInicial = value => async (dispatch, getState) => {
  const { selectedFinca } = getState();

  await dispatch({
    type: SELECT_SEMANA_INICIAL,
    value: value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SEMANA_INICIAL:
      return action.value;

    default:
      return state;
  }
}
