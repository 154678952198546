import { resetBoards } from './fetchAll';
export const SELECT_MENU = 'SELECT_MENU';
const initialState = null;

export const setMenu = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_MENU,
    value: value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_MENU:
      return action.value;

    default:
      return state;
  }
}
