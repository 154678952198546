import React, { Component } from 'react';

import { connect } from 'react-redux';

import Resumen from './reportes/resumen/Resumen';
import DateHelper from '../helpers/dateHelper';
import './SigatDashboard.css';

import { setProveedores } from '../redux/modules/proveedores';
import { setFincas } from '../redux/modules/fincas';
import { setMatrizCostos } from '../redux/modules/matrizCostos';
import { setMotivosAtraso } from '../redux/modules/motivosAtraso';

import { setCiclosAnio } from '../redux/modules/ciclosAnio';
import { setHectareajeAnio } from '../redux/modules/hectareajeAnio';
import { setCiclosHectareajeAnio } from '../redux/modules/ciclosHectareajePorAnio';

import { setHectarea } from '../redux/modules/selectedHectareaje';
import { setGerenteId } from '../redux/modules/selectedGerente';
// import { setPrograma } from '../redux/modules/selectedPrograma';
import { setCategoria } from '../redux/modules/selectedCategoria';
import { setMenu } from '../redux/modules/selectedMenu';
import { setProveedor } from '../redux/modules/selectedProveedor';
import { setFumigadora } from '../redux/modules/selectedFumigadora';
import { setSemanaInicial } from '../redux/modules/selectedSemanaInicial';
import { setSemanaFinal } from '../redux/modules/selectedSemanaFinal';
import { setTipoAplicacion } from '../redux/modules/selectedTipoAplicacion';
import { fetchAll } from '../redux/modules/fetchAll';

import { setAnioResumen } from '../redux/modules/selectedAnio';
import LoadingOverlay from 'react-loading-overlay';

import {
  TIPOS_APLICACION,
  TIPOS_HECTAREAJE,
  anios,
} from '../data';

import {
  AplicacionesAPI,
  ReportesAPI,
  ProveedoresAPI,
  FincasAPI,
  MotivoAtrasoAPI,
} from '../api/rest-api';

class SigatDashboard extends Component {
    componentDidMount = () => {
      const {
        fetchAll,
      } = this.props;

      fetchAll();
    }

    render() {
      const {
        menus,
        categorias,
        // programas,
        gerentes,
        ciclosAnio,
        fracs,
        diasAtraso,
        motivosAtrasoResumen,
        hectareajeAnio,
        ciclosHectareajePorAnio,
        selectedAnio,
        selectedSemanaInicial,
        selectedSemanaFinal,
        selectedHectareaje,
        selectedTipoAplicacion,
        selectedPrograma,
        selectedGerente,
        selectedCategoria,
        selectedMenu,

        setGerenteId,
        setHectarea,
        // setPrograma,
        setCategoria,
        setMenu,
        setAnio,
        setSemanaInicial,
        setSemanaFinal,
        setTipoAplicacion,
        loading,
        proveedores,
        fumigadoras,
        setProveedor,
        setFumigadora,
        selectedFumigadora,
        selectedProveedor
      } = this.props;

      let numWeeks = 0;
      if (selectedAnio
           && selectedAnio.toString() === DateHelper.getCurrentYear().toString()) {
        numWeeks = DateHelper.getWeeks(new Date(), null);
      }

      const weeks = Array.from(Array(numWeeks || 53), (x, index) => index + 1);
      let menusJSX = menus.map(menu => (
        <li
          key={menu.id}
        >
          <a
            href="#"
            data-menuid={menu.nombre}
            onClick={(evt) => { setMenu(menus.find(mn=> mn.nombre==evt.target.dataset.menuid)); }}
          >
            { menu.nombre }
          </a>
        </li>
      ));

      menusJSX = [(
        <li
          key={null}
        >
          <a
            href="#"
            data-menuid={null}
            onClick={(evt) => { setMenu(null); }}
          >
                TODOS
          </a>
        </li>
      ), menusJSX];




      let proveedoresJSX = proveedores.map(proveedor => (
        <li
          key={proveedor.id}
        >
          <a
            href="#"
            data-proveedorid={proveedor.id}
            onClick={(evt) => { setProveedor(proveedores.find((prov) => prov.id==evt.target.dataset.proveedorid)); }}
          >
            { proveedor.nombre }
          </a>
        </li>
      ));

      proveedoresJSX = [(
        <li
          key={null}
        >
          <a
            href="#"
            data-gerenteid={null}
            onClick={(evt) => { setProveedor(null); }}
          >
                TODOS
          </a>
        </li>
      ), proveedoresJSX];


      let fumigadorasJSX = fumigadoras.map(fumigadora => (
        <li
          key={fumigadora.id}
        >
          <a
            href="#"
            data-fumgadoraid={fumigadora.id}
            onClick={(evt) => { setFumigadora(fumigadora); }}
          >
            { fumigadora.nombre }
          </a>
        </li>
      ));

      fumigadorasJSX = [(
        <li
          key={null}
        >
          <a
            href="#"
            data-fumigadoraid={null}
            onClick={(evt) => { setFumigadora(null); }}
          >
                TODOS
          </a>
        </li>
      ), fumigadorasJSX];



      // const programasJSX = programas.map(programa => (
      //   <li
      //     key={programa.id}
      //   >
      //     <a
      //       href="#"
      //       data-programaid={programa.id}
      //       onClick={(evt) => { setPrograma(evt.target.dataset.programaid); }}
      //     >
      //       { programa.nombre }
      //     </a>
      //   </li>
      // ));
      

      let categoriasJSX = categorias.map(categoria => (
        <li
          key={categoria.id}
        >
          <a
            href="#"
            data-categoriaid={categoria.nombre}
            onClick={(evt) => {  setCategoria(categorias.find(ct=> ct.nombre==evt.target.dataset.categoriaid)); }}
          >
            { categoria.nombre }
          </a>
        </li>
      ));

      categoriasJSX = [(
        <li
          key={null}
        >
          <a
            href="#"
            data-categoriaid={null}
            onClick={(evt) => { setCategoria(null); }}
          >
                TODOS
          </a>
        </li>
      ), categoriasJSX];

    



      const weeksJSX = weeks.map(week => (
        <li
          key={week}
        >
          <a
            href="#"
            data-weekid={week}
            onClick={(evt) => { setSemanaInicial(evt.target.dataset.weekid); }}
          >
            {week}
          </a>
        </li>
      ));

      let gerentesJSX = gerentes.map(gerente => (
        <li
          key={gerente.id}
        >
          <a
            href="#"
            data-gerenteid={gerente.id}
            onClick={(evt) => { setGerenteId(evt.target.dataset.gerenteid); }}
          >
            { gerente.nombre }
          </a>
        </li>
      ));
      gerentesJSX = [(
        <li
          key={null}
        >
          <a
            href="#"
            data-gerenteid={null}
            onClick={(evt) => { setGerenteId(null); }}
          >
                TODOS
          </a>
        </li>
      ), gerentesJSX];

      const weeksEndJSX = weeks.map(week => (
        <li
          key={week}
        >
          <a
            href="#"
            data-weekid={week}
            onClick={(evt) => { setSemanaFinal(evt.target.dataset.weekid); }}
          >
            {week}
          </a>
        </li>
      ));

      const aniosJSX = anios.map(anio => (
        <li
          key={anio}
        >
          <a
            href="#"
            data-anioid={anio}
            onClick={(evt) => { setAnio(evt.target.dataset.anioid); }}
          >
            {anio}
          </a>
        </li>
      ));

      const tiposAplicacionJSX = TIPOS_APLICACION.map(app => (
        <li key={app.id}>
          <a
            href="#"
            data-tipoid={app.id}
            onClick={(evt) => { setTipoAplicacion(evt.target.dataset.tipoid); }}
          >
            {app.nombre}
          </a>
        </li>
      ));

      const tiposHectareajeJSX = TIPOS_HECTAREAJE.map(hc => (
        <li key={hc.id}>
          <a
            href="#"
            data-hc={hc.id}
            onClick={(evt) => { setHectarea(evt.target.dataset.hc); }}
          >
            {hc.nombre}
          </a>
        </li>
      ));

      const selectedApp = TIPOS_APLICACION.find((app) => {
        if (app.id && selectedTipoAplicacion) {
          return app.id.toString() === selectedTipoAplicacion.toString();
        }
        return app.id === selectedTipoAplicacion;
      });
      const selectedHc = TIPOS_HECTAREAJE.find((hc) => {
        if (hc.id !== null && selectedHectareaje !== null) {
          return hc.id.toString() === selectedHectareaje.toString();
        }
        return hc.id === selectedHectareaje;
      });

      // const selectedPr = programas.find(pr => pr.id.toString() === selectedPrograma.toString());
      const selectedCat = selectedCategoria ? categorias.find(ct => ct.nombre.toString() === selectedCategoria.nombre.toString()) : null;
      const selectedMen = selectedMenu ? menus.find(mn => mn.nombre.toString() === selectedMenu.nombre.toString()) : null;

      let selectedGe = null;
      if (selectedGerente) {
        selectedGe = gerentes.find(ge => ge.id.toString() === selectedGerente.toString());
      }

      return (
        <>
          <div className="page-content-wrapper">
            <div id="resumenCiclos" className="page-content">
              <div className="page-head">
                <div className="page-title">
                  <h1>{loading ? 'Cargando Información' : 'Resumen Ejecutivo' }</h1>
                </div>
                <div className="page-toolbar">
                  <div className="row hide">
                    <div className="pull-right">
                      <a className="btn red" ng-click="exportPDF()">PDF</a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        {'Programas: '}
                        { selectedMen ? `${selectedMen.nombre}` : 'TODOS' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {menusJSX}
                      </ul>
                    </div>
                    {/* <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        {' '}
                        { selectedPr ? `Programa ${selectedPr.nombre}` : '' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {programasJSX}
                      </ul>
                    </div> */}
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        {'Categoria: '}
                        { selectedCat ? `${selectedCat.nombre}` : 'TODOS' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {categoriasJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        { selectedHc ? selectedHc.nombre : '' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {tiposHectareajeJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        {selectedAnio}
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        { aniosJSX }
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
              DESDE
                        {' '}
                        { selectedSemanaInicial || '' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        <li ng-click="setFilterWeeks({id : '' , label : 'SEM'})">
                          <a href="#">
                                    SEMANAS
                          </a>
                        </li>
                        {weeksJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
              HASTA
                        {' '}
                        { selectedSemanaFinal || '' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        <li ng-click="setFilterWeeks2({id : '' , label : 'SEM'})">
                          <a href="#">
                                    SEMANAS
                          </a>
                        </li>
                        {weeksEndJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        { selectedApp != null ? selectedApp.nombre : '' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {tiposAplicacionJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        { selectedGe ? selectedGe.nombre : 'TODOS' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        <li ng-repeat="(key , value) in semanas" ng-click="setFilterWeeks(value)" className="ng-scope">
                          <a href="#" className="ng-binding" />
                        </li>
                        {gerentesJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        { selectedProveedor ? selectedProveedor.nombre : 'TODOS' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {proveedoresJSX}
                      </ul>
                    </div>
                    <div className="btn-group pull-right">
                      <button type="button" className="btn btn-fit-height blue dropdown-toggle ng-binding" data-toggle="dropdown" data-hover="dropdown" data-delay="1000" data-close-others="true" aria-expanded="false" data-_extension-text-contrast="">
                        { selectedFumigadora ? selectedFumigadora.nombre : 'TODOS' }
                        <i className="fa fa-angle-down" />
                      </button>
                      <ul className="dropdown-menu pull-right" role="menu">
                        {fumigadorasJSX}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="page-breadcrumb breadcrumb">
                <li>
                  <span className="active">Resumen</span>
                </li>
              </ul>
              <div className="row">
                <div className="col-md-12">
                  <LoadingOverlay
                    active={loading}
                    spinner
                    text="Cargando contenido"
                  >
                    <Resumen
                      ciclosAnio={ciclosAnio}
                      hectareajeAnio={hectareajeAnio}
                      ciclosHectareajePorAnio={ciclosHectareajePorAnio}
                      fracs={fracs}
                      diasAtraso={diasAtraso}
                      motivosAtrasoResumen={motivosAtrasoResumen}
                      selectedAnio={selectedAnio}
                    />

                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
}


function mapStateToProps(state) {
  return {
    categorias: state.categorias,
    menus: state.menus,
    proveedores: state.proveedores,
    fumigadoras: state.fumigadoras,
    fincas: state.fincas,
    motivosAtraso: state.motivosAtraso,
    // programas: state.programas,
    matrizCostos: state.matrizCostos,
    ciclosAnio: state.ciclosAnio,
    fracs: state.fracs,
    diasAtraso: state.diasAtraso,
    motivosAtrasoResumen: state.motivosAtrasoResumen,
    hectareajeAnio: state.hectareajeAnio,
    ciclosHectareajePorAnio: state.ciclosHectareajePorAnio,
    selectedAnio: state.selectedAnio,
    selectedSemanaInicial: state.selectedSemanaInicial,
    selectedSemanaFinal: state.selectedSemanaFinal,
    selectedHectareaje: state.selectedHectareaje,
    selectedTipoAplicacion: state.selectedTipoAplicacion,
    selectedMenu: state.selectedMenu,
    selectedPrograma: state.selectedPrograma,
    selectedCategoria: state.selectedCategoria,
    selectedGerente: state.selectedGerente,
    selectedProveedor: state.selectedProveedor,
    selectedFumigadora: state.selectedFumigadora,
    loading: state.loading,
    gerentes: state.gerentes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProveedores: arr => dispatch(setProveedores(arr)),
    setProveedor: val => dispatch(setProveedor(val)),
    setFumigadora: val => dispatch(setFumigadora(val)),
    setFincas: arr => dispatch(setFincas(arr)),
    setMotivosAtraso: arr => dispatch(setMotivosAtraso(arr)),
    setMatrizCostos: arr => dispatch(setMatrizCostos(arr)),

    setCiclosAnio: arr => dispatch(setCiclosAnio(arr)),
    setHectareajeAnio: arr => dispatch(setHectareajeAnio(arr)),
    setCiclosHectareajeAnio: arr => dispatch(setCiclosHectareajeAnio(arr)),
    fetchProveedores: () => ProveedoresAPI.get(),
    fetchMotivosAtraso: () => MotivoAtrasoAPI.get(),
    fetchFincas: () => FincasAPI.get(),
    fetchCiclosPorAnio: data => ReportesAPI.getCiclosAnio(data),
    fetchHectareajeAnio: data => ReportesAPI.getHectareajeAnio(data),
    fetchCiclosHectareaAnio: data => ReportesAPI.getCiclosHectareajeAnio(data),
    fetchAll: () => dispatch(fetchAll()),

    setHectarea: val => dispatch(setHectarea(val)),
    setSemanaInicial: val => dispatch(setSemanaInicial(val)),
    setSemanaFinal: val => dispatch(setSemanaFinal(val)),
    setTipoAplicacion: val => dispatch(setTipoAplicacion(val)),
    setAnio: val => dispatch(setAnioResumen(val)),
    // setPrograma: val => dispatch(setPrograma(val)),
    setCategoria: val => dispatch(setCategoria(val)),
    setMenu: val => dispatch(setMenu(val)),
    setGerenteId: val => dispatch(setGerenteId(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigatDashboard);
