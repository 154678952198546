import { resetBoards } from './fetchAll';

export const SELECT_PROVEEDOR = 'SELECT_PROVEEDOR';

const initialState = null;

// export function selectPrograma(value) {
//   return {
//     type: SELECT_PROGRAMA,
//     value
//   };
// }

export const setProveedor = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_PROVEEDOR,
    value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROVEEDOR:
      return action.value;

    default:
      return state;
  }
}
