import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ACEITE, FUNGICIDA } from '../../../data';

const uuidv4 = require('uuid/v4');


export default class TBodyRow extends Component {
  constructor(props) {
    super(props);

    const { rowData } = props;
    this.state = {
      rowData,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataRow !== prevState.dataRow) {
      return ({
        dataRow: nextProps.dataRow,
      });
    }
    return prevState;
  }

  render() {
    const textCenter = 'text-center';
    const className = textCenter;
    const { rowData } = this.state;
    const { maxArrSize, aceitesArrCount,aguaMaxSize,aguasMatrix } = this.props;
    const data = rowData.data ? rowData.data : [];

    const fungicidas = data.filter(producto => producto.tipo === FUNGICIDA);
    const aceites = data.filter(producto => producto.tipo === ACEITE);
    const aguas = data.filter(producto => producto.tipo === 'MEZCLA');

    console.log([...fungicidas.concat(aceites)]);
    //const galonaje = Number([...fungicidas.concat(aceites)].reduce((acum, pr) => Number(acum) + Number(pr.dosis), 0)) * rowData.hectareaje || 0;
    const galonaje = Number([...aguas.concat(aceites)].reduce((acum, pr) => Number(acum) + Number(pr.dosis), 0)) * rowData.hectareaje/rowData.hectareaje2 || 0;
    let fungicidasJSX = (<React.Fragment />);
    let aceitesJSX = (<React.Fragment />);
    let aguasJSX = (<React.Fragment />);

    for (let i = 0; i < aceitesArrCount.length; i++) {
      if (aceites[i]) {
        const ac = aceites[i];
        aceitesJSX = [aceitesJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { ac.nombre }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { ac.dosis }
            </td>
          </>
        )];
      } else {
        aceitesJSX = [aceitesJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
          </>
        )];
      }
    }

    for (let i = 0; i < maxArrSize.length; i++) {
      if (fungicidas[i]) {
        const fung = fungicidas[i];
        fungicidasJSX = [fungicidasJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { fung.nombre }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { fung.dosis }
            </td>
          </>
        )];
      } else {
        fungicidasJSX = [fungicidasJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
          </>
        )];
      }
    }
    console.log('MATRIX AGUA');
    console.log(aguasMatrix);
    console.log(fungicidas);
    for (let i = 0; i < aguaMaxSize; i++) {
      if (aguas[i]) {
        
        const agua = aguas[i];
        aguasJSX = [aguasJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { agua.nombre }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { agua.dosis }
            </td>
          </>
        )];
      } else {
        aguasJSX = [aguasJSX, (
          <>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
          </>
        )];
      }
    }

    console.log(fungicidasJSX);
    console.log(aguasJSX);

    return (
      <tr key={`${rowData.ciclo}`}>
        <td
          colSpan="1"
          className={className}
        >
          { rowData.ciclo }
        </td>
        {/* { fungicidasJSX } */}
        { aceitesJSX }
        { aguasJSX }

        <td
          colSpan="1"
          className={className}
        >
          { galonaje.toFixed(2) || '-' }
        </td>
      </tr>
    );
  }
}
