export const SET_FUMIGADORAS = 'SET_FUMIGADORAS';

const initialState = [];

export function setFumigadoras(value) {
  return {
    type: SET_FUMIGADORAS,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FUMIGADORAS:
      return action.value;

    default:
      return state;
  }
}
