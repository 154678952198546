import React, { Component } from 'react';
import { connect } from 'react-redux';


import ReactEcharts from 'echarts-for-react';
import THead from './Thead';
import TBody from './Tbody';
import TFooter from './TFooter';
import Indicadores from './Indicadores';

import DataParser from './DataParser';
import HectareasCiclo from './hectareasCiclo/HectareasCiclo';
import Ciclos from './ciclos/Ciclos';
import Fracs from './Frac';
import DiasAtraso from './DiasAtraso';
import MotivosAtrasoResumen from './MotivosAtrasoResumen';
import { selectElementContents } from '../../../helpers/DocumentHelper';


import { anios } from '../../../data';
import { getOption, parseResumenHectareaje, parseResumenCiclos } from '../../../helpers/echarts/resumenCiclos';
import { parseIndicadoresData } from '../../../helpers/resumen/indicadoresHelper';


import { setComparativo } from '../../../redux/modules/selectedComparativo';

const opcionDefault = {
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: [{
    data: [],
    type: 'bar'
  }]
};

let opcionHectareajeAnio = {};
let opcionCiclosAnio = {};
let opcionCiclosHectareaje = {};

class Resumen extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.props.setComparativo(value);
  }

    render = () => {
      const {
        hectareajeAnio,
        ciclosAnio,
        ciclosHectareajePorAnio,
        fracs,
        diasAtraso,
        motivosAtrasoResumen,
        selectedAnio,
        selectedComparativo
      } = this.props;

      opcionHectareajeAnio = opcionDefault;
      opcionCiclosAnio = opcionDefault;
      opcionCiclosHectareaje = opcionDefault;

      const parsedHectareajeAnio = parseResumenHectareaje(hectareajeAnio, anios);
      const parsedCiclosAnio = parseResumenCiclos(ciclosAnio, anios);
      const parsedCiclosHectareaje = parseResumenCiclos(ciclosHectareajePorAnio, anios);

      const indicadoresData = parseIndicadoresData(parsedHectareajeAnio, parsedCiclosAnio, parsedCiclosHectareaje, selectedAnio);

      opcionHectareajeAnio = getOption(
        '',
        parsedHectareajeAnio.legend,
        parsedHectareajeAnio.xAxisData,
        parsedHectareajeAnio.series
      );

      opcionCiclosAnio = getOption(
        '',
        parsedCiclosAnio.legend,
        parsedCiclosAnio.xAxisData,
        parsedCiclosAnio.series
      );

      opcionCiclosHectareaje = getOption(
        '',
        parsedCiclosHectareaje.legend,
        parsedCiclosHectareaje.xAxisData,
        parsedCiclosHectareaje.series
      );

      return (
        <>
          <Indicadores data={indicadoresData} />
          <div className="portlet box green-haze">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">TABLA RESUMEN</span>
              </div>

              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#tbl_resumen_costos" data-toggle="tab" aria-expanded="true"> $/Ha Año </a>
                </li>
                <li>
                  <a href="#tbl_dolares_ha_ciclo" data-toggle="tab" aria-expanded="false"> $/Ha Ciclo </a>
                </li>
                <li>
                  <a href="#tbl_ciclos_aplicados" data-toggle="tab" aria-expanded="false"> Ciclos </a>
                </li>
              </ul>
            </div>
            <div className="portlet-body">
              <div className="tab-content">
                <div id="tbl_resumen_costos" className="tab-pane active">
                  <div className="col-md-4" style={{ paddingLeft: 0 }}>
                    <label className="container-checkbox">
                      Comparativo
                      <input
                        name="isGoing"
                        type="checkbox"
                        checked={selectedComparativo}
                        onChange={this.handleInputChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  <div className="col-md-8 text-right">
                    <div className="btn-group btn-group-devided" data-toggle="buttons" />
                    <div className="btn-group pull-right">
                      <a className="btn blue btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                            Exportar
                        {' '}
                        <i className="fa fa-angle-down" />
                      </a>
                      <ul className="dropdown-menu pull-right">
                        <li>
                          <a
                            href="javascript:;"
                            onClick={(evt) => {
                              selectElementContents(document.getElementById('resumen'));
                            }}
                          >
                            {' '}
Copiar
                            {' '}
                          </a>
                        </li>
                        <li>
                          <a href="javascript:;"> Imprimir </a>
                        </li>
                        <li className="divider" />
                        <li>
                          <a href="javascript:;">Excel</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="table-container table-responsive table-scrollable">
                    <table
                      id="resumen"
                      className="table table-bordered table-hover"
                    >
                      <THead
                        anios={anios}
                        showTotales
                      />

                      <TBody
                        anios={anios}
                        data={hectareajeAnio || []}
                      />
                      <TFooter
                        anios={anios}
                        data={hectareajeAnio || []}
                        dataParser={DataParser}
                        isResumen
                      />
                    </table>
                  </div>
                </div>
                <HectareasCiclo
                  idTable="hectareas"
                  anios={anios}
                  data={ciclosHectareajePorAnio || []}
                />
                <Ciclos
                  idTable="ciclos"
                  anios={anios}
                  data={ciclosAnio || []}
                />

              </div>
            </div>
          </div>
          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase"> COMPARACION CICLOS POR AÑO </span>
              </div>
            </div>
            <div className="page-bar box-body">
              <ReactEcharts
                option={opcionCiclosAnio}
                notMerge
                lazyUpdate
              />
            </div>
          </div>

          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">  DOLARES POR HECTAREA/AÑO  </span>
              </div>
            </div>
            <div className="page-bar box-body">
              <ReactEcharts
                option={opcionHectareajeAnio}
                notMerge
                lazyUpdate
              />
            </div>
          </div>

          <div className="portlet light bordered">
            <div className="portlet-title">
              <div className="caption">
                <span className="caption-subject bold uppercase">   DOLARES POR HECTAREA/CICLO   </span>
              </div>
            </div>
            <div className="page-bar box-body">
              <ReactEcharts
                option={opcionCiclosHectareaje}
                notMerge
                lazyUpdate
              />
            </div>
          </div>
          <Fracs
            data={fracs}
          />
          <DiasAtraso
            data={diasAtraso}
          />

          <MotivosAtrasoResumen
            data={motivosAtrasoResumen}
          />

        </>
      );
    }
}


function mapStateToProps(state) {
  return {
    selectedComparativo: state.selectedComparativo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setComparativo: value => dispatch(setComparativo(value)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resumen);


// export default React.memo(Resumen);
