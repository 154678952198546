import { transformNumber } from '../../../../helpers/numberHelper';

export default class DataParser {
  static fillDataForYears(dataAdministracion, years) {

    const valores = years.map((year) => {
      let value = {};
      dataAdministracion.forEach((childValue) => {
        if (childValue.anio.toString() === year.toString()) {
          value = childValue;
        }
      });
      return value;
    });

      return valores;
  }

  static getDataFromFinca(data, years) {
    const dataRows = DataParser.fillDataForYears(data, years);
    const totales = dataRows.map(row => row.hasOwnProperty("value") ?  row.value : null);

    return {
      total: totales
    };
  }
}
