import React from 'react';

const Thead = (props) => {
    const titleNames = ['CICLO', 'SEM', 'FECHA', 'FREC', 'HA']

    const headings = titleNames.map((valor, index) => (
        <>
          <th key={index} className="textCenter">{valor}</th>
        </> ));

  return (
    <thead>
      <tr>
        { headings }
      </tr>
    </thead>
  );
};

export default React.memo(Thead);
