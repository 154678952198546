import React, { Component } from 'react';
import THead from '../Thead';
import TFooter from '../TFooter';
import DataParser from './DataParser';
import TCiclosBody from './TCiclosBody';

const Ciclos = (props) => {
  const { anios, data } = props;
  return (
    <div id="tbl_ciclos_aplicados" className="tab-pane">
      <div className="col-md-12 text-right">
        <div className="btn-group btn-group-devided" data-toggle="buttons" />
        <div className="btn-group pull-right">
          <a className="btn blue btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                            Exportar
            {' '}
            <i className="fa fa-angle-down" />
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a href="javascript:;" ng-click="exportPrint('datatable_dolares_hectarea')"> Imprimir </a>
            </li>
            <li className="divider" />
            <li>
              <a href="javascript:;" ng-click="fnExcelReport('datatable_dolares_hectarea', '$/Ha Año')">Excel</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-md-5 col-sm-12 pull-right">
        <ul style={{ listStyle: 'none' }}>
          <li>
            <div style={{ width: '10px', height: '10px', display: 'inline-block' }} className="bg-red-thunderbird bg-font-red-thunderbird" />
            {' '}
Más número de ciclos que el año pasado
          </li>
          <li>
            <div style={{ width: '10px', height: '10px', display: 'inline-block' }} className="bg-yellow-gold bg-font-yellow-gold" />
            {' '}
Mismo número de ciclos que el año pasado
          </li>
          <li>
            <div style={{ width: '10px', height: '10px', display: 'inline-block' }} className="bg-green-haze bg-font-green-haze" />
            {' '}
Menor número de ciclos que el año pasado
          </li>
        </ul>
      </div>
      <div className="table-container table-responsive table-scrollable">
        <table className="table table-bordered table-hover">
          <THead
            showTotales={false}
            anios={anios}
          />
          <TCiclosBody
            anios={anios}
            data={data}
          />
          <TFooter
            anios={anios}
            data={data}
            dataParser={DataParser}
          />
        </table>
      </div>
    </div>
  );
};

export default Ciclos;
