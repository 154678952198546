import React, { Component } from 'react';

// import MatrizExcel from './reportes/matriz_excel/MatrizExcel';
import './CostosDashboard.css';
import Matriz from './Matriz';
import DashboardDataLoading from './DashboardDataLoading';

import { isEmptyObject } from '../helpers/ObjectHelper';

import { anios, TIPOS_HECTAREAJE } from '../data';


class CostosDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtros: {
        tipoHectarea: 'fumigacion',
      },
      loadMatriz: true,
    };
    this.handleTipoHectarea = this.handleTipoHectarea.bind(this);
    this.updateLoadMatrizState = this.updateLoadMatrizState.bind(this);
  }

  handleTipoHectarea(event) {
    this.setState({ filtros: { tipoHectarea: event.target.value } });
  }

    updateLoadMatrizState = (newState) => {
      this.setState({
        loadMatriz: newState
      });
    }


    render() {
      const { tipoHectarea } = this.state.filtros;
      const { loadMatriz } = this.state;
      const {
        fetchCategorias,
        setCategorias,
        proveedores,
        fincas,
        selectedFinca,
        matrizCostos,
        tipoProductos,
        setProveedores,
        setMatrizCostos,
        setMotivosAtraso,
        setTiposProducto,
        fetchTiposProducto,
        fetchProveedores,
        fetchMotivosAtraso,
        setFincas,
        fetchFincas,
        fetchMatrizDeCostos,
        setProductos,
        fetchProductos,
        setDosis,
        fetchDosis,
        setProgramas,
        fetchProgramas,
        selectFinca,
        setAnio,
        selectedAnio,
        selectedHectareaje,
        selectHectareaje
      } = this.props;

      if ((loadMatriz || !proveedores || proveedores.length === 0)
        || isEmptyObject(matrizCostos)
          || !fincas) {
        return (
          <DashboardDataLoading
            loadMatriz={loadMatriz}
            updateLoadMatrizState={this.updateLoadMatrizState}
            fetchCategorias={fetchCategorias}
            setCategorias={setCategorias}
            fetchProveedores={fetchProveedores}
            setProveedores={setProveedores}

            setMatrizCostos={setMatrizCostos}
            fetchMatrizDeCostos={fetchMatrizDeCostos}

            fetchMotivosAtraso={fetchMotivosAtraso}
            setMotivosAtraso={setMotivosAtraso}

            fetchFincas={fetchFincas}
            setFincas={setFincas}

            fetchTiposProducto={fetchTiposProducto}
            setTiposProducto={setTiposProducto}

            fetchProductos={fetchProductos}
            setProductos={setProductos}

            fetchDosis={fetchDosis}
            setDosis={setDosis}

            fetchProgramas={fetchProgramas}
            setProgramas={setProgramas}

            selectedFinca={selectedFinca}
            selectAnio={setAnio}
          />
        );
      }

      const fincasJSX = fincas.map(finca => (
        <option value={finca.id} key={finca.id}>{ finca.nombre }</option>));

      const aniosJSX = anios.map(anio => (
        <option value={anio} key={anio}>{ anio }</option>));

      const tiposHectareasJSX = TIPOS_HECTAREAJE.map(tipo_hectareaje =>(
        <option value={tipo_hectareaje.id} key={tipo_hectareaje.id}>{ tipo_hectareaje.nombre }</option>));

      
      return (
        <>
          <div className="page-content-wrapper">
            <link type="text/css" href="http://cdn.procesos-iq.com//global/plugins/datatables/plugins/bootstrap/datatables.bootstrap.css" rel="stylesheet" />
            <div id="costos" className="page-content">
              <div className="page-head">
                <div className="page-title">
                  <h1>MATRIZ EXCEL</h1>
                </div>
                <div className="page-toolbar">
                  <div style={{ float: 'right' }}>
                    <div className="col-md-offset-1 col-md-3">
                      <label>Año: </label>
                      <br />
                      <select
                        id="anio"
                        className="input-sm "
                        style={{ width: '100%' }}
                        defaultValue={selectedAnio || null}
                        onChange={(evt) => {
                        setAnio(evt.target.value);
                      }
                 }
                      >
                        {aniosJSX}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Hectareas: </label>

                      <select className="input-sm" 
                      onChange = {(evt) => {
                        selectHectareaje(evt.target.value)
                      }}
                      defaultValue={selectedHectareaje}
                      >
                        {tiposHectareasJSX}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Finca: </label>
                      <select className="input-sm" onChange={selectFinca} defaultValue={selectedFinca || ''}>
                        {fincasJSX}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {' '}
              { /* page-head */}
              <ul className="page-breadcrumb breadcrumb">
                <li>
                  <a href="resumenCiclos">Inicio</a>
                  <i className="fa fa-circle" />
                </li>
                <li>
                  <span className="active">Listado</span>
                </li>
              </ul>
              <Matriz
                matrizCostos={matrizCostos}
                tipoHectarea={tipoHectarea}
              />
            </div>
          </div>
        </>
      );
    }
}

export default CostosDashboard;
