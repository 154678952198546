const SET_MOTIVOS_ATRASO = 'SET_MOTIVOS_ATRASO';

const initialState = [];

export function setMotivosAtraso(value) {
  return {
    type: SET_MOTIVOS_ATRASO,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MOTIVOS_ATRASO:
      return action.value;

    default:
      return state;
  }
}
