import { tipos } from '../../../data';
import { transformNumber } from '../../../helpers/numberHelper';

export default class DataParser {
  static getDataFromAdministracion(dataAdministracion, years) {
    const datos = years.map((year) => {
      const childValue = dataAdministracion.find(dato => parseInt(dato.anio) === parseInt(year));

      if (childValue) {
        return childValue;
      }
      return {
        anio: year, value: 0
      };
    });

    return [datos];
  }

  static getUniqueDataFromAdministracion(dataAdministracion, years) {
    const datos = years.map((year) => {
      const childValue = dataAdministracion.find(dato => parseInt(dato.anio) === parseInt(year));

      if (childValue) {
        return childValue;
      }
      return {
        anio: year, value: 0
      };
    });

    const finalData = [];
    let lastFilled = null;
    // SORT por anio!
    datos.forEach((element, i, arrChild) => {
      const filled = element;

      if (i === 0) {
        finalData.push(filled.value.toFixed(2));
        lastFilled = filled;
      } else if (lastFilled != null) {
        finalData.push(filled.value.toFixed(2));
        const aumento = filled.value - lastFilled.value;
        finalData.push(transformNumber((aumento / lastFilled.value * 100).toFixed(2)));
      }
      lastFilled = filled;
    });


    return finalData;
  }

  static getDataFromFinca(dataProductos, years) {
    const dataRows = dataProductos.map(producto => DataParser.getDataFromProducto(producto.data, years));
    const totales = dataRows.map(row => row.total);
    let totalRow = totales.reduce((r, a) => {
      a.forEach((b, i) => {
        r[i] = parseFloat(r[i] || 0) + parseFloat(b);
        r[i] = (transformNumber(r[i])).toFixed(2);
      });
      return r;
    }, []);

    let lastFilled = null;
    totalRow = totalRow.map((row, index, arr) => {
      const filled = row;
      if (index === 2) {
        const aumento = transformNumber((Number((parseFloat(arr[1]) - parseFloat(arr[0])) / parseFloat(arr[0])) * 100).toFixed(2));
        return aumento;
      } if (index !== 0 && index % 2 == 0) {
        const aumento = transformNumber(((Number(parseFloat(arr[index - 1]) - parseFloat(arr[index - 3] || 0)) / Number(parseFloat(arr[index - 3]))) * 100).toFixed(2));
        return aumento.toString();
      }
      lastFilled = row;
      return row;
    });
    return {
      data: dataRows,
      total: totalRow
    };
  }

  static getDataFromProducto(dataAdmins, years) {
    const dataProducts = dataAdmins.map((adminData) => {
      const dataTotal = [];
      const filledData = DataParser.getDataFromAdministracion(adminData.data, years);
      const filledData2 = DataParser.getUniqueDataFromAdministracion(adminData.data, years);

      filledData.forEach((value, index, array) => {
        let lastFilled = null;
        let dataAdminParsed = [];

        value.forEach((element, i, arrChild) => {
          const filled = element;
          if (i === 0) {
            dataAdminParsed.push(filled.value);
            lastFilled = filled;
          } else if (lastFilled != null) {
            dataAdminParsed.push(filled.value);
            const aumento = filled.value - lastFilled.value;
            dataAdminParsed.push(transformNumber((aumento / lastFilled.value * 100).toFixed(2)));
          }
          lastFilled = filled;
        });

        lastFilled = null;
        dataAdminParsed = dataAdminParsed.map((row, i, arr) => {
          if (i === 2) {
            const aumento = transformNumber((Number(parseFloat(arr[1]).toFixed(2) - parseFloat(arr[0]).toFixed(2)) / Number(parseFloat(arr[0]).toFixed(2))) * 100);
            return aumento;
          } if (i !== 0 && i % 2 == 0) {
            const aumento = transformNumber(Number((parseFloat(arr[i - 1]).toFixed(2) - parseFloat(arr[i - 3] || 0).toFixed(2)) / parseFloat(arr[i - 3] || 0)) * 100);
            return aumento;
          }
          lastFilled = row;
          return row;
        });
        dataTotal.push(dataAdminParsed);
      });
      return dataTotal;
    });

    let totalRow = totalIt(dataProducts);
    totalRow = totalRow.map((number, i, arr) => {
      if (i === 2) {
        const aumento = transformNumber((Number(parseFloat(arr[1]).toFixed(2) - parseFloat(arr[0]).toFixed(2)) / Number(parseFloat(arr[0]).toFixed(2))) * 100);
        return aumento;
      } if (i !== 0 && i % 2 == 0) {
        const aumento = transformNumber(Number((parseFloat(arr[i - 1]).toFixed(2) - parseFloat(arr[i - 3] || 0).toFixed(2)) / parseFloat(arr[i - 3] || 0)) * 100);
        return aumento;
      }
      return number;
    });

    return {
      data: dataProducts,
      total: totalRow
    };
  }

  static getDataFromTipo(data, tipo, years) {
    if (tipo === tipos.FINCA) {
      return DataParser.getDataFromFinca(data, years);
    } if (tipo === tipos.PRODUCTO) {
      return DataParser.getDataFromProducto(data, years);
    }

    data.forEach(() => {

    });
  }
}

export function totalIt(fatherArray) {
  const total = fatherArray.reduce((total, childrenArray) => {
    const arr = childrenArray;
    const lng = arr[0].length;
    const resultChild = [].concat.apply([], arr) // flatten the array
      .reduce((arr, val, ind) => { // loop over and create a new array
        const i = ind % lng; // get the column
        arr[i] = parseFloat(arr[i] || 0) + parseFloat(val); // update total for column
        arr[i] = transformNumber(arr[i]);
        return arr; // return the updated array
      }, []); // the new array used by reduce
    resultChild.forEach((val, i, array) => {
      if (!total[i]) total[i] = 0;
      total[i] += resultChild[i];
    });

    return total;
  }, []);
  return total;
}

export function sumMatrixByIndexes(matrix) {
  const columnTotales = [];
  let result = matrix.reduce((r, a) => {
    a.forEach((b, i) => {
      if (b != null) {
        if (!columnTotales[i]) {
          columnTotales[i] = 0;
        }

        if(b != 0 && b != '0') {
          columnTotales[i] += 1;
        }
      }
      r[i] = (parseFloat(r[i]) || parseFloat(0)) + parseFloat(b || 0);
      r[i] = transformNumber(r[i]);
    });
    // return r.map(totalColumna => totalColumna / a.length);
    return r; // TOTAL
  }, []);

  try {
    result = result.map((res, i) => parseFloat(res) / (columnTotales[i] || 1));
  } catch (e) { console.error('no se pudo mapear la matriz', e); }
  return result;
}
