export const SET_CICLOS_ANIO = 'SET_CICLOS_ANIO';

const initialState = [];

export function setCiclosAnio(value) {
  return {
    type: SET_CICLOS_ANIO,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CICLOS_ANIO:
      return action.value;

    default:
      return state;
  }
}
