import {
  setStatus,
  labels,
  status
} from './apiStatus';

import {
  SET_CATEGORIAS
} from './categorias';

import {
  SELECT_CATEGORIA
} from './selectedCategoria';

import {
  SET_MENUS
} from './menus';

import {
  SELECT_MENU
} from './selectedMenu';

import {
  SET_PROGRAMAS
} from './programas';

import {
  SET_NUEVAS_APLICACIONES
} from './nuevasAplicaciones';


import {
  SET_GERENTES
} from './gerentes';

import {
  SET_MATRIZ_COSTOS
} from './matrizCostos';

import {
  SELECT_PROGRAMA
} from './selectedPrograma';

import {
  SELECT_HECTAREA
} from './selectedHectareaje';

import {
  SET_CICLOS_ANIO
} from './ciclosAnio';

import {
  SET_TARIFAS
} from './tarifas';


import {
  SET_CICLOS_HECTAREAJE_ANIO
} from './ciclosHectareajePorAnio';

import { SET_FRACS } from './fracs';

import { SET_DIAS_ATRASO } from './diasAtraso';

import { SET_MOTIVOS_ATRASO_RESUMEN } from './motivosAtrasoResumen';

import {
  SET_HECTAREAJE_ANIO
} from './hectareajeAnio';

import {
  SET_LOADING
} from './loading';

import {
  SET_PROGRAMA_TECNICO
} from './programaTecnico';

import {
  SELECT_ANIO
} from './selectedAnio';

import {
  SELECT_FINCA
} from './selectedFinca';


import {
  SET_PROVEEDORES
} from './proveedores';

import {
  SET_FUMIGADORAS
} from './fumigadoras';


import {
  AplicacionesAPI,
  AplicacionProductosAPI,
  GerentesAPI,
  ReportesAPI,
  ProveedoresAPI,
  FincasAPI,
  MotivoAtrasoAPI,
  CategoriasAPI,
  MenusAPI,
  ProgramaAPI,
  TarifasAPI,
  FumigadoraAPI
} from '../../api/rest-api';

import DataParser from '../../components/reportes/resumen/DataParser';
import { anios } from '../../data';
import HectareajeAnio from '../../helpers/resumen/indicadoresHelperClasses/HectareajeAnio';


export const fetchTarifasFromProgramaId = data => async (dispatch, getState) => {
  const { id_programa, fecha_efectiva } = data;
  const tarifas = await TarifasAPI.get({ ...data });

  await dispatch({
    type: SET_TARIFAS,
    value: {
      id_programa,
      data: tarifas.data
    }
  });
};

export const fetchAll = () => async (dispatch, getState) => {
  const menus = await MenusAPI.get();
  const categorias = await CategoriasAPI.get();
  const programas = await ProgramaAPI.get();
  const gerentes = await GerentesAPI.get();
  const proveedores = await ProveedoresAPI.getOnlyFumigadora();
  const fumigadoras = await FumigadoraAPI.get();

  await dispatch({
    type: SET_LOADING,
    value: true
  });

  await dispatch({
    type: SET_CATEGORIAS,
    value: categorias.data
  });

  // inicializar selected categoria como un objeto para elegir siempre sigatoka
  let selectedCatObject = categorias.data.find(categoria => categoria.nombre.toLowerCase() == 'sigatoka');
  await dispatch({
    type: SELECT_CATEGORIA,
    value: Array.isArray(categorias.data) && categorias.data.length > 0 && selectedCatObject ? selectedCatObject : null
  });

  await dispatch({
    type: SET_GERENTES,
    value: gerentes.data
  });

  await dispatch({
    type: SET_MENUS,
    value: menus.data
  });


  // inicializar selected categoria como un objeto para elegir siempre sigatoka
  let selectedMenuObject = menus.data.find(menu => menu.nombre.toLowerCase() == 'sigatoka');
  await dispatch({
    type: SELECT_MENU,
    value: Array.isArray(menus.data) && menus.data.length > 0 && selectedMenuObject ? selectedMenuObject : null
  });

  // await dispatch({
  //   type: SELECT_MENU,
  //   value: Array.isArray(menus.data) && menus.data.length > 0 ? menus.data[0].id : null
  // });

  await dispatch({
    type: SET_PROGRAMAS,
    value: programas.data
  });

  await dispatch({
    type: SET_PROVEEDORES,
    value: proveedores
  });

  await dispatch({
    type: SET_PROVEEDORES,
    value: proveedores
  });

  await dispatch({
    type: SET_FUMIGADORAS,
    value: fumigadoras
  });

  let programa = {};
  const default_programa_name = 'Sigatoka';
  if (Array.isArray(programas.data) && programas.data.length > 0) {
    programa = programas.data.find(pr => pr.nombre === default_programa_name);
  }

  await dispatch({
    type: SELECT_PROGRAMA,
    value: programa && programa.id ? programa.id : null
  });

  const {
    selectedMenu,
    selectedAnio,
    selectedSemanaInicial,
    selectedSemanaFinal,
    selectedHectareaje,
    selectedTipoAplicacion,
    selectedPrograma,
    selectedCategoria,
    selectedGerente,
    selectedProveedor,
    selectedFumigadora,
    selectedComparativo
  } = getState();

  const data = {
    id_menu: selectedMenu.id,
    id_programa: selectedPrograma,
    id_categoria: selectedCategoria ? selectedCategoria.id : null,
    id_gerente: selectedGerente,
    tipo_aplicacion: selectedTipoAplicacion,
    tipo_hectareaje: selectedHectareaje,
    semana_inicial: selectedSemanaInicial,
    semana_final: selectedSemanaFinal,
    anio_inicial: selectedAnio,
    id_proveedor: selectedProveedor ? selectedProveedor.id : null,
    id_fumigadora: selectedFumigadora ? selectedFumigadora.id : null,
    comparativo: selectedComparativo
  };

  try {
    let ciclosHectareajeAnio = null;
    let hectareajeAnio = null;
    let ciclosPorAnio = null;
    let fracs = null;
    let diasAtraso = null;
    let motivosAtrasoResumen = null;
    await Promise.all([
      // ReportesAPI.getCiclosHectareajeAnio({ ...data }),
      ReportesAPI.getHectareajeAnio({ ...data }),
      ReportesAPI.getCiclosAnio({ ...data }),
      ReportesAPI.getFracs({ ...data }),
      ReportesAPI.getDiasAtraso({ ...data }),
      ReportesAPI.getMotivosAtrasoResumen({ ...data }),
    ]).then((responses) => {
      // ciclosHectareajeAnio = responses[0];
      hectareajeAnio = responses[0];
      ciclosPorAnio = responses[1];
      fracs = responses[2];
      diasAtraso = responses[3];
      motivosAtrasoResumen = responses[4];

    });


    let hectareajeAnioJSON = {}
    hectareajeAnio.data.forEach((item, index) => {
      hectareajeAnioJSON[item.name] = item.data
    });


    // copyCicloPorAnio.forEach((itemCicloAnio,index)=>{

    //   if(hectareajeAnioJSON.hasOwnProperty(itemCicloAnio.name)){
    //     let {total} = DataParser.getDataFromFinca(hectareajeAnioJSON[itemCicloAnio.name], anios);

    //     let a={};
    //     let count=0;
    //     for (let i=0;i<total.length;i++){
    //       if (i == 0){
    //         a[anios[count]]=total[i];
    //         count++
    //       }
    //       else if(i==1 || i%2 == 1){
    //         a[anios[count]]=total[i]
    //         count++
    //       }
    //     }
    //     // console.log(itemCicloAnio);

    //     for (let i=0;i<itemCicloAnio.data.length;i++){
    //       // console.log(a[itemCicloAnio.data[i].anio.toString()]);
    //       // console.log(itemCicloAnio.data[i].value);
    //       itemCicloAnio.data[i].value = a[itemCicloAnio.data[i].anio.toString()] / itemCicloAnio.data[i].value 
    //     }

    //   }



    // });




    let copyCicloPorAnio = JSON.parse(JSON.stringify(ciclosPorAnio.data));

    let ciclosAnioJSON = {}
    copyCicloPorAnio.forEach((item, index) => {
      ciclosAnioJSON[item.name] = [...item.data]
    });

    let resultx = []
    Object.keys(hectareajeAnioJSON).forEach((key) => {
      if (ciclosAnioJSON.hasOwnProperty(key)) {

        let { total } = DataParser.getDataFromFinca(hectareajeAnioJSON[key], anios);

        let a = {};
        let count = 0;
        for (let i = 0; i < total.length; i++) {
          if (i == 0) {
            a[anios[count]] = total[i];
            count++
          }
          else if (i == 1 || i % 2 == 1) {
            a[anios[count]] = total[i]
            count++
          }
        }

        if (ciclosAnioJSON[key].length > 0) {

          for (let i = 0; i < ciclosAnioJSON[key].length; i++) {
            ciclosAnioJSON[key][i].value = a[ciclosAnioJSON[key][i].anio.toString()] / ciclosAnioJSON[key][i].value

          }
          resultx.push({ name: key, data: ciclosAnioJSON[key] });

        }
      }


    });

    await dispatch({
      type: SET_CICLOS_ANIO,
      value: ciclosPorAnio.data
    });

    await dispatch({
      type: SET_HECTAREAJE_ANIO,
      value: hectareajeAnio.data
    });

    // await dispatch({
    //   type: SET_CICLOS_HECTAREAJE_ANIO,
    //   value: ciclosHectareajeAnio.data
    // });


    await dispatch({
      type: SET_CICLOS_HECTAREAJE_ANIO,
      value: resultx
    });


    await dispatch({
      type: SET_FRACS,
      value: fracs.data
    });

    await dispatch({
      type: SET_DIAS_ATRASO,
      value: diasAtraso.data
    });

    await dispatch({
      type: SET_MOTIVOS_ATRASO_RESUMEN,
      value: motivosAtrasoResumen.data
    });

  } catch (e) {
    console.error(e);
  }

  await dispatch({
    type: SET_LOADING,
    value: false
  });
};



export const resetBoards = async (dispatch, getState) => {
  await dispatch({
    type: SET_LOADING,
    value: true
  });

  const {
    selectedMenu,
    selectedAnio,
    selectedSemanaInicial,
    selectedSemanaFinal,
    selectedHectareaje,
    selectedTipoAplicacion,
    selectedPrograma,
    selectedCategoria,
    selectedGerente,
    selectedProveedor,
    selectedFumigadora,
    selectedComparativo,
  } = getState();

  const data = {
    id_menu: selectedMenu ? selectedMenu.id : null,
    id_programa: selectedPrograma,
    id_categoria: selectedCategoria ? selectedCategoria.id : null,
    id_gerente: selectedGerente,
    tipo_aplicacion: selectedTipoAplicacion,
    tipo_hectareaje: selectedHectareaje,
    semana_inicial: selectedSemanaInicial,
    semana_final: selectedSemanaFinal,
    anio_inicial: selectedAnio,
    id_proveedor: selectedProveedor ? selectedProveedor.id : null,
    id_fumigadora: selectedFumigadora ? selectedFumigadora.id : null,
    comparativo: selectedComparativo
  };

  try {
    let ciclosHectareajeAnio = null;
    let hectareajeAnio = null;
    let ciclosPorAnio = null;
    let fracs = null;
    let diasAtraso = null;
    let motivosAtrasoResumen = null;
    await Promise.all([
      // ReportesAPI.getCiclosHectareajeAnio({ ...data }),
      ReportesAPI.getHectareajeAnio({ ...data }),
      ReportesAPI.getCiclosAnio({ ...data }),
      ReportesAPI.getFracs({ ...data }),
      ReportesAPI.getDiasAtraso({ ...data }),
      ReportesAPI.getMotivosAtrasoResumen({ ...data }),

    ]).then((responses) => {
      // ciclosHectareajeAnio = responses[0];
      hectareajeAnio = responses[0];
      ciclosPorAnio = responses[1];
      fracs = responses[2];
      diasAtraso = responses[3];
      motivosAtrasoResumen = responses[4];
    });

    let hectareajeAnioJSON = {}
    hectareajeAnio.data.forEach((item, index) => {
      hectareajeAnioJSON[item.name] = item.data
    });


    // copyCicloPorAnio.forEach((itemCicloAnio,index)=>{

    //   if(hectareajeAnioJSON.hasOwnProperty(itemCicloAnio.name)){
    //     let {total} = DataParser.getDataFromFinca(hectareajeAnioJSON[itemCicloAnio.name], anios);

    //     let a={};
    //     let count=0;
    //     for (let i=0;i<total.length;i++){
    //       if (i == 0){
    //         a[anios[count]]=total[i];
    //         count++
    //       }
    //       else if(i==1 || i%2 == 1){
    //         a[anios[count]]=total[i]
    //         count++
    //       }
    //     }
    //     // console.log(itemCicloAnio);

    //     for (let i=0;i<itemCicloAnio.data.length;i++){
    //       // console.log(a[itemCicloAnio.data[i].anio.toString()]);
    //       // console.log(itemCicloAnio.data[i].value);
    //       itemCicloAnio.data[i].value = a[itemCicloAnio.data[i].anio.toString()] / itemCicloAnio.data[i].value 
    //     }

    //   }



    // });




    let copyCicloPorAnio = JSON.parse(JSON.stringify(ciclosPorAnio.data));

    let ciclosAnioJSON = {}
    copyCicloPorAnio.forEach((item, index) => {
      ciclosAnioJSON[item.name] = [...item.data]
    });

    let resultx = []
    Object.keys(hectareajeAnioJSON).forEach((key) => {
      if (ciclosAnioJSON.hasOwnProperty(key)) {

        let { total } = DataParser.getDataFromFinca(hectareajeAnioJSON[key], anios);

        let a = {};
        let count = 0;
        for (let i = 0; i < total.length; i++) {
          if (i == 0) {
            a[anios[count]] = total[i];
            count++
          }
          else if (i == 1 || i % 2 == 1) {
            a[anios[count]] = total[i]
            count++
          }
        }

        if (ciclosAnioJSON[key].length > 0) {

          for (let i = 0; i < ciclosAnioJSON[key].length; i++) {
            ciclosAnioJSON[key][i].value = a[ciclosAnioJSON[key][i].anio.toString()] / ciclosAnioJSON[key][i].value

          }
          resultx.push({ name: key, data: ciclosAnioJSON[key] });

        }
      }


    });




    await dispatch({
      type: SET_CICLOS_ANIO,
      value: ciclosPorAnio.data
    });

    await dispatch({
      type: SET_FRACS,
      value: fracs.data
    });

    await dispatch({
      type: SET_DIAS_ATRASO,
      value: diasAtraso.data
    });

    await dispatch({
      type: SET_HECTAREAJE_ANIO,
      value: hectareajeAnio.data
    });

    // await dispatch({
    //   type: SET_CICLOS_HECTAREAJE_ANIO,
    //   value: ciclosHectareajeAnio.data
    // });


    await dispatch({
      type: SET_CICLOS_HECTAREAJE_ANIO,
      value: resultx
    });

    await dispatch({
      type: SET_MOTIVOS_ATRASO_RESUMEN,
      value: motivosAtrasoResumen.data
    });

  } catch (e) {
    console.error(e);
  }

  await dispatch({
    type: SET_LOADING,
    value: false
  });
};

export const fetchProgramaTecnico = val => async (dispatch, getState) => {
  // if (!val) return;
  if (!val) {
    const { selectedAnio, selectedFinca, selectedCategoria } = getState();

    const data = {
      id_finca: selectedFinca,
      anio: selectedAnio,
      id_categoria: selectedCategoria,
    };

    const programaTecnicoResponse = await ReportesAPI.getProgramaTecnico({ ...data });

    await dispatch({
      type: SET_PROGRAMA_TECNICO,
      value: Array.isArray(programaTecnicoResponse.data) ? programaTecnicoResponse.data : []
    });
    return;
  }
  const { anio, finca, categoria } = val;
  if (val && anio) {
    await dispatch({
      type: SELECT_ANIO,
      value: anio
    });
  }

  if (categoria) {
    await dispatch({
      type: SELECT_CATEGORIA,
      value: categoria
    });
  }

  if (finca) {
    await dispatch({
      type: SELECT_FINCA,
      value: finca
    });
  }

  const { selectedAnio, selectedFinca, selectedCategoria } = getState();
  const data = {
    id_finca: selectedFinca,
    anio: selectedAnio,
    id_categoria: selectedCategoria,
  };

  const programaTecnicoResponse = await ReportesAPI.getProgramaTecnico({ ...data });

  await dispatch({
    type: SET_PROGRAMA_TECNICO,
    value: Array.isArray(programaTecnicoResponse.data) ? programaTecnicoResponse.data : []
  });
};


export const crearAplicacion = val => async (dispatch, getState) => {
  const {
    selectedFinca,
    selectedAnio
  } = getState();

  await dispatch(setStatus(labels.CREAR_APLICACION, status.REQ));
  const {
    aplicacion, alert
  } = val;
  if (!aplicacion) {
    console.error('Missing Parameter: Creacion de Aplicacion');
    return; // no se puede hacer nada en este caso
  }

  const aplicacionClone = { ...aplicacion };
  const productos = aplicacionClone.productos.map(pr => pr.ids);
  aplicacionClone.productos = productos;
  aplicacionClone.finca = selectedFinca;

  AplicacionesAPI.post(aplicacionClone).then(async (response) => {
    const { data: aplicacion } = response;
    const { id: idApp } = aplicacion;

    const appsProductos = aplicacionClone.productos.map((ap) => {
      ap.aplicacion = idApp;
      return ap;
    });

    try {
      const promises = appsProductos.map(appProducto => new Promise(async (resolve, reject) => {
        try {
          const response = await AplicacionProductosAPI.post(appProducto);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      }));

      Promise.all(promises).then((responses) => {
        ReportesAPI.getMatrizDeCostos({ anio: selectedAnio, id_finca: selectedFinca }).then(async (response) => {
          await dispatch({
            type: SET_MATRIZ_COSTOS,
            value: response.data
          });

          await dispatch(setStatus(labels.CREAR_APLICACION, status.SUCCESS));
          await dispatch({
            type: SET_NUEVAS_APLICACIONES,
            value: {}
          });
        }).catch((err) => {
          console.error(err);
        });
      }).catch((err) => {
        console.error(err);
      });
    } catch (e) {
      console.error(e);
    }
  }).catch(async (err) => {
    alert.error('No se pudo crear la aplicación');
    await dispatch(setStatus(labels.CREAR_APLICACION, status.ERROR));
  });
};
