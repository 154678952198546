import React from 'react';

import { transformNumber } from '../../../../helpers/numberHelper'

/*
  iconClassName: 'fa-icon...'
*/
const TCiclosCell = (props) => {
  const { valor, iconClassName } = props;

  let valorParsed = transformNumber(valor);

    if(valorParsed && !isNaN(valorParsed)) {
        valorParsed = Number(valorParsed).toFixed(2)
    }
 

  const textCenter = 'text-center';

  const icono = iconClassName ? <i className={`${iconClassName} ${textCenter}`} /> : '';
  return (
    <td className={`${iconClassName} ${textCenter}`}>
      { valorParsed || '' }
    </td>
  );
};

export default React.memo(TCiclosCell);
