import React, { Component } from 'react';

import THead from './tablaAplicaciones/THead';
import TBody from './tablaAplicaciones/TBody';

const CiclosDetalle = (props) => {
  const { data, finca, fincas } = props;
  return (
    <div className="portlet light portlet-fit portlet-datatable bordered">
      <div className="portlet-title">
        <div className="tools">
          <div className="btn-group pull-right">
            <a className="btn blue btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                    Exportar
              {' '}
              <i className="fa fa-angle-down" />
            </a>
            <ul className="dropdown-menu pull-right">
              <li>
                <a href="javascript:;" ng-click="exportExcel('datatable_ajax_estimado')">Excel</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="portlet-body">
        <div className="table-container table-scrollable">
          <table className="table table-striped table-bordered table-hover table-checkable" id="datatable_ajax_estimado">
            <THead data={data} />
            <thead />
            <TBody
              fincas={fincas}
              data={data}
              finca={finca}
            />
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CiclosDetalle);
