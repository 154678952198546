const DEFAULT_CONTAINER_NAME = 'Sigat | ';

function removeClasses(navItems) {
  for (let i = 0; i < navItems.length; i++) {
    navItems[i].classList.remove('active');
  }
}

class DocumentHelper {
    static updateTitle = (name) => {
      document.title = `${DEFAULT_CONTAINER_NAME} ${name}`;
    }

    static snatchActiveStateFromNavBar() {
      const navItems = document.querySelectorAll('li.nav-item.active');
      removeClasses(navItems);
    }

    static addClassToParent(child, clase) {
      const father = child.parentElement;
      try {
        father.classList.add(clase);
      } catch (e) {
        console.error(e);
      }
    }
}

export default DocumentHelper;

// racimos cosechados agrupados por calibre
// racimos cosechados agrupados por calibre

// Racimos cosechados agrupados por cantidad de manos
