import { resetBoards } from './fetchAll';

export const SELECT_COMPARATIVO = 'SELECT_COMPARATIVO';

const initialState = 0;

export const setComparativo = value => async (dispatch, getState) => {
    await dispatch({
      type: SELECT_COMPARATIVO,
      value: value
    });
  
    resetBoards(dispatch, getState);
  };
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_COMPARATIVO:
      return action.value;
    default:
      return state;
  }
}
