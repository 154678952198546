import React, { Component } from 'react';

import AplicacionHelper from '../../helpers/AplicacionHelper';
import { transformNumber } from '../../helpers/numberHelper';

const TMatrixFooter = (props) => {
    const {
        programa,
        tipo_hectarea,
        aplicacionesNuevas,
        isParcial 
    } = props;

  const numAplicaciones = programa.length;

  const longitudAppsNuevas = aplicacionesNuevas.reduce((max, p) => {
    if (!p.productos) return max;

    const longitud = p.productos.length;
    if (p.productos.length > max) return longitud;
    return max;
  }, 0);

  let longitudMaxima = AplicacionHelper.getLongitudMaximaProductos(programa);

  longitudMaxima = longitudAppsNuevas > longitudMaxima ? longitudAppsNuevas : longitudMaxima;
  const totalHectareas = AplicacionHelper.getTotalHectareas(programa);

  const costoTotalOperacion = AplicacionHelper.getTotalOperacion(programa);
  const costoTotal = AplicacionHelper.getTotalCostosFromAplicaciones(programa);
  const costoHectareas = AplicacionHelper.getCostoHectareas(programa, tipo_hectarea);

  const promedios = {
    costoTotalOperacion: transformNumber(costoTotalOperacion / numAplicaciones),
    costoTotal: transformNumber(costoTotal / numAplicaciones),
    costoHectareas: transformNumber(costoHectareas / numAplicaciones),
    totalHectareas: transformNumber(totalHectareas / numAplicaciones),
  };

  console.log(programa,totalHectareas,costoHectareas,tipo_hectarea);

  const size = longitudMaxima + 1;

  return (
    <tfoot>
      <tr>
        <th>PROM</th>
        { isParcial && <th /> }
        <th />
        <th />
        <th />
        <th />
        <th />
        <th>{ promedios.totalHectareas.toFixed(2) }</th>
        <th />
        <th />
        <th colSpan={size} />
        <th></th>
        <th colSpan={size} />
        <th colSpan={size} />
        <th colSpan={size} />
        <th colSpan={size} />

        <th />
        <th>{ promedios.costoTotalOperacion.toFixed(2) }</th>
        <th>{ promedios.costoTotal.toFixed(2) }</th>
        <th>{ promedios.costoHectareas.toFixed(2) }</th>
      </tr>
      <tr>
        <th>TOT</th>
        { isParcial && <th /> }
        <th />
        <th />
        <th />
        <th />
        <th />
          <th>{ totalHectareas ?
                parseFloat(totalHectareas).toFixed(2) : totalHectareas }</th>
        <th />
        <th />
        <th colSpan={size} />
        <th></th>
        <th colSpan={size} />
        <th colSpan={size} />
        <th colSpan={size} />
        <th colSpan={size} />

        <th />
          <th>{ !isNaN(costoTotalOperacion) ? parseFloat(costoTotalOperacion).toFixed(2) : costoTotalOperacion }</th>
        <th>{ !isNaN(costoTotal) ? parseFloat(costoTotal).toFixed(2) : costoTotal}</th>
        <th>{ !isNaN(costoHectareas) ? parseFloat(costoHectareas).toFixed(2) : costoHectareas }</th>
      </tr>
    </tfoot>
  );
};

export default TMatrixFooter;
