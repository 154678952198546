import axios from 'axios';

const env = process.env.NODE_ENV;
const development = 'development'; // DEFAULT ENV

const baseURL = env === development ? 'http://localhost:8000/api/v1'
  : 'http://sigat.procesosiq.com/api/v1';

const axiosClient = axios.create({ baseURL });

/* Aplicaciones */

class AplicacionesAPI {
    /* patch */
    static patch = (id, data) => axiosClient.patch(`/aplicacion/${id}/`, data)
      .then(response => response)
      .catch(error => error.response);

    /* post */
    static post = data => axiosClient.post('/aplicacion/', data)
      .then(response => response)

    /* delete */
    static delete = id => axiosClient.delete(`/aplicacion/${id}/`)
      .then(response => response)
}

class ProveedoresAPI {
    static get = data => axiosClient.get('/proveedor/')
      .then(response => response)
      .catch(error => error.response);

    static getOnlyFumigadora = data => axiosClient.get('/proveedor/')
      .then(response => {
       return response.data.filter(proveedor => proveedor.fumigadora)
      })
      .catch(error => error.response);
}

class FumigadoraAPI {
  static get = data => axiosClient.get('/fumigadora/')
    .then(response => response.data)
    .catch(error => error.response);
}

class TipoProductosAPI {
    static get = data => axiosClient.get('/tipoProducto/')
      .then(response => response)
      .catch(error => error.response);
}

class CategoriasAPI {
    static get = data => axiosClient.get('/categoria/')
      .then(response => response)
      .catch(error => error.response);
}

class ProgramaAPI {
    static get = () => axiosClient.get('/programa/')
      .then(response => response)
      .catch(error => error.response);
}

class MotivoAtrasoAPI {
    static get = () => axiosClient.get('/motivoAtraso/')
      .then(response => response)
      .catch(error => error.response);
}

class FincasAPI {
    static get = () => axiosClient.get('/finca/')
      .then(response => response)
      .catch(error => error.response);
}

class GerentesAPI {
    static get = () => axiosClient.get('/gerente/')
      .then(response => response)
      .catch(error => error.response);
}

class DosisAPI {
    static get = () => axiosClient.get('/dosis/')
      .then(response => response)
      .catch(error => error.response);
}

class ProductosAPI {
    static get = () => axiosClient.get('/producto/')
      .then(response => response)
      .catch(error => error.response);
}

class AplicacionProductosAPI {
    static get = () => axiosClient.get('/aplicacionProducto/')
      .then(response => response)
      .catch(error => error.response);

    static post = data => axiosClient.post('/aplicacionProducto/', data)
      .then(response => response)
      .catch(error => error.response);
}

class TarifasAPI {
    static get = data => axiosClient.get('/tarifas/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static post = data => axiosClient.post('/aplicacionProducto/', data)
      .then(response => response)
      .catch(error => error.response);
}

class MenusAPI {
  static get = data => axiosClient.get('/menu/', { params: data })
    .then(response => response)
    .catch(error => error.response);
}

class ReportesAPI {
    static getMatrizDeCostos = data => axiosClient.get('/aplicaciones/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getCiclosAnio = data => axiosClient
      .get('/ciclosPorAnio/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getHectareajeAnio = data => axiosClient
      .get('/hectariaAnio/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getCiclosHectareajeAnio = data => axiosClient
      .get('/ciclosHectareasPorAnio/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getFracs = data => axiosClient
      .get('/fracs/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getMotivosAtrasoResumen = data => axiosClient
      .get('/motivos/', { params: data })
      .then(response => response)
      .catch(error => error.response);
    
    static getDiasAtraso = data => axiosClient
      .get('/atraso/', { params: data })
      .then(response => response)
      .catch(error => error.response);

    static getProgramaTecnico = data => axiosClient
      .get('/programaTecnico/', { params: data })
      .then(response => response)
      .catch(error => error.response);
}


export default axiosClient;

export {
  AplicacionesAPI,
  ReportesAPI,
  ProveedoresAPI,
  ProgramaAPI,
  MotivoAtrasoAPI,
  TipoProductosAPI,
  FincasAPI,
  DosisAPI,
  ProductosAPI,
  AplicacionProductosAPI,
  CategoriasAPI,
  MenusAPI,
  GerentesAPI,
  TarifasAPI,
  FumigadoraAPI
};
