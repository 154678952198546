export default class HectareajeAnio {
  constructor(data = [], selectedAnio) {
    this.data = data;
    this.selectedAnio = Number.parseInt(selectedAnio);
  }

  // promedio
  get promedio() {
    let year = null;
    let anioData = null;
    let promedio = 0;

    const { data } = this;
    const { legend, series } = data;
    year = this.selectedAnio;
    // if (Array.isArray(legend) && legend.length > 0) {
    //   year = legend[legend.length - 1];
    // }

    if (Array.isArray(series)) {
      // console.log('HEACTAREAJEANIO');
      // console.log(series);
      anioData = series.find(serie => serie.name === year);

      if (anioData) {
        let sanizate = [...anioData.data.filter(obj => Number(obj) > 0)]
        const suma = sanizate.reduce((acum, valor) => Number(acum) + (Number(valor) || 0), 0);

        promedio = sanizate.length > 0 ? parseFloat(Number(suma) / sanizate.length).toFixed(2) : suma.toFixed(2);
        // console.log('promedio');
        // console.log(sanizate);
        // console.log(suma);
        // console.log(parseFloat(Number(suma) / sanizate.length));
      }
    }

    return promedio;
  }

  // mayor
  get mayor() {
    let finca = '';
    let year = null;
    let anioData = null;
    const maxes = [];
    let max = 0;

    const { data } = this;
    const { legend, series } = data;
    // console.log('HEACTAREAJEANIO.JS MAYOR----------------');
    // console.log(series);
    year = this.selectedAnio;
    // if (Array.isArray(legend) && legend.length > 0) {
    //   year = legend[legend.length - 1];
    // }

    if (Array.isArray(series)) {
      anioData = series.find(serie => serie.name === year);

      if (anioData) {
        const m = Math.max(...anioData.data.filter(obj => Number(obj) > 0));
        const maxes = anioData.data.reduce((p, c, i, a) => (c == m ? p.concat(i) : p), []);
        // console.log(m);
        // console.log(maxes);


        try {
          if (maxes.length > 0) max = anioData.data[maxes[0]];
          finca = data.xAxisData[maxes[0]]; // fallback
        } catch (e) {
          console.error(e);
        }
      }
    }

    return {
      valor: Number(max).toFixed(2),
      finca: finca || ''
    };
  }

  // menor
  get menor() {
    let finca = '';
    let year = null;
    let anioData = null;
    const mins = [];
    let min = 0;

    const { data } = this;
    const { legend, series } = data;
    year = this.selectedAnio;
    // if (Array.isArray(legend) && legend.length > 0) {
    //   year = legend[legend.length - 1];
    // }

    if (Array.isArray(series)) {
      anioData = series.find(serie => serie.name === year);

      if (anioData) {
        const m = Math.min(...anioData.data.filter(obj => Number(obj) > 0));
        // console.log('menor...');
        // console.log(m);
        const mins = anioData.data.reduce((p, c, i, a) => (c == m ? p.concat(i) : p), []);
        if (mins.length > 0) min = anioData.data[mins[0]];
        // console.log(mins);
        // console.log(min);
        try {
          finca = data.xAxisData[mins[0]]; // fallback
        } catch (e) {
          console.error(e);
        }
      }
    }

    return {
      valor: Number(min).toFixed(2),
      finca: finca || ''
    };
  }
}
