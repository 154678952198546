import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ACEITE, FUNGICIDA } from '../../../data';

const uuidv4 = require('uuid/v4');


export default class TBodyRow extends Component {
  constructor(props) {
    super(props);

    const { rowData } = props;
    this.state = {
      rowData,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataRow !== prevState.dataRow) {
      return ({
        dataRow: nextProps.dataRow,
      });
    }
    return prevState;
  }

  render() {
    const textCenter = 'text-center';
    const className = textCenter;
    const { rowData } = this.state;
    const { aceitesMaxSize, maxArrSize, finca } = this.props;
    const data = rowData.data ? rowData.data : [];

    const fungicidas = data.filter(producto => producto.tipo === FUNGICIDA);
    const aceites = data.filter(producto => producto.tipo === ACEITE);
    let fungicidasJSX = (<React.Fragment />);
    let aceitesJSX = (<React.Fragment />);
    for (let i = 0; i < maxArrSize.length; i++) {
      if (fungicidas[i]) {
        const fung = fungicidas[i];
        fungicidasJSX = [fungicidasJSX, (
            <td
              colSpan="1"
              className={className}
            >
              { fung.nombre }
            </td>
        )];
      } else {
        fungicidasJSX = [fungicidasJSX, (
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
        )];
      }
    }

    for (let i = 0; i < aceitesMaxSize.length; i++) {
      if (aceites[i]) {
        const fung = aceites[i];
        aceitesJSX = [aceitesJSX, (
            <td
              colSpan="1"
              className={className}
            >
              { fung.nombre }
            </td>
        )];
      } else {
        aceitesJSX = [aceitesJSX, (
            <td
              colSpan="1"
              className={className}
            >
              { '-' }
            </td>
        )];
      }
    }

      const dp = Number(rowData.frecuencia_programada || 0) - Number(rowData.frecuencia_real || 0)
      let classdp = ''
      if(dp === 0) classdp = `${textCenter} bg-green-jungle bg-font-green-jungle`;
      else if(dp > 0) classdp = `${textCenter} bg-yellow-gold bg-font-yellow-gold`;
      else classdp = `${textCenter} bg-red-thunderbird bg-font-red-thunderbird`;

    return (
      <tr key={`${rowData.ciclo}`}>
        <td
          colSpan="1"
          className={className}
        >
          { rowData.ciclo }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { finca.nombre || '-' }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { rowData.fecha_progrmada || '-' }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { rowData.fecha_real || '-' }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { rowData.frecuencia_programada || '-' }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { rowData.frecuencia_real || '-' }
        </td>
        { fungicidasJSX }
        { aceitesJSX }

        <td
          colSpan="1"
          className={classdp}
        >
          { dp }
        </td>

        <td
          colSpan="1"
          className={classdp}
        >
          { dp }
        </td>

        <td
          colSpan="1"
          className={className}
        >
          { rowData.motivo_atraso || '-' }
        </td>

      </tr>
    );
  }
}
