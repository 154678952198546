import DataParser from '../../components/reportes/resumen/DataParser';
import CiclosDataParser from '../../components/reportes/resumen/ciclos/DataParser';

const _ = require('underscore');
const lodash = require('lodash');


const getOption = (
  title,
  legend,
  xAxisData,
  series
) => {
  const option = {
    title: {
      text: title,
      left: 'center',
      top: '3%'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    legend: {
      data: legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    dataZoom: {
      y: '90%',
      show: true,
      position: 'bottom',

      type: 'slider',
      filterMode: 'filter',

    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      containLabel: true
    },
    series
  };
  return option;
};

const parseResumenHectareaje = (payload, anios) => {
  const data = payload;
  let series = [];
  let legend = [];
  let xAxisData = [];

  if (Array.isArray(data)) {
    const fincasAcum = data.map(finca => DataParser.getDataFromFinca(finca.data, anios).total);

    const transpose = _.zip(...fincasAcum);
    const size = transpose.length;
    const indexesToRemove = [];

    for (let i = 0; i < size; i++) {
      if (i >= 1 && i % 2 == 0) {
        indexesToRemove.push(i);
      }
    }

    lodash.pullAt(transpose, indexesToRemove); // Removemos los totales

    series = transpose.map((yearRow, i) => ({
      name: anios[i],
      type: 'bar',
      data: yearRow
    }), []);

    legend = anios;
    xAxisData = data.map(finca => finca.name);
  }

  return { series, legend, xAxisData };
};

const parseResumenCiclos = (payload, anios) => {
  const data = payload;
  let series = [];
  let legend = [];
  let xAxisData = [];
  if (Array.isArray(data)) {
    // console.log('parseadorResumen ciclos+++++++'); // PAYLOAD ES LA DATA DEL API TAL CUAL , RETORNA LA INFO NECESARIA PARA ECHART
    // console.log(data);
    const fincasAcum = data.map(finca => CiclosDataParser.getDataFromFinca(finca.data, anios).total);
    const transpose = _.zip(...fincasAcum);
    // console.log(fincasAcum);
    // console.log(transpose);
    series = transpose.map((yearRow, i) => ({
      name: anios[i],
      type: 'bar',
      data: yearRow
    }), []);

    // console.log(series);


    legend = anios;
    xAxisData = data.map(finca => finca.name);
  }

  return { series, legend, xAxisData };
};


export { getOption, parseResumenCiclos, parseResumenHectareaje };
