import Ciclo from './indicadoresHelperClasses/Ciclo';
import HectareajeCiclo from './indicadoresHelperClasses/HectareajeCiclo';
import HectareajeAnio from './indicadoresHelperClasses/HectareajeAnio';

const parseIndicadoresData = (
  parsedHectareajeAnio,
  parsedCiclosAnio,
  parsedCiclosHectareaje,
  selectedAnio
) => {
  const rows = [];

  /* CiclosAnio */

  let row = [];

  const ciclos = new Ciclo(parsedCiclosAnio, parsedCiclosHectareaje, selectedAnio);

  row.push({
    nombre: 'CICLOS PROMEDIO',
    valor: ciclos.promedio,
    color: 'blue-steel'
  });

  const { finca: fincaCiclosMax, valor: mayorCiclos } = ciclos.mayor;
  row.push({
    nombre: `${fincaCiclosMax}. MAYOR CICLOS`,
    valor: mayorCiclos,
    color: 'red-thunderbird'
  });

  const { finca: fincaCiclosMin, valor: menorCiclos } = ciclos.menor;
  row.push({
    nombre: `${fincaCiclosMin}. MENOR CICLOS`,
    valor: menorCiclos,
    color: 'green-jungle'
  });

  rows.push(row);

  /* $/Ha Ciclos */

  const hectareajeCiclo = new HectareajeCiclo(parsedCiclosHectareaje, selectedAnio);
  row = [];

  row.push({
    nombre: '$/Ha Ciclos PROMEDIO',
    valor: hectareajeCiclo.promedio,
    color: 'blue-steel'
  });

  const { finca: fincaHectareajeCicloMax, valor: maxHectareajeCiclo } = hectareajeCiclo.mayor;

  row.push({
    nombre: `${fincaHectareajeCicloMax}. MAYOR $/Ha Ciclos`,
    valor: maxHectareajeCiclo,
    color: 'red-thunderbird'
  });

  const { finca: fincaHectareajeCicloMin, valor: menorHectareajeCiclo } = hectareajeCiclo.menor;

  row.push({
    nombre: `${fincaHectareajeCicloMin}. MENOR $/Ha Ciclos`,
    valor: menorHectareajeCiclo,
    color: 'green-jungle'
  });

  rows.push(row);

  // $/Ha año

  const hectareajeAnio = new HectareajeAnio(parsedHectareajeAnio, selectedAnio);

  row = [];

  row.push({
    nombre: '$/Ha año PROMEDIO',
    valor: hectareajeAnio.promedio,
    color: 'blue-steel'
  });

  const { finca: fincaHectareajeAnioMax, valor: maxHectareajeAnio } = hectareajeAnio.mayor;

  row.push({
    nombre: `${fincaHectareajeAnioMax}. MAYOR $/Ha año`,
    valor: maxHectareajeAnio,
    color: 'red-thunderbird'
  });

  const { finca: fincaHectareajeAnioMin, valor: minHectareajeAnio } = hectareajeAnio.menor;

  row.push({
    nombre: `${fincaHectareajeAnioMin}. MENOR $/Ha año`,
    valor: minHectareajeAnio,
    color: 'green-jungle'
  });

  rows.push(row);

  return rows;
};

export { parseIndicadoresData };
