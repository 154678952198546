import { transformNumber } from './numberHelper';

export default class AplicacionHelper {
  static calcularTotal(aplicacion) {
    try {
      const { productos } = aplicacion;
        const totalCostoOperacion = Number(aplicacion.hectareas || aplicacion.ha) * aplicacion.costo_operacion;

      const totalProductos = productos.reduce((acum, producto) => {
          const cantidad = Number((producto.dosis_cantidad || producto.dosis)) * Number(aplicacion.hectareas || aplicacion.ha);
          const total = Number(cantidad * Number(producto.tarifa_precio || producto.precio));
        acum += total;
        return acum;
      }, 0);

      return transformNumber(totalProductos + totalCostoOperacion);
    } catch (e) {
      console.error(e);
    }
  }

  static getTotalHectareas(aplicaciones) {
    const hectareas = aplicaciones.map(ap => ap.ha);
    return hectareas.reduce((acum, ha) => parseFloat(acum) + parseFloat(ha), 0);
  }

  static getLongitudMaximaProductos(programa) {
    const longitudMaxima = programa.reduce((max, app) => {
      const longitud = app.productos.length;
      if (app.productos.length > max) return longitud;
      return transformNumber(max);
    }, 0);
    return transformNumber(longitudMaxima);
  }

  static getTotalOperacion(aplicaciones) {
    return aplicaciones.reduce((acum, ap) => transformNumber(acum + ap.ha * ap.costo_operacion), 0);
  }

  static getTotalCostosFromAplicaciones(aplicaciones) {
    return aplicaciones.reduce((acum, ap) => transformNumber(acum + AplicacionHelper.calcularTotal(ap)), 0);
  }

  static getCostoHectareas(aplicaciones, tipoHectarea) {
    return aplicaciones.reduce((acum, ap) => {
      const costoTotal = AplicacionHelper.calcularTotal(ap);
      // const costoHectareas = tipoHectarea ? (costoTotal / tipoHectarea.divisor).toFixed(2) : 0;
      const costoHectareas = tipoHectarea ? (costoTotal / ap.ha).toFixed(2) : 0;
      return transformNumber(acum + parseFloat(costoHectareas));
    }, 0);
  }
}
