export const SET_MATRIZ_COSTOS = 'SET_MATRIZ_COSTOS';

const initialState = {};

export function setMatrizCostos(value) {
  return {
    type: SET_MATRIZ_COSTOS,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MATRIZ_COSTOS:
      return action.value;

    default:
      return state;
  }
}
