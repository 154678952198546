import React from 'react';

import { VanishOut, Flash } from 'animate-components';
import DateHelper from '../helpers/dateHelper';

import './DashboardDataLoading';

const logoStyling = {
  height: '62px',
  width: '82px',
};

let interval = null;

class DashboardDataLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
  }

  async componentDidMount() {
    const {
      fetchProveedores,
      setProveedores,

      fetchFincas,
      setFincas,

      fetchMotivosAtraso,
      setMotivosAtraso,

      fetchTiposProducto,
      setTiposProducto,

      fetchProductos,
      setProductos,

      fetchDosis,
      setDosis,

      fetchProgramas,
      setProgramas,

      fetchCategorias,
      setCategorias,

      selectedFinca,

      selectAnio,
        loadData,

        loadMatriz,
        updateLoadMatrizState,
    } = this.props;


    const prProgramas = new Promise((resolve, reject) => {
      fetchProgramas().then((response) => {
        const { data } = response;
        setProgramas(data);
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });

    const prCategorias = new Promise((resolve, reject) => {
      fetchCategorias().then((response) => {
        const { data } = response;
        setCategorias(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });

    const prDosis = new Promise((resolve, reject) => {
      fetchDosis().then((response) => {
        const { data } = response;
        setDosis(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });

    const prProductos = new Promise((resolve, reject) => {
      fetchProductos().then((response) => {
        const { data } = response;
        setProductos(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });


    const prTiposProducto = new Promise((resolve, reject) => {
      fetchTiposProducto().then((response) => {
        const { data } = response;
        setTiposProducto(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });


    const prFincas = new Promise((resolve, reject) => {
      fetchFincas().then((response) => {
        const { data } = response;
        setFincas(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });

    const prProveedores = new Promise((resolve, reject) => {
      fetchProveedores().then((response) => {
        const { data } = response;
        setProveedores(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });

    const prMotivos = new Promise((resolve, reject) => {
      fetchMotivosAtraso().then((response) => {
        const { data } = response;
        setMotivosAtraso(data);
        resolve(data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
    });

    await Promise.all([
      prProgramas,
      prCategorias,
      prDosis,
      prProductos,
      prTiposProducto,
      prFincas,
      prProveedores,
      prMotivos
    ]).then((responses) => {


      if(loadMatriz) {
          updateLoadMatrizState(false);
      }
    }).catch((err) => { console.error(err); });

    if (loadData) {
      await loadData(); /* fetch default data from view */
    }

    interval = setInterval(() => {
      this.setState({ isVisible: !this.state.isVisible });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  render() {
    const { isVisible } = this.state;
    return (
      <div className="page-content-wrapper">
        <link type="text/css" href="http://cdn.procesos-iq.com//global/plugins/datatables/plugins/bootstrap/datatables.bootstrap.css" rel="stylesheet" />
        <div id="costos" className="page-content">
          <div className="box-loader">
            <Flash
              duration="2s"
              iterations="infinite"
            >
              <img className="logo-lg" src="/imagenes/logo.png" style={logoStyling} />
            </Flash>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardDataLoading;
