import React from 'react';

/*
  tipo: Porcentaje, Normal.
  iconClassName: 'fa-icon...'
*/
const TCell = (props) => {
  const { valor, iconClassName, tipo } = props;

  const textCenter = 'text-center';
  let valorParsed = valor

    if(valor & !isNaN(valor)) {
        valorParsed = Number(valor).toFixed(2)
    }

  const icono = iconClassName ? <i className={`${iconClassName} ${textCenter}`} /> : '';
  return (
    <td className="text-center">
      <small>{ tipo === tcellTipos.NORMAL ? valorParsed : `${valorParsed}%`}</small>
          &nbsp;
      {icono}
    </td>
  );
};

export const tcellTipos = {
  NORMAL: 'normal',
  PORCENTAJE: 'porcentaje',
};

export default React.memo(TCell);
