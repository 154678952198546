/* Compartimos el estado global de las dosis y los productos */
import { connect } from 'react-redux';
import { fetchTarifasFromProgramaId } from '../redux/modules/fetchAll';
import ModalCrearProducto from '../components/matriz/ModalCrearProducto';

const mapStateToProps = state => ({
  proveedores: state.proveedores,
  fincas: state.fincas,
  motivosAtraso: state.motivosAtraso,
  programas: state.fincas,
  matrizCostos: state.matrizCostos,
  tipoProductos: state.tipoProductos,
  productos: state.productos,
  tarifas: state.tarifas,
  dosis: state.dosis,
  loading: state.loading,
});

const mapDispatchToProps = dispatch => ({
  loadTarifasFromProgramaId: id_programa => dispatch(fetchTarifasFromProgramaId(id_programa)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCrearProducto);
