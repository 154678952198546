import { SELECT_PROGRAMA } from './selectedPrograma';

export const SET_PROGRAMAS = 'SET_PROGRAMAS';

const initialState = [];

export const setProgramas = value => async (dispatch, getState) => {
  await dispatch({
    type: SET_PROGRAMAS,
    value
  });

  dispatch(
    {
      type: SELECT_PROGRAMA,
      value: value && Array.isArray(value) ? value[0].id : null
    }
  );
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROGRAMAS:
      return action.value;

    default:
      return state;
  }
}
