import React, { Component } from 'react';

import ReactEcharts from 'echarts-for-react';

const fracObject = {
    Amina: null,
    Anilinopirimidinas: null,
    Triazol: null,
    Guanidinas: null,
    Carboxamidas: null,
    Estrobilurinas: null,
}

export default class DiasAtraso extends Component {

    getClassColor(value){    
        if(value == 0){
            return 'bg-green-haze bg-font-green-haze'
        }else if(value > 0){
            return 'bg-yellow-gold bg-font-yellow-gold'
        }else if(value <0){
            return 'bg-red-thunderbird bg-font-red-thunderbird'
        }
    }

    
    render(){
        const {data} = this.props;
        
        let maxCiclo = 0;
        
        for(let i=0; i<data.length; i++){
            let ciclos = data[i].data;
            let keys = Object.keys(ciclos);
            let last = keys[keys.length-1];

            if(last > maxCiclo){
                maxCiclo = last;
            }
        }


        let diasCols = [...Array(parseInt(maxCiclo)).keys()];
        const diasColsJSX = diasCols.map( col => (
            <>
            <th className="center-th">{col+1}</th>
            </>
        ));

        const diasJSX = data.map(item =>(
            <>
            <tr ng-repeat="valor in table_frac">
                <td className="center-th">
                    {item.name}
                </td>
                {diasCols.map(key =>(
                    <>
                        <td className={`center-th ${this.getClassColor(item.data[(key+1).toString()])}`}>
                            {item.data[(key+1).toString()]}
                            
                        </td>
                    </>
                ))}
            </tr>
            </>
        ));
        
        return (
            <>
                  <div className="portlet light bordered">
                    <div className="portlet-title">
                    <div className="caption">
                        <i className="icon-settings font-dark" />
                        <span className="caption-subject font-dark sbold uppercase">DIAS DE ATRASO</span>
                    </div>
                    <div className="actions">
                        <div className="btn-group btn-group-devided" data-toggle="buttons">
                        {/*<a class="btn newFinca sbold uppercase btn-outline blue-ebonyclay">Nueva Finca</a>*/}
                        </div>
                    </div>
                    </div>
                    <div className="portlet-body">
                    <div className="col-md-3 col-sm-12 pull-right">
                        <ul style={{listStyle: 'none'}}>
                        <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-red-thunderbird bg-font-red-thunderbird" /> Días de atraso</li>
                        <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-green-haze bg-font-green-haze" /> Se aplico el día programado</li>
                        <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-yellow-gold bg-font-yellow-gold" /> Días de adelanto</li>
                        </ul>
                    </div>
                    <div className="table-scrollable">                                
                        <table className="table table-striped table-bordered table-hover" id="datatable_ciclos_aplicados">
                        <thead>
                            <tr>
                            <th className="center-th">FINCAS</th>
                            {diasColsJSX}
                            </tr>
                        </thead>
                        <tbody>
                            {diasJSX}
                        </tbody>
                        <tfoot>
                            <tr>
                            <th className="center-th" />
                            <th className="center-th" ng-repeat="data in years" />
                            </tr>
                        </tfoot>
                        </table>
                    </div>
                    </div>
                </div>
            </>
        );
    }
}

