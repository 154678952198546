import { SELECT_CATEGORIA } from './selectedCategoria';

export const SET_CATEGORIAS = 'SET_CATEGORIAS';

const initialState = [];

export const setCategorias = (value) => async (dispatch, getState) => {
    await dispatch({
    type: SET_CATEGORIAS,
    value
    });

  if (Array.isArray(value) && value.length > 0) {
    return dispatch({
      type: SELECT_CATEGORIA,
      value: value[0].id
    });
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIAS:
      return action.value;

    default:
      return state;
  }
}
