import React, { Component } from 'react';
import { withRouter } from 'react-router';

import DocumentHelper from '../helpers/documentHelper';

const env = process.env.NODE_ENV;
const development = 'development'; // DEFAULT ENV

const baseURL = env === development ? 'http://localhost:8000/admin'
  : 'http://sigat.procesosiq.com/admin';

class Sidebar extends Component {
    componentDidMount = () => {

    }

    onSelectNavItem = (evt) => {
      evt.preventDefault();
      const { history } = this.props;
      const { currentTarget: element } = evt;
      const hrefData = element.getAttribute('href');
      DocumentHelper.snatchActiveStateFromNavBar();
      DocumentHelper.addClassToParent(evt.target, 'active');

      history.push({
        pathname: `${hrefData}`,
      });
    }

    render() {
      return (
        <div className="page-sidebar-wrapper">
          <div className="page-sidebar navbar-collapse collapse">
            <ul className="page-sidebar-menu" data-keep-expanded="false" data-auto-scroll="true" data-slide-speed="200">
              <li className="nav-item start active">
                <a href="/sigat/dashboard" className="nav-link nav-toggle" onClick={this.onSelectNavItem}>
                  <i className="icon-home" />
                  <span className="title">Inicio</span>
                  <span className="selected" />
                </a>
              </li>
              <li className="nav-item  open">
                <a href="javascript:;" className="nav-link nav-toggle">
                  <i className="fa fa-plane" />
                  <span className="title">Programas</span>
                  <span className="arrow open" />
                </a>
                <ul className="sub-menu" style={{ display: 'block;' }}>
                  <li className="nav-item ">
                    <a href="/sigat/programaTecnico" className="nav-link" onClick={this.onSelectNavItem}>
                      <i className="fa fa-info" />
                      <span className="title">Técnico</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="heading">
                <h3 className="uppercase">Menu</h3>
              </li>
              <li className="nav-item">
                <a href="javascript:;" className="nav-link nav-toggle">
                  <i className="icon-puzzle" />
                  <span className="title">Reportes</span>
                  <span className="arrow" />
                </a>
                <ul className="sub-menu">
                  <li className="nav-item ">
                    <a href="/sigat/costos" className="nav-link" onClick={this.onSelectNavItem}>
                      <i className="glyphicon glyphicon-th" />
                      <span className="title">Matriz Excel</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a href="javascript:;" className="nav-link nav-toggle">
                  <i className="icon-settings" />
                  <span className="title">Configuraci&oacute;n</span>
                  <span className="arrow" />
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/aplicacion/`} className="nav-link ">
                      <span className="title">Aplicaciones</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/categoria/`} className="nav-link ">
                      <span className="title">Categor&iacute;as</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/finca/`} className="nav-link ">
                      <span className="title">Fincas</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/menu/`} className="nav-link ">
                      <span className="title">Menu</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/producto/`} className="nav-link ">
                      <span className="title">Productos</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/programa/`} className="nav-link ">
                      <span className="title">Programa</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a href={`${baseURL}/sigat/proveedor/`} className="nav-link ">
                      <span className="title">Proveedores</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      );
    }
}

export default React.memo(withRouter(Sidebar));
