export const SET_NUEVAS_APLICACIONES = 'SET_NUEVAS_APLICACIONES';

const initialState = {};

export function setNuevasAplicaciones(value) {
  return {
    type: SET_NUEVAS_APLICACIONES,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NUEVAS_APLICACIONES:
      return action.value;

    default:
      return state;
  }
}
