export const SET_PROVEEDORES = 'SET_PROVEEDORES';

const initialState = [];

export function setProveedores(value) {
  return {
    type: SET_PROVEEDORES,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROVEEDORES:
      return action.value;

    default:
      return state;
  }
}
