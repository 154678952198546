export const SET_CICLOS_HECTAREAJE_ANIO = 'SET_CICLOS_HECTAREAJE_ANIO';

const initialState = [];

export function setCiclosHectareajeAnio(value) {
  return {
    type: SET_CICLOS_HECTAREAJE_ANIO,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CICLOS_HECTAREAJE_ANIO:
      return action.value;

    default:
      return state;
  }
}
