import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import apiStatus from './apiStatus';
import proveedores from './proveedores';
import tipoProductos from './tipoProductos';
import menus from './menus';
import programas from './programas';
import productos from './productos';
import motivosAtrasoResumen from './motivosAtrasoResumen';
import motivosAtraso from './motivosAtraso';
import fincas from './fincas';
import matrizCostos from './matrizCostos';
import ciclosAnio from './ciclosAnio';
import fracs from './fracs';
import diasAtraso from './diasAtraso';
import hectareajeAnio from './hectareajeAnio';
import ciclosHectareajePorAnio from './ciclosHectareajePorAnio';
import dosis from './dosis';
import selectedFinca from './selectedFinca';
import selectedAnio from './selectedAnio';
import gerentes from './gerentes';
import fumigadoras from './fumigadoras';
import programaTecnico from './programaTecnico';

import selectedSemanaInicial from './selectedSemanaInicial';
import selectedSemanaFinal from './selectedSemanaFinal';
import selectedHectareaje from './selectedHectareaje';
import selectedTipoAplicacion from './selectedTipoAplicacion';
import selectedCategoria from './selectedCategoria';
import selectedMenu from './selectedMenu';
import selectedComparativo from './selectedComparativo';
import selectedPrograma from './selectedPrograma';
import selectedGerente from './selectedGerente';
import selectedFumigadora from './selectedFumigadora';
import selectedProveedor from './selectedProveedor';
import categorias from './categorias';
import loading from './loading';
import nuevasAplicaciones from './nuevasAplicaciones';
import tarifas from './tarifas';

export default history => combineReducers({
  router: connectRouter(history),
  user,
  proveedores,
  tipoProductos,
  productos,
  menus,
  programas,
  motivosAtraso,
  matrizCostos,
  ciclosAnio,
  fracs,
  diasAtraso,
  motivosAtrasoResumen,
  hectareajeAnio,
  ciclosHectareajePorAnio,
  dosis,
  fincas,
  gerentes,
  fumigadoras,
  selectedFinca,
  /* RESUMEN */
  selectedAnio,
  selectedSemanaInicial,
  selectedSemanaFinal,
  selectedGerente,
  selectedFumigadora,
  selectedHectareaje,
  selectedTipoAplicacion,
  selectedCategoria,
  selectedMenu,
  selectedComparativo,
  selectedPrograma,
  selectedProveedor,
  categorias,
  loading,
  nuevasAplicaciones,
  tarifas,
  /* Programa Tecnico */
  programaTecnico,
  apiStatus,
});
