import React from 'react';

const TMatrizHead = (props) => {
  const {
    programa,
    aplicacionesNuevas,
    isParcial
  } = props;
  let size = 9;

  const longitudAppsNuevas = aplicacionesNuevas.reduce((max, p) => {
    if (!p.productos) return max;

    const longitud = p.productos.length;
    if (p.productos.length > max) return longitud;
    return max;
  }, 0);

  let longitudMaxima = programa.reduce((max, p) => {
    const longitud = p.productos.length;
    if (p.productos.length > max) return longitud;
    return max;
  }, 0);

  longitudMaxima = longitudAppsNuevas > longitudMaxima ? longitudAppsNuevas : longitudMaxima;

  size = longitudMaxima + 1;

  const filledSize = Array.from(Array(longitudMaxima), (x, index) => index + 1);

  const ths = Array.from(Array(5)).map(() => filledSize.map((i, index) => {
    if (index === longitudMaxima - 1) {
      return (
        <>
          <th style={{ border: '2px solid black' }}>{`P${i}`}</th>
          <th style={{ border: '2px solid black' }}>{`P${i + 1}`}</th>
        </>
      );
    }
    return (
      <th style={{ border: '2px solid black' }}>{`P${i}`}</th>
    );
  }));


  return (
    <thead className="">
      <tr>
        <th />
        <th rowSpan={2}>Ciclo</th>
        { isParcial && <th rowSpan={2}>Ciclo 2</th>}
        <th rowSpan={2}>Programa</th>
        <th rowSpan={2}>Semana</th>
        <th colSpan={2}>Fecha</th>
        <th rowSpan={2}>HAS</th>
        <th colSpan={2}>Atraso</th>
        <th colSpan={size} style={{ border: '2px solid black' }}>Productos</th>
        <th rowSpan={size > 1 ? 2 : 1} style={{ border: '2px solid black',verticalAlign: 'middle' }}>Galonaje</th>
        <th colSpan={size} style={{ border: '2px solid black' }}>Dosis</th>
        <th colSpan={size} style={{ border: '2px solid black' }}>Cantidad</th>
        <th colSpan={size} style={{ border: '2px solid black' }}>Precios Productos</th>
        <th colSpan={size} style={{ border: '2px solid black' }}>Costo Total</th>
        <th rowSpan={2}>Costo Operacion</th>
        <th rowSpan={2}>Total Costo Operacion</th>
        <th rowSpan={2}>Costo Total</th>
        <th rowSpan={2}>Costo / HAS</th>
      </tr>

      <tr>
        <th />
        <th style={{ minWidth: '80px !important' }}>Prog.</th>
        <th style={{ minWidth: '80px !important' }}>Real</th>

        <th>Dias</th>
        <th>Motivo</th>
        { ths }
      </tr>
    </thead>
  );
};

export default TMatrizHead;
