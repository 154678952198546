import DateHelper from '../../helpers/dateHelper';
import {
  ReportesAPI
} from '../../api/rest-api';

import { resetBoards } from './fetchAll';

import { SET_MATRIZ_COSTOS } from './matrizCostos';

export const SELECT_ANIO = 'SELECT_ANIO';
const initialState = DateHelper.getCurrentYear();

export const setAnio = (value) => (dispatch, getState) => {
  const { selectedFinca,selectedHectareaje } = getState();
  ReportesAPI.getMatrizDeCostos(
    {
      anio: value,
      id_finca: selectedFinca,
      tipo_hectareaje: selectedHectareaje
    }
  ).then((response) => {
    const { data } = response;
    dispatch({
      type: SET_MATRIZ_COSTOS,
      value: data
    });
  }).catch((err) => {
    console.error(err);
  });

  return dispatch({
    type: SELECT_ANIO,
    value
  });
};

export const setAnioResumen = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_ANIO,
    value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ANIO:
      return action.value;

    default:
      return state;
  }
}
