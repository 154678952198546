export const SET_FRACS = 'SET_FRACS';

const initialState = [];

// export function setCiclosAnio(value) {
//   return {
//     type: SET_CICLOS_ANIO,
//     value
//   };
// }

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FRACS:
      return action.value;

    default:
      return state;
  }
}
