import React from 'react';
import PropTypes from 'prop-types';

import { ACEITE, FUNGICIDA } from '../../../data';

import TBodyRow from './TBodyRow';

const uuidv4 = require('uuid/v4');

const TBody = (props) => {
  const { data, finca, fincas } = props;
    const selectedFinca = fincas.find((f) => {
        return finca && f.id.toString() === finca.toString();
    });

  const fungicidasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === FUNGICIDA);
    } return [];
  });

  const aceitesMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === ACEITE);
    } return [];
  });

  const columnsMaxSize = fungicidasMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const aceitesMaxSize = aceitesMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const fungicidasArrCount = new Array(columnsMaxSize);
  const aceitesArrCount = new Array(aceitesMaxSize);

  const rows = data.map(item => (
    <TBodyRow
      key={uuidv4()}
      rowData={item}
      finca={selectedFinca}
      maxArrSize={fungicidasArrCount}
      aceitesMaxSize={aceitesArrCount}
    />
  ));

  return (
    <tbody>
      { rows }
    </tbody>
  );
};

export default TBody;
