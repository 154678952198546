export const SET_MOTIVOS_ATRASO_RESUMEN = 'SET_MOTIVOS_ATRASO_RESUMEN';

const initialState = {};

// export function setCiclosAnio(value) {
//   return {
//     type: SET_CICLOS_ANIO,
//     value
//   };
// }

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MOTIVOS_ATRASO_RESUMEN:
      return action.value;

    default:
      return state;
  }
}
