import { resetBoards } from './fetchAll';

export const SELECT_GERENTE = 'SELECT_GERENTE';

const initialState = '';

export const setGerenteId = value => async (dispatch, getState) => {

  await dispatch({
    type: SELECT_GERENTE,
    value: value || null
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_GERENTE:
      return action.value;
    default:
      return state;
  }
}
