import { connect } from 'react-redux';
import ProgramaTecnico from '../components/ProgramaTecnico';

import { setAnio } from '../redux/modules/selectedAnio';
import { fetchProgramaTecnico } from '../redux/modules/fetchAll';
import { setProveedores } from '../redux/modules/proveedores';
import { setDosis } from '../redux/modules/dosis';
import { selectFincaNew } from '../redux/modules/fincas';
import { setFincasNew } from '../redux/modules/fincas';
import { setMotivosAtraso } from '../redux/modules/motivosAtraso';
import { setTiposProducto } from '../redux/modules/tipoProductos';
import { setProductos } from '../redux/modules/productos';
import { setProgramas } from '../redux/modules/programas';
import { setCategorias } from '../redux/modules/categorias';

import {
  AplicacionesAPI,
  DosisAPI,
  ProductosAPI,
  TipoProductosAPI,
  ReportesAPI,
  ProgramaAPI,
  ProveedoresAPI,
  FincasAPI,
  MotivoAtrasoAPI,
  CategoriasAPI,
} from '../api/rest-api';

const mapStateToProps = (state) => (
  {
    proveedores: state.proveedores,
    fincas: state.fincas,
    motivosAtraso: state.motivosAtraso,
    programas: state.programas,
    tipoProductos: state.tipoProductos,
    productos: state.productos,
    fincaSelected: state.fincaSelected,
    selectedAnio: state.selectedAnio,
    selectedFinca: state.selectedFinca,
    programaTecnico: state.programaTecnico,
    categorias: state.categorias,
    selectedCategoria: state.selectedCategoria,
  });

const mapDispatchToProps = (dispatch) => (
  {
    fetchCategorias: () => CategoriasAPI.get(),
    setCategorias: arr => dispatch(setCategorias(arr)),
    fetchProgramaTecnico: val => dispatch(fetchProgramaTecnico(val)),
    fetchProveedores: () => ProveedoresAPI.get(),
    setProveedores: arr => dispatch(setProveedores(arr)),
    selectFinca: evt => dispatch(selectFincaNew(evt)),
    fetchMotivosAtraso: () => MotivoAtrasoAPI.get(),
    setMotivosAtraso: arr => dispatch(setMotivosAtraso(arr)),
    fetchTiposProducto: () => TipoProductosAPI.get(),
    setTiposProducto: arr => dispatch(setTiposProducto(arr)),
    fetchFincas: () => FincasAPI.get(),
    setFincas: arr => dispatch(setFincasNew(arr)),
    fetchMatrizDeCostos: data => ReportesAPI.getMatrizDeCostos(data),
    fetchProductos: () => ProductosAPI.get(),
    setProductos: arr => dispatch(setProductos(arr)),
    fetchDosis: () => DosisAPI.get(),
    setDosis: arr => dispatch(setDosis(arr)),
    fetchProgramas: () => ProgramaAPI.get(),
    setProgramas: arr => dispatch(setProgramas(arr)),
    setAnio: val => dispatch(setAnio(val)),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramaTecnico);
