import React from 'react';
import { ACEITE, FUNGICIDA } from '../../../data';

const THead = (props) => {
  const { data } = props;
  const fungicidasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === FUNGICIDA);
    } return [];
  });

  const aceitesMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === ACEITE);
    } return [];
  });

  const aceitesMaxSize = aceitesMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const columnsMaxSize = fungicidasMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const fungicidasArrCount = new Array(columnsMaxSize);
  const aceitesArrCount = new Array(aceitesMaxSize);

  let titleNames = ['CICLO', 'FINCA', 'FECHA PROG', 'FECHA REAL', 'FREC PROG', 'FREC REAL'];
  let arrFungicidas = [];
  let arrAceites = [];

  for (let i = 0; i < fungicidasArrCount.length; i++) {
    arrFungicidas = arrFungicidas.concat([`FUNGICIDA ${i + 1}`]);
  }

  for (let i = 0; i < aceitesArrCount.length; i++) {
    arrAceites = arrAceites.concat([`ACEITE ${i + 1}`]);
  }

  titleNames = titleNames.concat(arrFungicidas);
  titleNames = titleNames.concat(arrAceites);
  titleNames = titleNames.concat(['DP', 'DF', 'MOTIVO']);

  const headings = titleNames.map((valor, index) => (
    <>
      <th key={index} className="textCenter">{valor}</th>
    </>
  ));

  return (
    <thead>
      <tr>
        {headings}
      </tr>
    </thead>
  );
};

export default React.memo(THead);
