import React, { Component } from 'react';

class Signin extends Component {
  render() {
    return (
      <div className="user-login-5">
        <div className="row bs-reset">
          <div className="col-md-6 login-container bs-reset">
            <img className="login-logo login-6" alt="login" style={{ width: '35%' }} src="/imagenes/logo.png" />
            <div className="login-content">
              <h1>Inicio de Sesión</h1>
              <p>
                {' '}
Introduzca su correo y contraseña para ingresar a SIGAT y poder controlar y monitorear la Sigatoka Negra en el cultivo de banano.
                <br />
                        Si usted no recuerda su correo y/o contraseña de clic en 'olvidó contraseña' y enviaremos a su correo la información
              </p>
              <form action="http://sigat.procesos-iq.com/controllers/Aceso.php" className="login-form" method="post">
                <div className="alert alert-danger display-hide">
                  <button className="close" data-close="alert" />
                  <span>Ingrese su correo y contraseña. </span>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <input className="form-control form-control-solid placeholder-no-fix form-group" type="text" autoComplete="off" placeholder="Correo" name="username" required />
                  </div>
                  <div className="col-xs-6">
                    <input className="form-control form-control-solid placeholder-no-fix form-group" type="password" autoComplete="off" placeholder="Contraseña" name="password" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="rem-password">
                      <p>
Recordarme
                        <input type="checkbox" className="rem-checkbox" />
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-8 text-right">
                    <div className="forgot-password">
                      <a href="javascript:;" id="forget-password" className="forget-password">Olvidó su contraseña?</a>
                    </div>
                    <button className="btn blue" type="submit">Ingresar</button>
                  </div>
                </div>
              </form>
              <form className="forget-form" action="javascript:;" method="post">
                <h3 className="font-green">Olvide mi contraseña ?</h3>
                <p> Ingrese su e-mail para recuperar su contraseña. </p>
                <div className="form-group">
                  <input className="form-control placeholder-no-fix" type="text" autoComplete="off" placeholder="Email" name="email" />
                </div>
                <div className="form-actions">
                  <button type="button" id="back-btn" className="btn grey btn-default">Regresar</button>
                  <button type="submit" className="btn blue btn-success uppercase pull-right">Enviar</button>
                </div>
              </form>
            </div>
            <div className="login-footer">
              <div className="row bs-reset">
                <div className="col-xs-5 bs-reset">
                  <ul className="login-social">
                    <li>
                      <a href="javascript:;">
                        <i className="icon-social-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <i className="icon-social-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;">
                        <i className="icon-social-dribbble" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-7 bs-reset">
                  <div className="login-copyright text-right">
                    <p>Copyright &copy; Sigat 2016</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 bs-reset">
            <div className="login-bg" />
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
