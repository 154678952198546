import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { AplicacionesAPI } from '../api/rest-api';

class ConfirmDeleteModal extends Component {
  constructor(props) {
    super(props);

    const { aplicacion } = props;

    this.state = {
      aplicacion,
      show: false
    };

    this.onDeleteAsk = this.onDeleteAsk.bind(this);
    this.confirm = this.confirm.bind(this);
    this.borrarAplicacion = this.borrarAplicacion.bind(this);
  }

  onDeleteAsk = () => (evt) => {
    this.confirm();
  }

    borrarAplicacion = async () => {
      const {
        aplicacion,
        programa,
        removerAplicacion,
        isNuevaAplicacion,
        key
      } = this.props;

      if (isNuevaAplicacion) {
        removerAplicacion(key);
        return;
      }

      /* Si no estamos en la app nueva. */

      const response = await AplicacionesAPI.delete(aplicacion.id);
      if (response.status === 204) {
        removerAplicacion(aplicacion.id, programa);
      } else {
        console.error('no se pudo eliminar');
      }
    }

  confirm = () => {
    confirmAlert({
      title: 'Eliminacion de Aplicacion',
      message: 'Esta seguro de que desea eliminar este documento?',
      buttons: [
        {
          label: 'Si',
          onClick: () => this.borrarAplicacion()
        },
        {
          label: 'No',
        }
      ]
    });
  };


    render = () => {
      const { aplicacion, programa } = this.props;
      const { show } = this.state;

      return (
        <>
          <button className="btn btn-sm btn-danger btn-circle" title="Borrar" ng-hide="row.por_borrar" onClick={this.onDeleteAsk(aplicacion.id, programa)}>
            <i className="fa fa-trash" />
          </button>
        </>
      );
    }
}

export default React.memo(ConfirmDeleteModal);
