import DateHelper from '../../helpers/dateHelper';
import {
  ReportesAPI
} from '../../api/rest-api';

import { resetBoards } from './fetchAll';

export const SELECT_TIPO_APLICACION = 'SELECT_TIPO_APLICACION';
const initialState = null;

export const setTipoAplicacion = value => async (dispatch, getState) => {

  await dispatch({
    type: SELECT_TIPO_APLICACION,
    value: value || null
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_TIPO_APLICACION:
      return action.value;

    default:
      return state;
  }
}
