import { resetBoards } from './fetchAll';

export const SELECT_PROGRAMA = 'SELECT_PROGRAMA';

const initialState = 1;

export function selectPrograma(value) {
  return {
    type: SELECT_PROGRAMA,
    value
  };
}

export const setPrograma = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_PROGRAMA,
    value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROGRAMA:
      return action.value;

    default:
      return state;
  }
}
