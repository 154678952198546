import React, { Component } from 'react';

import ReactEcharts from 'echarts-for-react';

const fracObject = {
    Amina: null,
    Anilinopirimidinas: null,
    Triazol: null,
    Guanidinas: null,
    Carboxamidas: null,
    Estrobilurinas: null,
}

export default class Frac extends Component {

    getClassColor(value){
        if(value > 0){
            return 'bg-green-jungle bg-font-green-jungle'
        }else if(value == 0){
            return 'bg-yellow-gold bg-font-yellow-gold'
        }else{
            return 'bg-red-thunderbird bg-font-red-thunderbird'
        }
    }

    
    render(){
        const {data} = this.props;
        let fracCols =  ['FINCAS'];
        fracCols = fracCols.concat(Object.keys(fracObject));
        const fracColsJSX = fracCols.map( col => (
            <>
            <th className="center-th">{col}</th>
            </>
        ));

        const fracsJSX = data.map(item =>(
            <>
            <tr ng-repeat="valor in table_frac">
                <td className="center-th {{ semaforoSaldo(data) }}" ng-repeat="(campo, data) in valor">
                    {item.name}
                </td>
                {Object.keys(fracObject).map(key =>(
                    <>
                        <td className={`center-th ${this.getClassColor(item.data[key])}`} ng-repeat="(campo, data) in valor">
                            {item.data[key]}
                        </td>
                    </>
                ))}
            </tr>
            </>
        ));
        
        return (
            <>
            <div className="portlet light bordered">
                <div className="portlet-title">
                <div className="caption">
                    <i className="icon-settings font-dark" />
                    <span className="caption-subject font-dark sbold uppercase">FRAC</span>
                </div>
                <div className="actions">
                </div>
                </div>
                <div className="portlet-body">
                <div className="col-md-2 col-sm-12 pull-right">
                    <ul style={{listStyle: 'none'}}>
                    <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-red-thunderbird bg-font-red-thunderbird" /> Pasado</li>
                    <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-yellow-gold bg-font-yellow-gold" /> Sin Saldo</li>
                    <li><div style={{width: '10px', height: '10px', display: 'inline-block'}} className="bg-green-haze bg-font-green-haze" /> Saldo</li>
                    </ul>
                </div>
                <div className="table-scrollable">                                
                    <table className="table table-striped table-bordered table-hover" id="datatable_ciclos_aplicados">
                    <thead>
                        <tr>
                        {fracColsJSX}
                        </tr>
                    </thead>
                    <tbody>
                        {fracsJSX}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </>
        );
    }
}

