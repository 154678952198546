export const SELECT_FINCA = 'SELECT_FINCA';

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FINCA:
      return action.value;

    default:
      return state;
  }
}
