import DateHelper from '../../helpers/dateHelper';
import { resetBoards } from './fetchAll';
import {TIPOS_HECTAREAJE} from '../../data';
import {SET_MATRIZ_COSTOS} from './matrizCostos';

import {
  ReportesAPI
} from '../../api/rest-api';

export const SELECT_HECTAREA = 'SELECT_HECTAREA';
const initialState = null;

export const setHectarea = value => async (dispatch, getState) => {

  await dispatch({
    type: SELECT_HECTAREA,
    value: value || null
  });

  resetBoards(dispatch, getState);
};

export const selectHectareaje = value => async (dispatch, getState) => {

  const {selectedFinca,selectedAnio} = getState();

  ReportesAPI.getMatrizDeCostos(
    {
      anio: selectedAnio,
      id_finca: selectedFinca,
      tipo_hectareaje: value
    }
  ).then((response) => {
    const { data } = response;
    dispatch({
      type: SET_MATRIZ_COSTOS,
      value: data
    });
  }).catch((err) => {
    console.error(err);
  });

  return dispatch({
    type: SELECT_HECTAREA,
    value
  });
};

export default function reducer(state = TIPOS_HECTAREAJE[3].id, action) {
  switch (action.type) {
    case SELECT_HECTAREA:
      return action.value;

    default:
      return state;
  }
}
