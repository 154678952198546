import DateHelper from '../../helpers/dateHelper';
import { resetBoards } from './fetchAll';

export const SELECT_SEMANA_FINAL = 'SELECT_SEMANA_FINAL';

const initialState = DateHelper.getWeeks(new Date(), null);

export const setSemanaFinal = value => async (dispatch, getState) => {
  const { selectedFinca } = getState();

  await dispatch({
    type: SELECT_SEMANA_FINAL,
    value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SEMANA_FINAL:
      return action.value;

    default:
      return state;
  }
}
