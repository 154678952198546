import React from 'react';

/*
  anios: 'anios'
*/
const Thead = (props) => {
  const { anios, showTotales } = props;

  const aniosJSX = anios.map((anio, index) => {
    if (index === 0) {
      return (
              <th key={index} className="center-th">{anio}</th>
      );
    }
    if (showTotales) {
      return (
        <>
          <th key={index} className="center-th">{anio}</th>
          <td key={index*10} />
        </>
      );
    }
    return (
      <th className="center-th">{anio}</th>
    );
  });
  return (
    <thead>
      <tr className="cursor">
          <th key={'parent'} className="center-th asc" ng-click="tables.dolares.orderBy = 'finca'; tables.dolares.reverse = !tables.dolares.reverse;" style={{ position: "sticky", zIndex: 1, backgroundColor: "white", left: 0 }}>FINCAS</th>
        { aniosJSX }
      </tr>
    </thead>
  );
};

export default React.memo(Thead);
