import React from 'react';
import { connect } from 'react-redux';

import TMatrixCellEditable from './TMatrixCellEditable';
import AplicacionHelper from '../../helpers/AplicacionHelper';
import ProductTooltip from './ProductTooltip';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

import CrearProductoFormProvider from '../context/CrearProducto';
import ModalCrearProducto from '../../containers/ModalCrearProducto';
import { fetchTarifasFromProgramaId } from '../../redux/modules/fetchAll';

import DateHelper from '../../helpers/dateHelper';
import { TIPOS_INPUT } from './TMatrixCellEditable';

const $ = require('jquery');

window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

const TAplicacionRow = (props) => {
  const {
    aplicacion,
    programa,
    programaData,
    onDeleteAsk,
    tipoHectarea,
    crearNuevoValorTemporal,
    removerAplicacion,
    agregarProducto,
    indice,
    aplicacionesNuevas,
    programas,
    isParcial,
    fetchTarifasFromProgramaId,
  } = props;

  const costoTotalOperacion = aplicacion.ha * aplicacion.costo_operacion;
  const costoTotal = AplicacionHelper.calcularTotal(aplicacion);
  // const costoHectareas = tipoHectarea ? (costoTotal / (tipoHectarea.divisor || 1)).toFixed(2) : 'selecciona una hectarea';

  const costoHectareas = aplicacion.hectareaje ? (costoTotal / (aplicacion.hectareaje || 1)).toFixed(2) : '-';

  const { productos } = aplicacion;

  const longitudAppsNuevas = aplicacionesNuevas.reduce((max, p) => {
    if (!p.productos) return max;

    const longitud = p.productos.length;
    if (p.productos.length > max) return longitud;
    return max;
  }, 0);

  let longitudMaxima = AplicacionHelper.getLongitudMaximaProductos(programaData);

  longitudMaxima = longitudAppsNuevas > longitudMaxima ? longitudAppsNuevas : longitudMaxima;
  const productsSize = productos.length;

  const filledSize = Array.from(Array(longitudMaxima + 1), (x, index) => index + 1);

  const { fecha_programacion, fecha_real, semana } = aplicacion;

  const diferencia = DateHelper.substractDates(fecha_real, fecha_programacion) || 0;

  let galonaje = 0;

  return (
    <CrearProductoFormProvider>
      <tr data-key={aplicacion.id} key={aplicacion.id}>
        <td>
          <ConfirmDeleteModal
            isNuevaAplicacion={false}
            aplicacion={aplicacion}
            programa={aplicacion.programa}
            removerAplicacion={removerAplicacion}
          />
        </td>
        <TMatrixCellEditable
          tipo_input={TIPOS_INPUT.NUMBER}
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          nombre_atributo="naplicacion"
          nombre_submit="ciclo"
        />
        { isParcial && (<td>{ Number(aplicacion.ciclo2).toFixed(2) || '' }</td>) }
        <TMatrixCellEditable
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          tipo_input={TIPOS_INPUT.SELECT}
          choices={programas || []}
          nombre_atributo="programa"
          nombre_submit="programa"
        />
        <td>{ semana || '' }</td>
        <TMatrixCellEditable
          tipo_input={TIPOS_INPUT.DATE}
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          nombre_atributo="fecha_programacion"
          nombre_submit="fecha_programada"
        />
        <TMatrixCellEditable
          tipo_input={TIPOS_INPUT.DATE}
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          nombre_atributo="fecha_real"
          nombre_submit="fecha_efectiva"
        />
        <TMatrixCellEditable
          tipo_input={TIPOS_INPUT.NUMBER}
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          nombre_atributo="ha"
          nombre_submit="hectareas"
        />
        <td>{ diferencia }</td>
        <td>{aplicacion.motivo_atraso && aplicacion.motivo_atraso.id ? aplicacion.motivo_atraso.nombre : '' }</td>
        {
            filledSize.map(i => {
              if(aplicacion.productos[i - 1]){
                if(aplicacion.productos[i-1].tipo_producto=='MEZCLA' || aplicacion.productos[i-1].tipo_producto=='Aceite'){
                  galonaje = galonaje + aplicacion.productos[i-1].dosis;
                }
              }
              return(
              <ProductTooltip
                fetchTarifasFromProgramaId={fetchTarifasFromProgramaId}
                key={i}
                index={i}
                producto={aplicacion.productos[i - 1] || null}
                hectarea={aplicacion.ha || aplicacion.hectareas}
                aplicacion={aplicacion}
              />)
            })
                        }
        <td>
          {/* {aplicacion.productos[i - 1]
            ? (aplicacion.productos[i - 1].dosis).toFixed(2) : null} */}
            {galonaje!=0 ? (galonaje*aplicacion.ha/aplicacion.hectareaje).toFixed(2) : galonaje}
        </td>
        {
            filledSize.map(i => (
              <td>
                {aplicacion.productos[i - 1]
                 ? (aplicacion.productos[i - 1].dosis).toFixed(2) : null}
              </td>
            ))
                        }
        {
            filledSize.map(i => (
              <td>
                {aplicacion.productos[i - 1]
                  ? (aplicacion.productos[i - 1].dosis * aplicacion.ha).toFixed(2) : null}
              </td>
            ))
                        }
        {
            filledSize.map(i => (
              <td>
                {aplicacion.productos[i - 1] && aplicacion.productos[i - 1].precio
                 ? (aplicacion.productos[i - 1].precio).toFixed(2) : null}
              </td>
            ))
                        }
        {
            filledSize.map(i => (
              <td>
                {aplicacion.productos[i - 1]
                  ? (aplicacion.productos[i - 1].precio * (aplicacion.productos[i - 1].dosis * aplicacion.ha)).toFixed(2) : null}
              </td>
            ))
                        }
        <TMatrixCellEditable
          tipo_input={TIPOS_INPUT.NUMBER}
          aplicacion={aplicacion}
          programa={programa}
          crearNuevoValorTemporal={crearNuevoValorTemporal}
          nombre_atributo="costo_operacion"
          nombre_submit="costo_operacion"
        />
        <td>{ !Number.isNaN(costoTotalOperacion) ? costoTotalOperacion.toFixed(2) : costoTotalOperacion }</td>
        <td>{ !Number.isNaN(costoTotal) ? costoTotal.toFixed(2) : costoTotal}</td>
        <td>
          { !Number.isNaN(costoHectareas) ? parseFloat(costoHectareas).toFixed(2) : costoHectareas }
          <ModalCrearProducto
            aplicacion={aplicacion}
            programa={programaData}
            programaKey={programa}
            indice={indice}
            agregarProducto={agregarProducto}
          />
        </td>
      </tr>
    </CrearProductoFormProvider>
  );
};


function mapStateToProps(state) {
  return {
    proveedores: state.proveedores,
    fincas: state.fincas,
    motivosAtraso: state.motivosAtraso,
    programas: state.programas,
    matrizCostos: state.matrizCostos,
    tipoProductos: state.tipoProductos,
    productos: state.productos,
  };
}
const mapDispatchToProps = dispatch => ({
  fetchTarifasFromProgramaId: id_programa => dispatch(fetchTarifasFromProgramaId(id_programa)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TAplicacionRow);
