import React from 'react';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useAlert } from 'react-alert';
import { transformNumber } from '../../helpers/numberHelper';

const $ = require('jquery');

window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

/* No funciona aun... */
const ProductTooltip = (props) => {
  const {
    index,
    producto,
    hectarea,
    aplicacion,
    fetchTarifasFromProgramaId
  } = props;

  const alert = useAlert();

  if (!producto) {
    return (
      <td
        className="pointer add-product"
        onClick={async (ev) => {
          ev.persist();
          if (aplicacion
              && (aplicacion.fecha_efectiva || (aplicacion.fecha_real))
              && (aplicacion.id_programa || (aplicacion.programa && !isNaN(aplicacion.programa)))) {
            await fetchTarifasFromProgramaId({
              id_programa: aplicacion.id_programa || aplicacion.programa,
              fecha_efectiva: aplicacion.fecha_efectiva || (aplicacion.fecha_real)
            });
            $(ev.target).closest('tr').find('div.modalCrear').modal('show');
          } else {
            let campos = '';
            if ((!aplicacion.fecha_efectiva && !aplicacion.fecha_real) && (!aplicacion.programa && !aplicacion.id_programa)) campos = 'Fecha Real, Programa';
            else if (!aplicacion.fecha_efectiva && !aplicacion.fecha_real) campos += 'Fecha Real';
            else if (!aplicacion.programa && !aplicacion.id_programa) campos += 'Programa';

            alert.error(`Debe llenar los campos ${campos}`);
          }
        }}
      >
        <span className="prueba"><i className="fa fa-plus add-product-plus btn btn-sm btn-outline red" /></span>
      </td>
    );
  }

  const cantidad = (Number(producto.dosis_cantidad || producto.dosis) * Number(hectarea)).toFixed(2) || 0;
  return (
    <OverlayTrigger
      overlay={(
        <Tooltip id={`tooltip-${index}`}>
          {`Tipo: ${producto.tipo_producto}`}
          <br />
          {`Proveedor: ${producto.proveedor}`}
          <br />
          { `Dosis: ${producto.dosis_cantidad || producto.dosis}`}
          <br />
          {`Cantidad: ${cantidad}`}
          <br />
          {`Tarifa: ${producto.precio}`}
          <br />
          {`Total: $: ${transformNumber(Number(cantidad) * producto.precio).toFixed(2)}`}
          <br />
        </Tooltip>
)}
    >
      <td className="pointer add-product" onClick={() => { $('#modalEditarProducto').modal('show'); }}>
        <span className="prueba">
          {producto ? producto.nombre : (
            <i
              onClick={(ev) => {
                if (aplicacion.id_programa) {
                  // await fetchTarifasFromProgramaId(aplicacion.id_programa);
                }
                $('#modalEditarProducto').modal('show');
              }}
              className="fa fa-plus add-product-plus btn btn-sm btn-outline red"
            />
          )}
        </span>
      </td>
    </OverlayTrigger>
  );
};


export default ProductTooltip;
