
export default class ProductoHelper {
  static getNamesFromJSONIds(proveedores, productos, dosis, json) {
    const newJSON = { ids: json };
    const proveedorData = proveedores.find(pv => pv.id.toString() === json.proveedor);

    const productoData = productos.find(pr => (pr.id.toString() === json.producto) && pr.proveedores.includes(proveedorData ? parseInt(proveedorData.id) : null));

    const dosisData = dosis.find(dosisItem => dosisItem.id.toString() === json.dosis.toString());

    if (proveedorData && dosisData && productoData) {
      newJSON.nombre = productoData.nombre;
      newJSON.proveedor = proveedorData.nombre;
      newJSON.dosis = dosisData.id;
      newJSON.dosis_cantidad = json.cantidad;
      newJSON.tarifa_precio = json.precio;
    }

    return newJSON;
  }

  static getIdsFromJSONNames(proveedores, productos, dosis, json) {
    const newJSON = { ...json.ids };
    return newJSON;
  }
}
