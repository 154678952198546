import React from 'react';
import { Provider as AlertProvider, positions } from 'react-alert';
import { Provider } from 'react-redux';
import AlertTemplate from 'react-alert-template-basic';
import { Route, Switch, Redirect } from 'react-router'; // react-router v4
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/configureStore';
import Signin from './containers/Signin';
import SigatDashboard from './components/SigatDashboard';
import CostosDashboard from './containers/CostosDashboard';
import ProgramaTecnico from './containers/ProgramaTecnico';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const initialState = {};

const store = configureStore(initialState);

const App = () => (
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <ConnectedRouter history={history}>
        <>
          <Route
            exact
            path="/signin"
            name="Inicio de Sesión"
            component={() => <Signin />}
          />
          <Route path="/sigat" component={Header} />
          <div className="clearfix" />
          <div className="page-container">
            <Route path="/sigat" component={Sidebar} />
            <Switch>
              <Route path="/sigat/dashboard" component={SigatDashboard} />
              <Route path="/sigat/programaTecnico" component={ProgramaTecnico} />
              <Route path="/sigat/costos" component={CostosDashboard} />
              <Redirect to="/sigat/dashboard" />
            </Switch>
          </div>
        </>
      </ConnectedRouter>
    </AlertProvider>
  </Provider>
);

export default App;
