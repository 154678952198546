import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateHelper from '../../../helpers/dateHelper'

const uuidv4 = require('uuid/v4');

export default class TBodyRow extends Component {
  constructor(props) {
    super(props);

    const { rowData } = props;

    this.state = {
      rowData,
      childrenShown: false,
    };

    this.onClickRow = this.onClickRow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.dataRow !== prevState.dataRow) {
      return ({
        dataRow: nextProps.dataRow,
      });
    }
    return prevState;
  }

    onClickRow = () => {
      const { rowData, childrenShown } = this.state;

      this.setState({
        childrenShown: !childrenShown
      });
    }

    render() {
      const { rowData, childrenShown } = this.state;
      const { child } = this.props;

      const data = rowData.data ? rowData.data : [];
      const icons = {
        better: 'fa fa-arrow-down font-green-haze',
        worse: 'fa fa-arrow-up font-red-thunderbird',
        equal: 'fa fa-arrow-right font-yellow-gold'
      };

      const childrenTitles = ['PROD', 'TIPO', 'DOSIS', 'CANT'];

      const trClass = rowData.data ? '' : '';

      const textCenter = 'text-center';
      const className = child ? '' : textCenter;

      let children = (<React.Fragment />);

      if (rowData.data && childrenShown) {
        children = data.map((item, index) => (
          <TBodyRow key={`${item.id}`} rowData={item} child />
        ));

        children = [children, (<tr key={`${uuidv4()}`}>
          <td colSpan={data ? data.length : 1} className="divider active" />
        </tr>)];


        const fatherChildrenRow = childrenTitles.map((title, index) => {
          if (index === 0) {
            return (
              <th colSpan={2} className="textCenter">{ title }</th>
            );
          }
          return (
            <th colSpan={1} className="textCenter">{ title }</th>
          );
        });

        children = [(<tr key={`${uuidv4()}`}>
          { fatherChildrenRow }
        </tr>), children];
      }

      if (!rowData) {
        return (<> </>);
      }
      return (
        <>
          <tr key={`${rowData.name}`} onClick={this.onClickRow}>
            <td
              colSpan={rowData.nombre ? '2' : '1'}
              className={className}
            >
              { rowData.ciclo || rowData.nombre }
            </td>
            <td className={className}>{ rowData.semana || rowData.tipo }</td>
            <td className={className}>{ rowData.fecha_real || rowData.dosis }</td>
              { rowData.cantidad &&
                <td className={className}>{ rowData.cantidad }</td>
              }
              {!isNaN(rowData.frecuencia_real) && 
              <>
                <td className={className}>{ rowData.frecuencia_real }</td>
                <td className={className}>{ rowData.hectareaje }</td>
              </>
            }
          </tr>
          { children }
        </>
      );
    }
}
