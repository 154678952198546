import React, { Component } from 'react';
import counterUp from 'counterup2';

import CountUp from 'react-countup';


export class Indicador extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      valor,
      color,
      nombre
    } = this.props;

    return (

      <div className="dashboard-stat2 ">
        <div className="display">
          <div className="number">
            <h3 className={`font-${color}`}>
              <CountUp
                start={0}
                useEasing
                delay={5}
                decimals={2}
                decimal=","
                end={valor}

                duration={3}
              />
              <small className={`font-${color}`} />
            </h3>
            <small>{nombre}</small>
          </div>
          <div className="icon">
            <i className="icon-settings" />
          </div>
        </div>
        <div className="progress-info">
          <div className="progress">
            <span className={`progress-bar progress-bar-success ${color}`} style={{ width: `${valor}px` }}>
              <span className="sr-only">{valor}</span>
            </span>
          </div>
          <div className="status">
            <div className="status-title" />
            <div className="status-number">{valor}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(Indicador);
