import React from 'react';
import Indicador from '../../indicadores/indicador';

const Indicadores = (props) => {
  const { data } = props;

  const indicadores = data.reduce((acum, item) => {
    const itemMapped = item.map(child => (
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
        <Indicador
          nombre={child.nombre}
          valor={child.valor}
          color={child.color}
        />
      </div>
    ));
    return acum.concat(itemMapped);
  }, []);
  return (
    <div id="indicadores">
      <div className="row">
        {indicadores}
      </div>
    </div>

  );
};

export default React.memo(Indicadores);
