import { resetBoards } from './fetchAll';

export const SELECT_FUMIGADORA = 'SELECT_FUMIGADORA';

const initialState = null;

// export function selectPrograma(value) {
//   return {
//     type: SELECT_PROGRAMA,
//     value
//   };
// }

export const setFumigadora = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_FUMIGADORA,
    value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FUMIGADORA:
      return action.value;

    default:
      return state;
  }
}
