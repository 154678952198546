import 'moment-timezone';

const moment = require('moment');


const DEFAULT_TIME_ZONE = 'America/Guayaquil';

class DateHelper {
  /*
      get Year with timezone
     */
  static getCurrentYear() {
    // const currentYear = moment().tz().toDate(DEFAULT_TIME_ZONE).getYear();
    const year = moment().format('YYYY');
    return year;
    // return currentYear + 1900;
  }

  /*
     * @param rangofechas:  {inicio:date, final:date}
     * @return mismo objeto con horas y minutos formateados para cubrir el rango.
     */
  static setDateTimeFromRango(rangoFechas) {
    const { inicio, final } = rangoFechas;

    inicio.setHours(0, 0, 0, 0);
    final.setHours(23, 57, 59, 99999);
    return {
      inicio, final
    };
  }

  /*
     * @param rangofechas:  {inicio:date, final:date}
     * @return {inicio:moment(date), final: moment(date)}.
     */
  static getMomentFromRangoFechas(rangoFechas) {
    const rangoConDateTimes = DateHelper.setDateTimeFromRango(rangoFechas);
    const { inicio, final } = rangoConDateTimes;
    return {
      inicio: moment(inicio.toString()).format('YYYY-MM-DD HH:mm'),
      final: moment(final.toString()).format('YYYY-MM-DD HH:mm')
    };
  }

  static getMomentFromFecha(fecha) {
    return moment(fecha.toString()).format('YYYY-MM-DD');
  }

  static getDateFromString(date) {
    return moment.tz(date, DEFAULT_TIME_ZONE).toDate();
  }

  static getFormattedDate(momentDate) {
    return momentDate.format('YYYY-MM-DD');
  }

  static substractDates(date1, date2) {
    const firstDate = moment(date1);
    const secondDate = moment(date2);

    return firstDate.diff(secondDate, 'days');
  }

  static addDaysToDate(date, days) {
    return moment(date, 'YYYY-MM-DD').add(days, 'days');
  }

  static getWeeks(date, format) {
    const momentDate = moment(date, 'YYYY-MM-DD');
    let week = momentDate.isoWeek();
    if (week >= 52 && momentDate.month() == 0)
      week = 1;
    return week;
  }
}

export default DateHelper;
