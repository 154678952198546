import React, { Component } from 'react';

import THead from './ciclosGeneral/THead';
import TBody from './ciclosGeneral/TBody';

class CiclosGeneral extends Component {
    render = () => {
      let {
        data,
        isParcial,
        title
      } = this.props;


      let dataJson = {};

      let dataCopy=JSON.parse(JSON.stringify(data));
      dataCopy.forEach((el,index) => {

        let dataChild={};

        for (let i = 0; i < el.data.length; i++) {
          const producto = el.data[i];
          if(!dataChild.hasOwnProperty(producto.nombre)){
            dataChild[producto.nombre] =  producto;
  
          }
        }

        if(!dataJson.hasOwnProperty(el.ciclo)){
          dataJson[el.ciclo] =  [el];

        }
        else{
          dataJson[el.ciclo].push(el);
        }

        el.data = dataChild;
        
      });
      console.log(dataJson);

      
      let prueba = Object.keys(dataJson).map((key,index)=>{
        let aplicacion = dataJson[key];
        let productos = aplicacion.data;
        let arrayProd = [];

        if(aplicacion.length>1){
          let result = aplicacion[0];
          for(let i=1; i<aplicacion.length; i++){
              let e = aplicacion[i];
              result.hectareaje = result.hectareaje + e.hectareaje;
              for (let keyProducto in e.data){
                let x = e.data[keyProducto];
                if(result.data.hasOwnProperty(keyProducto)){
                  result.data[keyProducto].dosis = result.data[keyProducto].dosis + x.dosis;
                  result.data[keyProducto].cantidad = result.data[keyProducto].cantidad + x.cantidad;
                }
                else{
                  result.data[keyProducto] = x;
                }
              }
          }
          dataJson[key]=[result];

        }

        

        console.log(dataJson[key]);
        
        
        // console.log(Object.keys(dataJson[key].data));



        let dataChild = Object.keys(dataJson[key][0].data).map((key2,index2)=>{
          return dataJson[key][0].data[key2]
        });
        

        dataJson[key][0].data = dataChild;
        return dataJson[key][0];
        // data2.push(dataJson[key]);
        
      });
      console.log(prueba);
      console.log(dataJson);
      console.log(data);
      // prueba es la conversion de datajson en array es la version final de data sumando las dosis
      // data=prueba;
      



      let realData = data;
      if (isParcial) {
        realData = data.filter(ciclo => (ciclo.tipo && ciclo.tipo === 'parcial'));
      } else {
        realData = data.filter(ciclo => (!ciclo.tipo || ciclo.tipo !== 'parcial'));
      }
      return (
        <div className="portlet light portlet-fit portlet-datatable bordered">
          <div className="portlet-title">
            <div className="caption">
              <i className="icon-settings font-dark" />
              <span className="caption-subject font-dark sbold uppercase">
PROGRAMA TECNICO
              <small>({title || 'CICLOS'})</small>
              </span>
            </div>
            <div className="actions">
              <div className="btn-group pull-right">
                <a className="btn blue btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                    Exportar
                  {' '}
                  <i className="fa fa-angle-down" />
                </a>
                <ul className="dropdown-menu pull-right">
                  <li>
                    <a href="javascript:;" ng-click="exportExcel('datatable_ajax_1')">Excel</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="portlet-body">
            <div className="table-container table-scrollable">
              <table className="table table-striped table-bordered table-hover">
                <THead />
                <TBody
                  data={realData}
                />
              </table>
            </div>
          </div>
        </div>

      );
    }
}

export default React.memo(CiclosGeneral);
