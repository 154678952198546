import React from 'react';
import { ACEITE, FUNGICIDA } from '../../../data';

const THead = (props) => {
  const { data } = props;
  const fungicidasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === FUNGICIDA);
    } return [];
  });

  const aceitesMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === ACEITE);
    } return [];
  });

  const aguasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === 'MEZCLA');
    } return [];
  });

  const aceitesMaxSize = aceitesMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const columnsMaxSize = fungicidasMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const aguaMaxSize = aguasMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const fungicidasArrCount = new Array(columnsMaxSize);
  const aceitesArrCount = new Array(aceitesMaxSize);
  const aguasArrCount = new Array(aguaMaxSize);

  let titleNames = ['CICLO', ];
  let arrFungicidas = [];
  let arrAceites = [];
  let arrAguas = [];

  for (let i = 0; i < fungicidasArrCount.length; i++) {
    arrFungicidas = arrFungicidas.concat([`FUNGICIDA ${i + 1}`, 'DOSIS']);
  }

  for (let i = 0; i < aceitesArrCount.length; i++) {
    arrAceites = arrAceites.concat([`ACEITE ${i + 1}`, 'DOSIS']);
  }

  for (let i = 0; i < aguasArrCount.length; i++) {
    arrAguas = arrAguas.concat([`AGUA ${i + 1}`, 'DOSIS']);
  }


  // titleNames = titleNames.concat(arrFungicidas);
  titleNames = titleNames.concat(arrAceites);
  titleNames = titleNames.concat(arrAguas);

  if (titleNames.length === 1) {
    // titleNames = titleNames.concat(['FUNGICIDA', 'DOSIS']);
  }

  titleNames = titleNames.concat(['GALONEAJE']);

  const headings = titleNames.map((valor, index) => (
    <>
      <th key={index} className="textCenter">{valor}</th>
    </>
  ));

  return (
    <thead>
      <tr>
        { headings }
      </tr>
    </thead>
  );
};

export default React.memo(THead);
