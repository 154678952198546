import { resetBoards } from './fetchAll';
export const SELECT_CATEGORIA = 'SELECT_CATEGORIA';
const initialState = null;

export const setCategoria = value => async (dispatch, getState) => {
  await dispatch({
    type: SELECT_CATEGORIA,
    value: value
  });

  resetBoards(dispatch, getState);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_CATEGORIA:
      return action.value;

    default:
      return state;
  }
}
