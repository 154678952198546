
export const getOption = (dataObject) => {

    const keys = Object.keys(dataObject);
    let data = [];

    for (var key in dataObject) {
        if (dataObject.hasOwnProperty(key)) {
            data.push({name:key,value:dataObject[key]});
        }
    }

    const option = {
        // title : {
        //     text: '某站点用户访问来源',
        //     subtext: '纯属虚构',
        //     x:'center'
        // },
        tooltip : {
            trigger: 'item',
            formatter: "{b} : {c} ({d}%)"
        },
        legend: {
            bottom: 0,
        left: 'center',
            data: keys
        },
        series : [
            {
                name: '访问来源',
                type: 'pie',
                radius : '55%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                data: data,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    normal:{
                        label:{
                            show: true,
                            position: 'top',
                            formatter: '{b} \n{c} ({d}%)'
                        }
                    }
                }
            }
        ]
    };
    return option;
  };
