export const FETCH_UPDATE = 'FETCH_UPDATE';
export const status = {
  REQ: 'req', SUCCESS: 'success', ERROR: 'error'
};

export const labels = {
  CREAR_APLICACION: 'crear aplicacion'
};

const initialState = {};

export function setStatus(label, statusLabel = null) {
  return {
    type: FETCH_UPDATE,
    value: { [`${label}`]: statusLabel }
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPDATE:
      return { ...state, ...action.value };
    default:
      return state;
  }
}
