import React, { Component } from 'react';

import THead from './ciclosDetalle/THead';
import TBody from './ciclosDetalle/TBody';

const CiclosDetalle = (props) => {
  const { data, isParcial } = props;
  let realData = data;
  if (isParcial) {
    realData = data.filter(ciclo => (ciclo.tipo && ciclo.tipo === 'parcial'));
  } else {
    realData = data.filter(ciclo => (!ciclo.tipo || ciclo.tipo !== 'parcial'));
  }
  return (

    <div className="portlet light portlet-fit portlet-datatable bordered">
      <div className="portlet-title">
        <div className="caption">
          <i className="icon-settings font-dark" />
          <span className="caption-subject font-dark sbold uppercase">
PROGRAMA TECNICO
            <small>(CICLOS)</small>
          </span>
        </div>
        <div className="actions col-md-12">
          <div style={{ float: 'left' }}>
            <div className="btn-group hide">
              <a className="btn blue-ebonyclay btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                        Columnas
                {' '}
                <i className="fa fa-angle-down" />
              </a>
              <ul className="dropdown-menu pull-right" />
            </div>
          </div>
          <div className="btn-group pull-right hide">
            <a className="btn blue btn-outline btn-circle btn-sm" href="javascript:;" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="false">
                                    Exportar
              {' '}
              <i className="fa fa-angle-down" />
            </a>
            <ul className="dropdown-menu pull-right">
              <li>
                <a href="javascript:;" ng-click="exportExcel('datatable_ajax_1')">Excel</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="portlet-body">
        <div className="table-container table-scrollable">
          <table className="table table-striped table-bordered table-hover">
            <THead data={realData} />
            <TBody data={realData} />
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CiclosDetalle);
