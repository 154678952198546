const SET_PRODUCTOS = 'SET_PRODUCTOS';

const initialState = [];

export function setProductos(value) {
  return {
    type: SET_PRODUCTOS,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTOS:
      return action.value;

    default:
      return state;
  }
}
