import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner ">
          <div className="page-logo">
            <a href="#">
              <img src="/imagenes/logo.png" style={{ width: '150px', marginTop: '10px' }} alt="logo" className="logo-default" />
            </a>
            <div className="menu-toggler sidebar-toggler" />
          </div>
          <a href="javascript:;" className="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse" />

          <div className="page-top">
            <div className="top-menu">
              <ul className="nav navbar-nav pull-right">
                <li className="dropdown dropdown-user dropdown-dark">
                  <a href="javascript:;" className="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                    <span className="username username-hide-on-mobile" />
                  </a>
                  <ul className="dropdown-menu dropdown-menu-default">
                    <li>
                      <a href="index.php?page=user">
                        <i className="icon-user" />
                        {' '}
Mi Perfil
                      </a>
                    </li>

                    <li>
                      <a href="index.php?page=logout">
                        <i className="icon-key" />
                        {' '}
Log Out
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
