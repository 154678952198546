import React from 'react';
import { connect } from 'react-redux';

import DateHelper from '../../helpers/dateHelper';
import { transformNumber } from '../../helpers/numberHelper';
import { anios } from '../../data';
import {
  TIPO_HECTAREAS,
  programas,
} from '../../dataNuevo';


import TMatrixCellEditable, { TIPOS_INPUT } from './TMatrixCellEditable';
import AplicacionHelper from '../../helpers/AplicacionHelper';
import ProductTooltip from './ProductTooltip';
import ConfirmDeleteModal from '../ConfirmDeleteModal';
import ModalCrearProducto from '../../containers/ModalCrearProducto';

import { CrearProductoFormConsumer } from '../context/CrearProducto';

const $ = require('jquery');

window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

class TAplicacionNuevaRow extends React.Component {
    state = {
      aplicacion: {
        productos: []
      }
    }

    componentDidMount = () => {
      const {
        programa,
        tipoHectarea,
        removerAplicacionNueva,
        indice,
        programaKey,
        programas,
        removerProducto,
        agregarProducto,
        nuevasAplicaciones,
        isParcial,
      } = this.props;

      this.setProductos(nuevasAplicaciones && nuevasAplicaciones[programaKey] && nuevasAplicaciones[programaKey].productos ? nuevasAplicaciones[programaKey].productos : []);
    }

    componentWillReceiveProps(nextProps) {
      const {
        data,
      } = nextProps;

      this.setProductos(data && data.productos ? data.productos : []);
    }


    addProducto = (producto) => {
      const { productos } = this.state;
      this.setState({
        aplicacion: {
          ...this.state.aplicacion,
          productos: [...productos, producto]
        }
      });
    }

    setProductos = (productos) => {
      this.setState({
        aplicacion: {
          ...this.state.aplicacion,
          productos
        }
      });
    }

    setAtribute = (aplicacion, programa, newValue, atributo) => {
      this.setState({
        aplicacion: {
          ...this.state.aplicacion,
          [atributo]: newValue
        }
      });
    }

    setDiasAtraso = () => {

    }

    onAprobar = () => {
      const { crearAplicacion, indice, programaKey, isParcial } = this.props;
      const { aplicacion } = this.state;
      const aplicacionCloned = {...aplicacion, tipo_aplicacion: !isParcial ? 0:1}
      crearAplicacion(aplicacionCloned);
    }

    render = () => {
      const { aplicacion } = this.state;
      const {
        programa,
        tipoHectarea,
        removerAplicacionNueva,
        indice,
        programaKey,
        programas,
        removerProducto,
        agregarProducto,
        aplicacionesNuevas,
        motivosAtraso,
        fetchTarifasFromProgramaId,
          apiStatus,
        isParcial
      } = this.props;

      const costoTotalOperacion = aplicacion.hectareas * aplicacion.costo_operacion;
      const costoTotal = AplicacionHelper.calcularTotal(aplicacion);
      const costoHectareas = tipoHectarea ? (costoTotal / tipoHectarea.divisor).toFixed(2) : 'selecciona una hectarea';

      const longitudAppsNuevas = aplicacionesNuevas.reduce((max, p) => {
        if (!p.productos) return max;

        const longitud = p.productos.length;
        if (p.productos.length > max) return longitud;
        return max;
      }, 0);

      let longitudMaxima = AplicacionHelper.getLongitudMaximaProductos(programa);

      longitudMaxima = longitudAppsNuevas > longitudMaxima ? longitudAppsNuevas : longitudMaxima;

      const filledSize = Array.from(Array(longitudMaxima + 1), (x, index) => index + 1);

      const semana = aplicacion && aplicacion.fecha_efectiva
        ? DateHelper.getWeeks(aplicacion.fecha_efectiva) : 0;

      const diferencia = aplicacion && aplicacion.fecha_programada && aplicacion.fecha_efectiva
        ? DateHelper.substractDates(aplicacion.fecha_efectiva, aplicacion.fecha_programada) : '';

      return (
        <CrearProductoFormConsumer>
          {
  context => (
    <tr>
      <td>
        <ConfirmDeleteModal
          aplicacion={aplicacion}
          programa={aplicacion.programa}
          removerAplicacion={evt => removerAplicacionNueva(indice, programaKey)}
          isNuevaAplicacion
        />
        <button
          className="btn btn-sm btn-success btn-circle"
          title="Aprobar"
          onClick={this.onAprobar}
          disabled={apiStatus && apiStatus === 'req'}
        >
          <i className="fa fa-thumbs-o-up" />
        </button>
      </td>
      <TMatrixCellEditable
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        tipo_input={TIPOS_INPUT.NUMBER}
        nombre_atributo="ciclo"
        isNuevaAplicacion
      />
          {isParcial && <td />}
      <TMatrixCellEditable
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        tipo_input={TIPOS_INPUT.SELECT}
        choices={programas}
        nombre_atributo="programa"
        isNuevaAplicacion
      />
      <td>{ semana || '' }</td>
      <TMatrixCellEditable
        tipo_input={TIPOS_INPUT.DATE}
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        nombre_atributo="fecha_programada"
        isNuevaAplicacion
      />
      <TMatrixCellEditable
        tipo_input={TIPOS_INPUT.DATE}
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        nombre_atributo="fecha_efectiva"
        isNuevaAplicacion
      />
      <TMatrixCellEditable
        tipo_input={TIPOS_INPUT.NUMBER}
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        nombre_atributo="hectareas"
        isNuevaAplicacion
      />
      <td>{diferencia || ''}</td>
      <TMatrixCellEditable
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        tipo_input={TIPOS_INPUT.SELECT}
        choices={motivosAtraso || []}
        nombre_atributo="motivo_atraso"
        isNuevaAplicacion
      />
      {
            filledSize.map(i => (
              <ProductTooltip
                key={i}
                index={i}
                producto={aplicacion.productos[i - 1] || null}
                hectarea={aplicacion.hectareas}
                aplicacion={aplicacion}
                fetchTarifasFromProgramaId={fetchTarifasFromProgramaId}
              />
            ))
                        }
      {
                        filledSize.map(i => (<td>{aplicacion.productos[i - 1] ? aplicacion.productos[i - 1].dosis_cantidad || aplicacion.productos[i - 1].dosis: 0}</td>))
                        }
      {
          filledSize.map(i => (<td>{aplicacion.productos[i - 1] ? (aplicacion.productos[i - 1].dosis_cantidad || 0) * aplicacion.hectareas : 0}</td>))
                        }
      {
                        filledSize.map(i => (<td>{aplicacion.productos[i - 1] ? aplicacion.productos[i - 1].tarifa_precio || 0 : null}</td>))
                        }
      {
          filledSize.map(i => (<td>{aplicacion.productos[i - 1] ? Number(aplicacion.productos[i - 1].tarifa_precio || 0) * Number((aplicacion.productos[i - 1].dosis_cantidad || 0) * aplicacion.hectareas) : 0}</td>))
                        }
      <TMatrixCellEditable
        aplicacion={aplicacion}
        programa={programa}
        crearNuevoValorTemporal={this.setAtribute}
        tipo_input={TIPOS_INPUT.NUMBER}
        nombre_atributo="costo_operacion"
        isNuevaAplicacion
      />
      <td>{ costoTotalOperacion || 0 }</td>
      <td>{ costoTotal || 0 }</td>
      <td>
        { costoHectareas }

        <ModalCrearProducto
          aplicacion={aplicacion}
          programa={programas}
          agregarProducto={agregarProducto}

          programaKey={programaKey}
          indice={indice}
        />
      </td>
    </tr>
  )
}
        </CrearProductoFormConsumer>
      );
    }
}

export default TAplicacionNuevaRow;
