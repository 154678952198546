import React, { Component } from 'react';

import { connect } from 'react-redux';

import DateHelper from '../helpers/dateHelper';
import DashboardDataLoading from './DashboardDataLoading';

import './SigatDashboard.css';

import LoadingOverlay from 'react-loading-overlay';
import CiclosGeneral from './programaTecnico/CiclosGeneral';
import CiclosDetalle from './programaTecnico/CiclosDetalle';
import TablaAplicaciones from './programaTecnico/TablaAplicaciones';

import {
  TIPOS_APLICACION,
  TIPOS_HECTAREAJE,
  anios,
} from '../data';

class ProgramaTecnico extends Component {
  render() {
    const {
      categorias,
      setCategorias,
      fetchCategorias,
      programaTecnico,
      selectedAnio,
      fetchProgramaTecnico,
      proveedores,
      fincas,
      fetchTiposProducto,
      fetchProveedores,
      fetchMotivosAtraso,
      setFincas,
      fetchFincas,
      setProveedores,
      setMotivosAtraso,
      setProductos,
      fetchProductos,
      setTiposProducto,
      setProgramas,
      fetchProgramas,
      setDosis,
      fetchDosis,
      setAnio,
      selectedFinca,
      selectFinca,
      selectedCategoria,
    } = this.props;

    if ((!proveedores || proveedores.length === 0)
          || !fincas || !selectedFinca || !categorias) {
      return (
        <DashboardDataLoading
          setCategorias={setCategorias}
          fetchCategorias={fetchCategorias}
          fetchProveedores={fetchProveedores}
          setProveedores={setProveedores}
          fetchMotivosAtraso={fetchMotivosAtraso}
          setMotivosAtraso={setMotivosAtraso}
          fetchFincas={fetchFincas}
          setFincas={setFincas}
          fetchTiposProducto={fetchTiposProducto}
          setTiposProducto={setTiposProducto}
          fetchProductos={fetchProductos}
          setProductos={setProductos}
          fetchDosis={fetchDosis}
          setDosis={setDosis}
          fetchProgramas={fetchProgramas}
          setProgramas={setProgramas}
          selectedFinca={selectedFinca}
          selectAnio={setAnio}
          loadData={fetchProgramaTecnico}
        />
      );
    }

    const aniosJSX = anios.map(anio => (
      <option value={anio}>{anio}</option>
    ));

    const categoriasJSX = categorias.map(cat => (
      <option value={cat.id.toString()}>{cat.nombre.toUpperCase()}</option>
    ));

    const fincasJSX = fincas.map(finca => (
      <option value={finca.id} key={finca.id}>{ finca.nombre }</option>));

    return (
      <>
        <div className="page-content-wrapper">
          <link href="http://cdn.procesos-iq.com/global/plugins/datatables/datatables.min.css" rel="stylesheet" type="text/css" />
          <link href="http://cdn.procesos-iq.com/global/plugins/datatables/plugins/bootstrap/datatables.bootstrap.css" rel="stylesheet" type="text/css" />
          <link href="http://cdn.procesos-iq.com/global/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css" rel="stylesheet" type="text/css" />
          <div ng-app="app" ng-controller="programa" className="ng-scope">

            <div className="page-content" style={{ minHeight: '322px' }}>
              <div className="page-head">
                <div className="page-title">
                  <h1>
PROGRAMA TECNICO
                    <small />
                  </h1>
                </div>
                <div className="page-toolbar">
                  <div style={{ float: 'right' }}>
                    <div className="col-md-4">
                      <select
                        value={selectedAnio}
                        name="year"
                        className="form-control"
                        id="year"
                        onChange={(evt) => {
                          const anio = evt.target.value;
                          fetchProgramaTecnico({ anio });
                        }}
                      >
                        {aniosJSX}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select
                        value={selectedFinca}
                        name="finca"
                        onChange={(evt) => {
                          const finca = evt.target.value;
                          fetchProgramaTecnico({ finca });
                        }}
                        className="form-control"
                        id="finca"
                      >
                        <option label="TODOS" value="">TODOS</option>
                        {fincasJSX}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <select
                        name="categoria"
                        value={selectedCategoria}
                        onChange={(evt) => {
                          const categoria = evt.target.value;
                          fetchProgramaTecnico({ categoria });
                        }}
                        className="form-control"
                        id="finca"
                      >
                        {categoriasJSX}
                      </select>
                    </div>
                  </div>
                  <div className="row pull-right">
                    <a className="btn green-jungle sbold" data-toggle="modal" ng-click="showModal()"> FRAC </a>
                  </div>
                </div>
              </div>

              <ul className="page-breadcrumb breadcrumb">
                <li>
                  <a href="resumenCiclos">Inicio</a>
                  <i className="fa fa-circle" />
                </li>
                <li>
                  <span className="active">Listado</span>
                </li>
              </ul>
              <div className="row">
                <div className="col-md-6">
                  <CiclosGeneral
                    data={programaTecnico}
                  />
                </div>
                <div className="col-md-6">
                  <CiclosDetalle
                    data={programaTecnico}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TablaAplicaciones
                    data={programaTecnico}
                    finca={selectedFinca}
                    fincas={fincas}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <CiclosGeneral
                    data={programaTecnico}
                    isParcial
                    title={'PARCIAL'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default React.memo(ProgramaTecnico);
