const SET_TIPO_PRODUCTOS = 'SET_TIPO_PRODUCTOS';

const initialState = [];

export function setTiposProducto(value) {
  return {
    type: SET_TIPO_PRODUCTOS,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TIPO_PRODUCTOS:
      return action.value;

    default:
      return state;
  }
}
