import React, {
  Component, useState, useEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import DataParser from './DataParser';


import TBodyRow from './TBodyRow';

const uuidv4 = require('uuid/v4');

const Tbody = ({
  data,
  anios
}) => {
  const rows = useMemo(() => data.map(item => (
    <TBodyRow key={uuidv4()} rowData={item} />
  )), [data]);

  return (
    <tbody>
      { rows }
    </tbody>
  );
};

export default React.memo(Tbody);
