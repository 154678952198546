export const SET_TARIFAS = 'SET_TARIFAS';

const initialState = {};

export function setTarifas(id_programa, tarifas) {
  return {
    type: SET_TARIFAS,
    value: {
      id_programa,
      data: tarifas
    }
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TARIFAS:
      return {
        ...state,
        [action.value.id_programa]: action.value.data
      };

    default:
      return state;
  }
}
