import React from 'react';
import PropTypes from 'prop-types';

import { sumMatrixByIndexes } from './DataParser';
import TCell, { tcellTipos } from './TCell';
import { transformNumber } from '../../../helpers/numberHelper';

const TFooter = (props) => {
  const {
    data: rowData,
    anios,
    isResumen,
    dataParser: DataParser
  } = props;

  const fincasData = rowData.map(finca => DataParser.getDataFromFinca(finca.data, anios));

  const totales = fincasData.map(valor => valor.total || null);
  const total = sumMatrixByIndexes(totales);

  const textCenter = 'text-center';
  const className = textCenter;

  let totalJSX = (<React.Fragment />);

  if (isResumen) {
    totalJSX = total.map((item, index, arr) => {
      const valor = arr[index].toFixed(2);
      if (index === 0) {
        return (
          <>
            <th className={className}>{ valor }</th>
          </>
        );
      }

      if (index >= 2 && index % 2 == 0) {
        return (
          <th className={className} />
        );
      }
      return (
        <th className={className}>{ arr[index].toFixed(2) }</th>
      );
    });
  } else {
    totalJSX = total.map((item, index, arr) => {
      const valor = arr[index].toFixed(2);
      if (index === 0) {
        return (
          <>
            <th className={className}>{ valor }</th>
          </>
        );
      }

      return (
        <th className={className}>{ arr[index].toFixed(2) }</th>
      );
    });
  }


  return (
    <tfoot>
      <tr>
        <th scope="col" className="center-th" style={{ position: "sticky", zIndex: 1, backgroundColor: "white", left: 0 }}>TOTALES</th>
        { totalJSX }
      </tr>
    </tfoot>
  );
};

export default React.memo(TFooter);
