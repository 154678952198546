import React, { Component } from 'react';

import { anios } from '../../../../data';

import TCiclosCell from './TCiclosCell';
import DataParser from './DataParser';

class TCiclosBodyRow extends Component {
  constructor(props) {
    super(props);

    const { rowData } = props;

    this.state = {
      rowData,
    };

    // console.log(rowData);
  }

  render() {
    const { rowData } = this.state;
    const data = DataParser.getDataFromFinca(rowData.data, anios);
    const { total } = data;
    // console.log(data);

    const icons = {
      better: 'bg-green-haze bg-font-green-haze',
      worse: 'bg-red-thunderbird bg-font-red-thunderbird',
      equal: 'bg-yellow-gold bg-font-yellow-gold'
    };
    const textCenter = 'center-th';

    let lastValue = null;
    const totalJSX = total.map((item, index, arr) => {
      let valor = null;
      if (arr[index]) valor = Number(parseFloat(arr[index]).toFixed(2));
      
      let className = '';
        if (lastValue === null || (!lastValue) && !valor) className = textCenter;
      else if ((valor && lastValue)&&(valor > lastValue)) className = `${textCenter} ${icons.worse}`;
      else if ((valor && lastValue)&&(valor < lastValue)) className = `${textCenter} ${icons.better}`;
      else if((valor && lastValue)&&(valor == lastValue)) className = `${textCenter} ${icons.equal}`;

      // console.log(rowData);
      // console.log('current',valor);
      // console.log('last',lastValue);
      // console.log(valor < lastValue);
      // console.log(className);
      

      if (index === 0) {
        lastValue = valor;
        return (
          <>
            <td className={className}>{ rowData.name }</td>
            <TCiclosCell
              iconClassName={className}
              valor={valor}
            />
          </>
        );
      }

      lastValue = valor;
      return (
        <TCiclosCell
          iconClassName={className}
          valor={valor}
        />
      );
    });

    return (
      <tr key={`${rowData.name}`} className="">
        { totalJSX }
      </tr>
    );
  }
}

export default React.memo(TCiclosBodyRow);
