import React, { createContext } from 'react';
import { connect } from 'react-redux';

/* Context Shape */
export const CrearProductoFormContext = createContext({
  proveedor: null,
  producto: null,
  dosis: null,
  aplicacion: null,

  onChangeTipoProducto: (tipo_producto) => {},
  onChangeDosis: (dosis) => {},
  onChangeProveedor: (proveedor) => {},
  handleSubmit: (evt) => {},
  handleSubmitCallback: (fn, data, props, precio, cantidad) => {},
  validateData: () => {},
});


const defaultState = {
  proveedor: null,
  producto: null,
  dosis: null,
  aplicacion: null,
};

class CrearProductoFormProvider extends React.Component {
  state = defaultState

  onChangeTipoProducto = (producto) => {
    this.setState({ producto });
  }

  onChangeDosis = (dosis) => {
    this.setState({ dosis });
  }

  onChangeProveedor = (proveedor) => {
    this.setState({ proveedor });
  }

  /* DEPRECADO, MEJOR RECIBIR UN CALLBACK Y MANEJAR EL ESTADO RESULTANTE EN UN COMPONENTE MAYOR */
  handleSubmit = (e) => {}

    handleSubmitCallback = (evt, agregarProducto, props, precio, cantidad) => {
      const {
        programaKey, indice, aplicacion, tarifas
      } = props;
      const { producto, proveedor, dosis } = this.state;
      /* CREAMOS UN NUEVO TIPO_PRODUCTO EN LA BASE */
      if (props.aplicacion && props.aplicacion.id) {
        agregarProducto(evt, programaKey, aplicacion, {
          producto, proveedor, dosis, aplicacion: aplicacion.id
        });
      } else {
        agregarProducto(evt, programaKey, indice, {
          producto, proveedor, dosis, precio, cantidad
        });
      }

      this.setState({ ...defaultState });
    }

    render() {
      const { children } = this.props;
      return (
        <CrearProductoFormContext.Provider value={{
          state: this.state,
          onChangeTipoProducto: this.onChangeTipoProducto,
          onChangeProveedor: this.onChangeProveedor,
          onChangeDosis: this.onChangeDosis,
          handleSubmit: this.handleSubmit,
          handleSubmitCallback: this.handleSubmitCallback,
        }}
        >
          { children }
        </CrearProductoFormContext.Provider>
      );
    }
}


function mapStateToProps(state) {
  return {
    proveedores: state.proveedores,
    fincas: state.fincas,
    motivosAtraso: state.motivosAtraso,
    programas: state.fincas,
    matrizCostos: state.matrizCostos,
    tipoProductos: state.tipoProductos,
    productos: state.productos,
    dosis: state.dosis,
    tarifas: state.tarifas,
  };
}


export default connect(
  mapStateToProps,
)(CrearProductoFormProvider);

export const CrearProductoFormConsumer = CrearProductoFormContext.Consumer;
