import { SELECT_FINCA } from './selectedFinca';
import { SET_MATRIZ_COSTOS } from './matrizCostos';
import {
  ReportesAPI
} from '../../api/rest-api';
import DateHelper from '../../helpers/dateHelper';

export const SET_FINCAS = 'SET_FINCAS';

const initialState = [];

export const setFincas = value => (dispatch, getState) => {
  const { selectedAnio,selectedHectareaje } = getState();
  dispatch({
    type: SET_FINCAS,
    value
  });

  if (Array.isArray(value) && value.length > 0) {
    ReportesAPI.getMatrizDeCostos(
      {
        anio: selectedAnio,
        id_finca: value[0].id,
        tipo_hectareaje: selectedHectareaje
      }
    ).then((response) => {
      const { data } = response;
      dispatch({
        type: SET_MATRIZ_COSTOS,
        value: data
      });
    }).catch((err) => {
      console.error(err);
    });
    return dispatch({
      type: SELECT_FINCA,
      value: value[0].id
    });
  }
};

export const selectFinca = evt => (dispatch, getState) => {
  const { selectedAnio } = getState();
  ReportesAPI.getMatrizDeCostos({ anio: selectedAnio, id_finca: evt.target.value }).then((response) => {
    const { data } = response;
    dispatch({
      type: SET_MATRIZ_COSTOS,
      value: data
    });
  }).catch((err) => {
    console.error(err);
  });

  return dispatch({
    type: SELECT_FINCA,
    value: evt.target.value
  });
};

export const setFincasNew = value => (dispatch, getState) => {
  const { selectedAnio } = getState();
  dispatch({
    type: SET_FINCAS,
    value
  });

  if (Array.isArray(value) && value.length > 0) {
    return dispatch({
      type: SELECT_FINCA,
      value: value[0].id
    });
  }
};

export const selectFincaNew = evt => (dispatch, getState) => {
  const { selectedAnio } = getState();
  return dispatch({
    type: SELECT_FINCA,
    value: evt.target.value
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FINCAS:
      return action.value;

    default:
      return state;
  }
}
