import { SELECT_MENU } from './selectedMenu';

export const SET_MENUS = 'SET_MENUS';

const initialState = [];

export const setMenus = (value) => async (dispatch, getState) => {
    await dispatch({
    type: SET_MENUS,
    value
    });

  if (Array.isArray(value) && value.length > 0) {
    return dispatch({
      type: SELECT_MENU,
      value: value[0].id
    });
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENUS:
      return action.value;

    default:
      return state;
  }
}
