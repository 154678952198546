import React from 'react';
import PropTypes from 'prop-types';

import TBodyRow from './TBodyRow';

const uuidv4 = require('uuid/v4');

const TBody = (props) => {
  const { data } = props;

  const rows = data.map(item => (
    <TBodyRow key={uuidv4()} rowData={item} />
  ));

  return (
    <tbody>
      { rows }
    </tbody>
  );
};

export default TBody;
