import React from 'react';
import PropTypes from 'prop-types';

import { ACEITE, FUNGICIDA } from '../../../data';

import TBodyRow from './TBodyRow';

const uuidv4 = require('uuid/v4');

const TBody = (props) => {
  const { data } = props;

  const fungicidasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === FUNGICIDA);
    } return [];
  });

  const aceitesMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === ACEITE);
    } return [];
  });

  const aguasMatrix = data.map((aplicacion) => {
    if (Array.isArray(aplicacion.data)) {
      return aplicacion.data.filter(producto => producto.tipo === 'MEZCLA');
    } return [];
  });

  const aceitesMaxSize = aceitesMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const columnsMaxSize = fungicidasMatrix.reduce((acum, productos) => {
    if (Number(acum) < productos.length) return productos.length;
    return Number(acum);
  }, 0);

  const aguaMaxSize = aguasMatrix.reduce((acum, item) => {
    if (Number(acum) < item.length) return item.length;
    return Number(acum);
  }, 0);

  const fungicidasArrCount = new Array(columnsMaxSize);
  const aceitesArrCount = new Array(aceitesMaxSize);

  const rows = data.map(item => (
    <TBodyRow
      key={uuidv4()}
      rowData={item}
      maxArrSize={fungicidasArrCount}
      aceitesArrCount={aceitesArrCount}
      aguaMaxSize={aguaMaxSize}
      aguasMatrix={aguasMatrix}
    />
  ));

  return (
    <tbody>
      { rows }
    </tbody>
  );
};

export default TBody;
