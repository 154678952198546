import React from 'react';
import PropTypes from 'prop-types';

import TCiclosBodyRow from './TCiclosBodyRow';

const uuidv4 = require('uuid/v4');

const TCiclosBody = (props) => {
  const { data, anios } = props;

  const rows = data.map(item => (
    <TCiclosBodyRow key={uuidv4()} rowData={item} />
  ));

  return (
    <tbody>
      { rows }
    </tbody>
  );
};

export default TCiclosBody;
