export const SET_PROGRAMA_TECNICO = 'SET_PROGRAMA_TECNICO';

const initialState = [];

export function setProgramaTecnico(value) {
  return {
    type: SET_PROGRAMA_TECNICO,
    value
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROGRAMA_TECNICO:
      return action.value;

    default:
      return state;
  }
}
